import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const OnboardCompany= createAsyncThunk("/OnboardCompany",async(companyData)=>{
    try {
        const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
        var formdata = new FormData();
            formdata.append("company_name", companyData.CompanyName);
            formdata.append("concerned_person_name", companyData.NameOfConcernPerson);
            formdata.append("phone", companyData.Phone);
            formdata.append("email", companyData.Email);
            formdata.append("position_title", companyData.Position);
            formdata.append("job_description", companyData.JD);
            formdata.append("job_location", companyData.Location);
            formdata.append("no_of_openings", companyData.NoOfOpenings);
            formdata.append("ctc_in_lpa", companyData.Ctc);
            formdata.append("experience_required", companyData.Experiance);
            formdata.append("category", companyData.catagory);
            formdata.append("profile", companyData.Profile);
            formdata.append("skills", companyData.Skills);
            formdata.append("contacted_by", companyData.ContactedBy);
        const resp = await axios.post("/course/placement/",formdata)
        return resp.data
    } catch (error) {
        throw new Error(error)
    }
})

 const OnboardCompanyReducer = createSlice({
    name:"Onboard Company",
    initialState:{
        loading:true,
        data:null,
        error:null,
    },
    reducer:{},
    extraReducer:(builder)=>{
        builder
        .addCase(OnboardCompany.pending,(state)=>{
            state.loading= true;
            state.data= null;
        })
        .addCase(OnboardCompany.fulfilled,(state,action)=>{
            state.loading= false;
            // state.data= action.payload;
        })
        .addCase(OnboardCompany.rejected,(state,action)=>{
            state.loading= false;
            state.error= action.error.message;
        })
    }
})

export default OnboardCompanyReducer.reducer