import axios from 'axios';
import React, { useState } from 'react'
// material ui 
import Snackbar from "@mui/material/Snackbar";

export default function CreateCourse() {

  const [Title, setTitle] = useState("");
  const [Summary, setSummary] = useState("");
  const [desc, setdesc] = useState("");
  const [Language, setLanguage] = useState("");
  const [ModuleCount, setModuleCount] = useState("");
  const [SessionCount, setSessionCount] = useState("");
  const [Fees, setFees] = useState("");
  const [DiscountedFees, setDiscountedFees] = useState("");
  const [Syllabus, setSyllabus] = useState("");
  const [CourseType, setCourseType] = useState("");
  const [Prerequisite, setPrerequisite] = useState("");
  const [CourseLevel, setCourseLevel] = useState("");
  const [AgeGrp, setAgeGrp] = useState("");
  const [Category, setCategory] = useState("");
  const [CourseFeature, setCourseFeature] = useState("");
  const [Roadmap, setRoadmap] = useState("");
  const [Brochure, setBrochure] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [RegURL, setRegURL] = useState("");
  const [CourseImg, setCourseImg] = useState("");
  const [AvgRating, setAvgRating] = useState(0);
  const [Duration, setDuration] = useState("");
  const [Type, setType] = useState("");
  const [DummyCert, setDummyCert] = useState("");
  const [DurationInMonths, setDurationInMonths] = useState(0);
  const [Frequency, setFrequency] = useState([]);
  const [Size, setSize] = useState(0);
  const [CourseVideo, setCourseVideo] = useState("");
  const [CourseId, setCourseId] = useState("");
  const [AllCourse, setAllCourse] = useState([]);
  const [cdId, setcdId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [ShowCourse, setShowCourse] = useState("");


  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post(
      "/users/token/refresh/",
      formdata
    );
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const getAllCourses = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      "/course/course/?pageSize&offset"
    );
    setAllCourse(data.data.courseDetails);
  };

  const handeAddCourse = async (e) => {
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("title", Title);
    formdata.append("summary", Summary);
    formdata.append("description", desc);
    formdata.append("language", Language);
    formdata.append("module_count", ModuleCount);
    formdata.append("session_count", SessionCount);
    formdata.append("fees", Fees);
    formdata.append("discounted_fees", DiscountedFees);
    formdata.append("syllabus", Syllabus);
    formdata.append("course_type", CourseType);
    formdata.append("prerequisite", Prerequisite);
    formdata.append("course_level", CourseLevel);
    formdata.append("age_group", AgeGrp);
    formdata.append("category", Category);
    formdata.append("course_key_feature", CourseFeature);
    formdata.append("roadmap", Roadmap);
    formdata.append("brochure", Brochure);
    formdata.append("start_date", StartDate);
    formdata.append("registration_url", RegURL);
    formdata.append("course_image", CourseImg);
    formdata.append("avg_rating", AvgRating);
    formdata.append("duration", Duration);
    formdata.append("type", Type);
    formdata.append("course_dummy_certificate", DummyCert);
    formdata.append("course_duration_in_months", DurationInMonths);
    formdata.append("frequency", "");
    formdata.append("show_course", ShowCourse);
    formdata.append("size", Size);
    formdata.append("course_video", "");

    const { data } = await axios.post(
      `/course/course/`,
      formdata
    );

    if (data.status == "0x0") {
      getAllCourses();
      setOpen(true);
        setTimeout(() => setOpen(false), 5000);
    }
    };

  return (
    <div>
      {<Snackbar
        open={open}
        // autoHideDuration={3000}
        // onClose={handleClose}
        message="Course Added successfully...!!!"
        // action={action}
        sx={{
          bgcolor: "info",
        }}
      />}
      <h4 className="ms-5 ps-5">Create Course</h4>
        <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={handeAddCourse}>
                    <div className="row py-4">
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Title:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Title"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Summary:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          className="form-control"
                          onChange={(e) => setSummary(e.target.value)}
                          placeholder="Summary"
                          required
                        ></textarea>
                      </div> 

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Desc:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          className="form-control"
                          onChange={(e) => setdesc(e.target.value)}
                          placeholder="Desc"
                          required
                        ></textarea>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Language:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLanguage(e.target.value)}
                          placeholder="Select Language"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Module Count:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setModuleCount(e.target.value)}
                          placeholder="Module Count"
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Session Count:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setSessionCount(e.target.value)}
                          placeholder="Session Count"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Fees:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setFees(e.target.value)}
                          placeholder="Fees"
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Discounted Fees:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDiscountedFees(e.target.value)}
                          placeholder="Discounted Fees"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Syllabus:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          onChange={(e) => setSyllabus(e.target.value)}
                          className="form-control"
                          placeholder="Syllabus"
                          required
                        ></textarea>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Prerequisite:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setPrerequisite(e.target.value)}
                          placeholder="Prerequisite"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Type:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCourseType(e.target.value)}
                          aria-label="Default select example"
                          required
                        >
                          <option selected>Course Type</option>
                          <option value="L">Live</option>
                          <option value="P">Pre-Recorded</option>
                        </select>
                      </div>
                      
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Level:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCourseLevel(e.target.value)}
                          aria-label="Default select example"
                          required
                        >
                          <option>Select Level</option>
                          <option value="B">Beginner</option>
                          <option value="I">Intermediate</option>
                          <option value="E">Expert</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Age Group:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setAgeGrp(e.target.value)}
                          placeholder="Age Group"
                        />
                      </div>
                      
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course category:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCategory(e.target.value)}
                          aria-label="Default select example"
                          required
                        >
                          <option>Select Course category</option>
                          <option value="tech">tech</option>
                          <option value="design">design</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Feature:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setCourseFeature(e.target.value)}
                          placeholder="Course Feature"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Roadmap Img:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setRoadmap(e.target.files[0])}
                          placeholder="Roadmap Img"
                          required
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Brochure:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setBrochure(e.target.files[0])}
                          placeholder="Brochure"
                        />
                      </div>
                      
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Start Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setStartDate(e.target.value)}
                          placeholder="Start Date"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">RegURL:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setRegURL(e.target.value)}
                          placeholder="setRegURL"
                          required
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Img:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setCourseImg(e.target.files[0])}
                          placeholder="Course Img"
                          required
                        />
                      </div>

                      {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Video:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setCourseVideo(e.target.files[0])}
                          placeholder="Course Video"
                          required
                        />
                      </div> */}
                       <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Avg Rating:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setAvgRating(e.target.value)}
                          value={AvgRating}
                          placeholder="Avg Rating"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Duration In Hours:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDuration(e.target.value)}
                          value={Duration}
                          placeholder="Course Duration In Hourse"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Duration In Months:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDurationInMonths(e.target.value)}
                          value={DurationInMonths}
                          placeholder="Course Duration In Months"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Type:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setType(e.target.value)}
                          value={Type}
                          aria-label="Default select example"
                        >
                          <option>Select Type</option>
                          <option value="W">Workshop</option>
                          <option value="C">Course</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Dummy Certificate:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setDummyCert(e.target.files[0])}
                          // value={Duration}
                          placeholder="Dummy Certificate"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Size:</label>
                        <input
                          type="Number"
                          className="form-control"
                          onChange={(e) => setSize(e.target.value)}
                          value={Size}
                          placeholder="Course Size"
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-5">
                        <button className="btn btn-info">Add Course</button>
                      </div>
                    </div>
                  </form>
                </div>
        </div>
    </div>
  )
}
