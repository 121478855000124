import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function Module({ModuleList}) {
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [moduleList, setmoduleList] = useState(ModuleList)

    useEffect(() => {
      setOpen(ModuleList.length>0?true:false)
    }, [ModuleList])
    
  return (
    <div>
         {/* model of module  */}

         <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
                <ul>
                {ModuleList.length===0
                ?"Add Modules"
                :ModuleList.map((module,i)=>(
                  <li key={i}>{module}</li>
                ))}
              </ul>
          </Box>
        </Fade>
      </Modal>
    </div>
    </div>
  )
}
