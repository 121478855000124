import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useState } from 'react';



export const signin = createAsyncThunk('/signin', async (Username,Password) => {
        var formdata = new FormData();

        console.log(Username);
        console.log(Password);

        formdata.append("username", Username);
        formdata.append("password", Password);
        
        try {
          const { data } = await axios.post("/users/signin/", formdata);
          if (data.status == "0x0") {
            localStorage.setItem("refresh", JSON.stringify(data.data.refresh));
            localStorage.setItem("username", JSON.stringify(Username));
            localStorage.setItem("Userid", JSON.stringify(data.data.id));
            localStorage.setItem("profile_state", JSON.stringify(data.data.profile_state));
            localStorage.setItem(
              "user_group",
              JSON.stringify(data.data.user_group)
            );
            localStorage.setItem("name", JSON.stringify(data.data.name));
          }
          return data
        } catch (error) {
            throw new Error(error.message)
        }
});

const signinSlice = createSlice({
  name: 'signin',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(signin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default signinSlice.reducer;