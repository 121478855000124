import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Referals() {
  const [AllStuList, setAllStuList] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [TotalCount, setTotalCount] = useState();
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [Search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const jsonParser = (arr) => {
    const newArr = arr.map((item) => item.description);
    // console.log(newArr)
    const parsedData = newArr.map((item) => {
      // Replace single quotes with double quotes to make it valid JSON
      const validJSONString = item.replace(/'/g, '"');

      // Parse the valid JSON string into an object

      return JSON.parse(validJSONString);
    });

    setAllStuList(parsedData);
  };

  console.log(AllStuList);

  const GetReferals = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/support/list?ticket_type=Referral");

    if (data.status == "0x0") {
      // setAllStuList(data?.data?.Support_list?.results)
      jsonParser(data?.data?.Support_list?.results);
    }
  };
  useEffect(() => {
    GetReferals();
  }, []);

  return (
    <div className="refer-container1">
      <h4 className="my-2">Referal Students List</h4>
      <div className="table table-responsive mt-2">
        <table className="table table-striped custom-bordered">
          <thead className="table-dark">
            <tr>
              <th className=" supportTable ">
                <p className="supportTable">Sr. No.</p>
              </th>
              <th className="supportTable">
                {/* <div className="dropdown "> */}
                <p
                  className="dropdown-toggle supportTable"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Referral Name
                </p>

                {/* </div> */}
              </th>

              <th className="supportTable">
                <p className="supportTable">Referral Mob.</p>
              </th>
              <th className="supportTable">
                <p className="supportTable">Interested Course</p>
              </th>

              <th className="supportTable">
                <p className="supportTable">Referee Name</p>
              </th>

              <th className="supportTable">
                <p className="supportTable">Referee Mob.</p>
              </th>

              <th className="supportTable">
                <p className="supportTable">Referee Counsellor</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {AllStuList.length == 0 ? (
              <h4 className="text-center my-2">No Results Found</h4>
            ) : (
              <>
                {AllStuList.map((item, i) => (
                  <tr key={i} className="p-0 m-0">
                    <td>{i + 1 + listNo}</td>
                    <td>{item?.ReferralName}</td>

                    <td>{item?.ReferralContactNumber}</td>
                    <td>{item?.CourseInterest}</td>
                    <td>{item?.YourName}</td>
                    <td>{item?.YourNumber}</td>

                    <td>{item?.CounsellorName}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
