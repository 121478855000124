import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { contactUsData } from '../slices/contactUsSlice';

export default function Leads() {
  const [LeadList, setLeadList] = useState([])
  const [pageNo, setpageNo] = useState(1);
  const [listNo, setlistNo] = useState(0);
  const [ListCount, setListCount] = useState(0);
  const dispatch = useDispatch();
  const contactUs = useSelector((state) => state.contactUs);
  console.log(contactUs);
  // console.log(loading);

  const getAllLeads=async()=>{
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/users/contact_us/list/?page=${pageNo}`);
    if(data.status=="0x0"){
      setLeadList(data.data.contactUsList.results)
      setListCount(data.data.contactUsList.count / 10)
    }
  }

   // pagination
   const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 10);
    }
  };

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      console.log("next");
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 10);
    }
  };

  useEffect(() => {
    // getAllLeads()
    dispatch(contactUsData(pageNo))
  }, [])

  useEffect(() => {
    if (contactUs.data !=null) {
      setLeadList(contactUs.data.data.contactUsList.results)
      setListCount(contactUs.data.data.contactUsList.count / 10)
    }
  }, [contactUs])

  useEffect(() => {
    dispatch(contactUsData(pageNo))
  }, [pageNo])
  
  return (
    <div className=''>
      <h4 className="mb-4">List Of Leads</h4>
      <div className="row">
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile No.</th>
              <th>Enquiry Date</th>
            </tr>
          </thead>
          <tbody>
            {LeadList?.length > 0 ? (
              LeadList?.map((lead, i) => (
                <tr key={i} style={{ cursor: "pointer" }}>
                  <td>{i + 1 + listNo}</td>
                  <td>{lead?.user_info}</td>
                  <td>{lead?.email}</td>
                  <td>{lead?.phone_numbers}</td>
                  <td>{lead?.date_of_inquiry}</td>
                </tr>
              ))
            ) : (
              <h4 className="text-center my-5">No Data Found...</h4>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-between my-5">
          <button className="btn btn-info" onClick={handlePrevPage}>
            Prev
          </button>
          <button className="btn btn-info" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  )
}
