import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCompanyList } from '../slices/companyListSlice'
import { useEffect } from 'react'
import { RefresToken } from '../slices/RefreshToken'
import { useState } from 'react'

export default function CompanyList() {

  const [pageNo, setpageNo] = useState(1);
  const [TotalCount, setTotalCount] = useState();
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);

    const dispatch = useDispatch()
    // const [placementList, setplacementList] = useState({results:[]})
    const {data}=useSelector((state)=>state.companyList)

    const displayData=()=>{
      if(data!=null){
      setTotalCount(data.data.placementList.count);
      setListCount(data.data.placementList.count / 10);
      }
    }
     
    useEffect(() => {
      displayData()
    }, [data])
    

    useEffect(() => {
      let Username= localStorage.getItem("username");
      dispatch(RefresToken(Username))
      .then(()=>{dispatch(fetchCompanyList(pageNo))})  
      .catch((error) => {
        console.log(error);
      });
    }, [])
    useEffect(() => {
      let Username= localStorage.getItem("username");
      dispatch(RefresToken(Username))
      .then(()=>{dispatch(fetchCompanyList(pageNo))})  
      .catch((error) => {
        console.log(error);
      });
    }, [pageNo])

    const handleNextPage = () => {
      if (pageNo < ListCount) {
        setpageNo((prev) => prev + 1);
        setlistNo((prev) => prev + 10);
      }
    };
  
    const handlePrevPage = () => {
      if (pageNo > 1) {
        setpageNo((prev) => prev - 1);
        setlistNo((prev) => prev - 10);
      }
    };

    console.log(pageNo);
    console.log(ListCount);

  return (
    <div>
              <h4>List Of Company's</h4>
              <div className="table table-responsive mt-2">
                <table className="table table-striped custom-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th className="  "> <p> Sr. No. </p> </th>
                      <th className="">
                        <div className="dropdown ">
                          <p
                            className="dropdown-toggle "
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Company Name
                          </p>
                          <ul className="dropdown-menu">
                            <li
                              style={{ cursor: "pointer" }}
                              // onClick={GetAllStuByBatchEnroll}
                            >
                              <span className="dropdown-item">
                                Sort By Batch Enroll
                              </span>
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              // onClick={handleGetAllStu}
                            >
                              <span className="dropdown-item">
                                Sort By Recently Added
                              </span>
                            </li>
                          </ul>
                        </div>
                      </th>

                      <th className="">
                        <p className="">Email </p>
                      </th>
                      
                      <th className="">
                        <p className="">Phone No.</p>
                      </th>

                      <th className="">
                        <p className="">Concered Person Name</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Contacted By</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data==null||data.data.placementList?.results.length==0? (
                      <h4 className="text-center my-2">No Results Found</h4>
                    ) : (
                      <>
                        {data?.data?.placementList?.results.map((item, i) => (
                          <tr key={i} className="p-0 m-0">

                            <td>{i + 1}</td>

                            <td>
                              {item?.company_name}
                            </td>

                            <td>{item?.email}</td>
                            <td>{item?.phone}</td>
                            <td>
                              {item?.concerned_person_name}
                            </td>
                          
                            <td className="d-flex justify-content-center align-items-center">
                              {item.contacted_by}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {/*============= pagination ============= */}

              <div className="container d-flex justify-content-between my-5">
                <button className="btn btn-info mb-5" onClick={handlePrevPage}>
                  Prev
                </button>
                <div>
                  <button
                    className="btn btn-info mb-5"
                    onClick={handleNextPage}
                  >
                    Next
                  </button>
                 
                </div>
              </div>
    </div>
  )
}
