import { useScrollTrigger } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CircularProgress from "@mui/joy/CircularProgress";

export default function SupportTicketList() {
  const [EditId, setEditId] = useState("");
  const [AllTicketList, setAllTicketList] = useState([]);
  const [listNo, setlistNo] = useState(0);
  const [Reply, setReply] = useState("");
  const [Title, setTitle] = useState("");
  const [Desc, setDesc] = useState("");
  const [TicketType, setTicketType] = useState("");
  const [Attachment, setAttachment] = useState();
  const [TicketStatus, setTicketStatus] = useState("N");
  const [Modal, setModal] = useState();
  const [AllSupportTickets, setAllSupportTickets] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [ListCount, setListCount] = useState(0);
  const [TotalCount, setTotalCount] = useState();
  const [IsLoading, setIsLoading] = useState(false);

  const regex = /(<([^>]+)>)/gi;

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  // =========== Get All Reply ===========

  const GetAllTickets = async () => {
    setIsLoading(true)
    const { data } = await axios.get(`/support/list/?page=${pageNo}`);
    if (data.status == "0x0") {
      setAllSupportTickets(data?.data?.Support_list?.results);
      setListCount(data?.data?.Support_list.count / 20);
      setIsLoading(false)
    }
  };

  const getTicket = async () => {
    const { data } = await axios.get(`/support/?id=${EditId}`);
    // setTicket(data.data.ticketData[0]);
    setTitle(data.data.ticketData[0].title);
    setDesc(data.data.ticketData[0].description);
    setTicketType(data.data.ticketData[0].ticket_type);
    setTicketStatus(data.data.ticketData[0].ticket_status);
    setAttachment(data.data.ticketData[0].attachment);
  };

  useEffect(() => {
    GetAllTickets();
  }, [pageNo]);

  useEffect(() => {
    GetAllTickets();
  }, []);

  const EditTicket = async (e) => {
    console.log("edited in");
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      // 'DEVICE-UDID': deviceUDID
    };
    var formdata = new FormData();

    const userId = JSON.parse(localStorage.getItem("Userid"));
    formdata.append("title", Title);
    formdata.append("description", Desc);
    formdata.append("ticket_type", TicketType);
    formdata.append("ticket_status", TicketStatus);
    // formdata.append("reply", Reply);
    formdata.append("reply", Reply.replace(regex, ""));
    formdata.append("attachment", Attachment);
    formdata.append("ticket_id", EditId);
    formdata.append("user_id", userId);

    const { data } = await axios.put("/support/", formdata);
    // const {data} = await axios.put("http://127.0.0.1:8000/support/",formdata)
    if (data.status == "0x0") {
      setModal(true);
      GetAllTickets();
    }
  };

  useEffect(() => {
    if (EditId != "") {
      getTicket();
    }
  }, [EditId]);

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 20);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 20);
    }
  };

  return (
    <div>
      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <>
        
        <h4 className="mb-4">Ticket List</h4>
          <div className="row">
            <table className="table table-striped">
              <thead className="table-dark">
                <tr>
                  <th className="text-center">Sr. No.</th>
                  <th>Name</th>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {AllSupportTickets.length>0
              ?<tbody>
                {AllSupportTickets?.map((ticket, i) => (
                  <tr style={{ cursor: "pointer" }}>
                    <td>{i + 1 + listNo}</td>
                    <td>{ticket?.first_name}</td>
                    <td>{ticket?.title}</td>
                    <td>
                      {ticket?.ticket_type == "C" && "Course"}
                      {ticket?.ticket_type == "P" && "Payment"}
                      {ticket?.ticket_type == "R" && "Refund"}
                      {ticket?.ticket_type == "O" && "Other"}
                    </td>
                    <td>
                      {ticket?.ticket_status == "N" && "New"}
                      {ticket?.ticket_status == "O" && "Open"}
                      {ticket?.ticket_status == "C" && "Closed"}
                      {ticket?.ticket_status == "H" && "On-Hold"}{" "}
                      {ticket?.ticket_status == "P" && "Pending"}
                      {ticket?.ticket_status == "S" && "Solved"}
                    </td>
                    <td>
                      {/* <button
                    className="btn btn-info btn-sm m-1 "
                    data-bs-toggle="modal"
                    data-bs-target="#ReplyModal"
                    onClick={() => setEditId(ticket?.id)}
                  >
                    Reply
                  </button> */}
                      <button
                        className="btn btn-info btn-sm m-1"
                        data-bs-toggle="modal"
                        data-bs-target="#editTicketModal"
                        onClick={() => setEditId(ticket?.id)}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            : <h3 className="my-4">No Data Found</h3>
          }
            </table>
          </div>


          {/* ============== Pagination ============ */}
          <div className="container d-flex justify-content-between my-5">
            <button className="btn btn-info" onClick={handlePrevPage}>
              Prev
            </button>

            <button className="btn btn-info" onClick={handleNextPage}>
              Next
            </button>
          </div>
        </>
      )}
      {/* ============== Edit ticket modal ============ */}

      <div
        className="modal fade"
        id="editTicketModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={EditTicket}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Reply To Ticket
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mb-4">
                    {/* <form onSubmit={EditTicket}> */}
                    <div className="row">
                      <div className="col-12 my-2">
                        <label htmlFor="">Title:</label>
                        <input
                          type="text"
                          placeholder="Title"
                          value={Title}
                          className="form-control"
                        />
                      </div>

                      <div className="col-12 my-2">
                        <label htmlFor="">Ticket Type:</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={TicketType}
                        >
                          <option selected>Select Ticket Type</option>
                          <option value="C">Course</option>
                          <option value="P">Payment</option>
                          <option value="R">Refund</option>
                          <option value="O">Other</option>
                        </select>
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Status:</label>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setTicketStatus(e.target.value)}
                          value={TicketStatus}
                        >
                          <option selected>Select Ticket Type</option>
                          <option value="N">New</option>
                          <option value="O">Open</option>
                          <option value="P">Pending</option>
                          <option value="H">On-Hold</option>
                          <option value="S">Solved</option>
                          <option value="C">Closed</option>
                        </select>
                      </div>
                      <div className="col-12 mt-2 mb-3">
                        <label htmlFor="">Description:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                          placeholder="Description"
                          className="form-control"
                          value={Desc}
                        ></textarea>
                      </div>
                      <hr />
                      <div className="col-12 mt-2 mb-1">
                        <label htmlFor="">
                          <h5>Your Reply:</h5>
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={Reply}
                          // onChange={(e) => setReply(e.target.value)}
                          onChange={setReply}
                          width="100%"
                        />
                      </div>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="col-12 my-4 d-flex justify-content-end">
                  <button className="btn btn-primary" data-bs-dismiss="modal">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
