import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
// import "../../style/addLink.css";
import "react-quill/dist/quill.snow.css";

export default function AddLinks() {
  const [BatchId, setBatchId] = useState("");
  const [AssLink, setAssLink] = useState("");
  const [doubtLink, setdoubtLink] = useState("");
  const [CommLink, setCommLink] = useState("");
  const [satLink, setsatLink] = useState("");
  const [sunLink, setsunLink] = useState("");
  const [AllBatches, setAllBatches] = useState([]);

  const getAllBatches = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/course/batch/");
    if ((data.status = "0x0")) {
      setAllBatches(data.data.batchDetails);
    }
  };

  const addBatchLinks = async (e) => {
    e.preventDefault();
    var formdata = new FormData();
    formdata.append("user_batch_id", BatchId);
    formdata.append("batch_assignment_link", AssLink);
    formdata.append("doubt_solving_session_zoom_link",doubtLink);
    // formdata.append(
    //   "doubt_solving_session_zoom_link",
    //   doubtLink.replace(regex, "")
    // );
    formdata.append("saturday_session_zoom_link", satLink);
    formdata.append("sunday_session_zoom_link", sunLink);
    formdata.append("batch_community_link", CommLink);
    const { data } = await axios.put("/course/batch/add_links", formdata);
    if (data.status == "0x0") {
      setBatchId("");
      setAssLink("");
      setdoubtLink("");
      setCommLink("");
      setsatLink("");
      setsunLink("");
      alert("Batch Links Added successfully...!!!");
    }
  };

  useEffect(() => {
    getAllBatches();
  }, []);
console.log(AllBatches);
  return (
    <div className="container">
      <h4 className="mb-5">Add Links</h4>
      <form onSubmit={addBatchLinks}>
        <div className="row mt-3">
          <div className="col-4">
            <p>Batch ID</p>
          </div>
          <div className="col-8">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setBatchId(e.target.value)}
                value={BatchId}
              >
                <option selected>Select Batch ID</option>
                {AllBatches?.map((batch, i) => (
                  <option value={batch?.user_batch_id} className="text-dark">
                    {batch?.user_batch_id}
                  </option>
                ))}
              </select>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-4">
            <p>Assignment Link</p>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Assignment Link"
              className="form-control"
              onChange={(e) => setAssLink(e.target.value)}
              value={AssLink}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <p>Doubt Solving Zoom Link</p>
          </div>
          <div className="col-8">
            <ReactQuill
              theme="snow"
              // onChange={(e) => setZoomLink(e.target.value)}
              onChange={setdoubtLink}
              value={doubtLink}
              width="100%"
            />
          </div>
        </div>
     
        <div className="row mt-3">
          <div className="col-4">
            <p>Saturday Session Zoom Link</p>
          </div>
          <div className="col-8">
            <ReactQuill
              theme="snow"
              // onChange={(e) => setZoomLink(e.target.value)}
              onChange={setsatLink}
              value={satLink}
              width="100%"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <p> Sunday Sessions Zoom Link</p>
          </div>
          <div className="col-8">
            <ReactQuill
              theme="snow"
              // onChange={(e) => setZoomLink(e.target.value)}
              onChange={setsunLink}
              value={sunLink}
              width="100%"
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <p>Community Link</p>
          </div>
          <div className="col-8">
            <input
              type="text"
              placeholder="Community Link"
              className="form-control"
              onChange={(e) => setCommLink(e.target.value)}
              value={CommLink}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end my-5">
          <button className="btn btn-info">Submit</button>
        </div>
      </form>
    </div>
  );
}
