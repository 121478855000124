import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import "../style/loginPage.css";
import coachxLogo from "../images/logo.png";
// import newTrainer from "../images/newTrainer.jpeg"
// material ui
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { useDispatch } from "react-redux";
import { RefresToken } from "../Components/slices/RefreshToken";
import { signin } from "../Components/slices/SignInSlice";

export default function LoginPage() {
  let history = useHistory();
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [refreshToken, setrefreshToken] = useState(
    JSON.parse(localStorage.getItem("refresh"))
  );
  const [IsLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState("");
  const [ShowPass, setShowPass] = useState({
    showPassword: true,
  });

  const dispatch = useDispatch();

  // let deviceUDID = new Date();
  // if (!localStorage.getItem("DEVICE-UDID")) {
  //   localStorage.setItem("DEVICE-UDID", deviceUDID);
  // }
  // deviceUDID = localStorage.getItem("DEVICE-UDID");
  // axios.defaults.headers.common = {
  //   // 'Authorization': '',
  //   "DEVICE-UDID": deviceUDID,
  // };

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", Username);
    formdata.append("refresh", refreshToken);
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.status == "0x0") {
      if(data.data.access!=undefined && data.data.access!=""){
      localStorage.setItem("access", JSON.stringify(data.data.access));
      history.push("/AdminPanel");
      }
    }
  };

  if (refreshToken) {
    handleRefreshToken();
    // dispatch(RefresToken(Username))
  }

  const handleSignIn = async (e) => {
    e.preventDefault();
    // dispatch(signin(Username,Password))

    setIsLoading(true);
    var formdata = new FormData();
    formdata.append("username", Username);
    formdata.append("password", Password);
    try {
      const { data } = await axios.post("/users/signin/", formdata);
      if (data.status == "0x1004") {
        setError("Incorrect username or password");
        setIsLoading(false);
      }
      if (data.status == "0x1007") {
        setError(
          "Your account is deactivated due to non payment of your installments."
        );
        setIsLoading(false);
      }

      if (data.status == "0x0") {
        localStorage.setItem("refresh", JSON.stringify(data.data.refresh));
        localStorage.setItem("username", JSON.stringify(Username));
        localStorage.setItem("Userid", JSON.stringify(data.data.id));
        localStorage.setItem(
          "profile_state",
          JSON.stringify(data.data.profile_state)
        );
        localStorage.setItem(
          "user_group",
          JSON.stringify(data.data.user_group)
        );
        localStorage.setItem("name", JSON.stringify(data.data.name));
        setIsLoading(false);
        setrefreshToken(data.data.refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPass({ ...ShowPass, showPassword: !ShowPass.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="main-wrapper">
      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-column border w-100 h-100">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <div className="container inner-container">
          <div className="row d-flex justify-content-center align-items-center pt-2 ">
            <div className="d-flex justify-content-center col-12  img-wrapper">
              <img
                src={coachxLogo}
                alt="coachx logo"
                width="200px"
                className=""
              />
            </div>
          </div>

          <div className="row d-flex flex-column justify-content-center">
            {Error != "" && (
              <div className="col-md-4 offset-md-4 col-6 offset-3 mt-2">
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  {Error}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            )}
            <form onSubmit={handleSignIn}>
              <h4 className="my-4 text-center welcome-text">Sign In</h4>
              <div className="col-md-4 offset-md-4 col-10 offset-1 my-3">
                <Input
                  type="text"
                  placeholder="Enter Username"
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control px-2 bg-light"
                />
              </div>
              <div className="col-md-4 offset-md-4 col-10 offset-1 my-3">
                <Input
                  type={ShowPass.showPassword ? "password" : "text"}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {ShowPass.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
              <div className="col-md-4 offset-md-4 col-10 offset-1 my-3">
                <button className="btn sub-btn text-light">Submit</button>
              </div>
              {/* <div className="col-6 offset-3 d-flex justify-content-center my-3">
                <div className="d-flex justify-content-between">
                  <a href="#">Forget Your Password?</a>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
