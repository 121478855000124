import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

export default function DbPiechart({ data }) {
  const dd=data
  return (
    <div>
      <ResponsiveContainer width={500} height={300}>
      <PieChart>
      <Pie
        dataKey="NumberOfStudents"
        nameKey="NameOfCounsellor"
        startAngle={360}
        endAngle={0}
        data={dd}
        cx={270}
        cy={150}
        outerRadius={100}
        fill="#8884d8"
        label
      />
      <Tooltip/>
      {/* <Legend verticalAlign="top" height={36}/> */}
    </PieChart> 
      </ResponsiveContainer> 
    </div>
  );
}
