import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function PlacedStuList() {
    const [AllStuList, setAllStuList] = useState([])
    const [pageNo, setpageNo] = useState(1);
    const [TotalCount, setTotalCount] = useState();
    const [ListCount, setListCount] = useState(0);
    const [listNo, setlistNo] = useState(0);
    const [EditId, setEditId] = useState("")

    const [StuName, setStuName] = useState("")
    const [Desc, setDesc] = useState("")
    const [ProfilePic, setProfilePic] = useState("")
    const [CompanyLogo, setCompanyLogo] = useState("")
    const [Designation, setDesignation] = useState("")
    const [Review, setReview] = useState("")
    const [Rating, setRating] = useState(0)
    const [companyName, setCompanyName] = useState("")


    const handleRefreshToken = async () => {
        var formdata = new FormData();
        formdata.append("username", JSON.parse(localStorage.getItem("username")));
        formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
        formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
        const { data } = await axios.post("/users/token/refresh/", formdata);
        if (data.data.access) {
          localStorage.setItem("access", JSON.stringify(data.data.access));
        }
      };

    const getStuProfile=async()=>{
        handleRefreshToken();
        const { data } = await axios.get(`/placement/placed_students/?id=${EditId}`);
        console.log(data.data.studentsPlacementData);
        if(data.status=="0x0"){
            setStuName(data.data.studentsPlacementData.stud_name)
            setDesc(data.data.studentsPlacementData.description)
            setDesignation(data.data.studentsPlacementData.designation)
            setCompanyLogo(data.data.studentsPlacementData.company_logo)
            setReview(data.data.studentsPlacementData.review)
            setRating(data.data.studentsPlacementData.rating)
            setCompanyName(data.data.studentsPlacementData.company_name)
        }
    }

    const GetAllStuList=async()=>{
        const {data} = await axios.get(`/placement/placed_students/list?page=${pageNo}`)
        // console.log(data.data.studentsPlacementList)
        if(data.status=="0x0"){
            setAllStuList(data?.data?.studentsPlacementList);
            setTotalCount(data?.data?.studentsPlacementList.count);
            setListCount(data?.data?.studentsPlacementList.count / 10);
        }
    }

    const updateStu=async(e)=>{
        e.preventDefault()
        var formdata = new FormData();
        formdata.append("id", EditId);
        formdata.append("stud_name", StuName);
        formdata.append("description", Desc);
        formdata.append("profile_pic", ProfilePic);
        // formdata.append("company_logo", CompanyLogo);
        formdata.append("designation", Designation);
        formdata.append("review", Review);
        formdata.append("rating", Rating);
        formdata.append("company_name", companyName);

        const {data}=await axios.put("/placement/placed_students/",formdata)
        if(data.status="0x0"){
            GetAllStuList()
        }
    }

    useEffect(() => {
        GetAllStuList()
    }, [])

    useEffect(() => {
        GetAllStuList()
    }, [pageNo])

  useEffect(() => {
    getStuProfile();
  }, [EditId]);


    const handleNextPage = () => {
        if (pageNo < ListCount) {
          setpageNo((prev) => prev + 1);
          setlistNo((prev) => prev + 10);
        }
      };
    
      const handlePrevPage = () => {
        if (pageNo > 1) {
          setpageNo((prev) => prev - 1);
          setlistNo((prev) => prev - 10);
        }
      };

  return (
    <div>
        <h3 className='my-2'>Our Placed Students</h3>
        <div className="row">
            <table className="table table-striped">
              <thead className="table-dark">
                <tr>
                  <th className="text-center">Sr. No.</th>
                  <th>Student Name</th>
                  <th>Designation</th>
                  <th>Company</th>
                  <th>Profile Pic</th>
                  <th>Rating</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {AllStuList.length > 0 ? (
                  <>
                    {AllStuList?.map((stu, i) => (
                      <tr key={i}>
                        <td>{i + 1 + listNo}</td>
                        <td>
                          {stu?.stud_name}
                        </td>
                        <td>{stu?.designation}</td>
                        <td>{stu?.company_name==null?"---":stu?.company_name}</td>
                        <td><img src={stu?.profile_pic} width={35} alt="company_logo" /></td>
                        <td>{stu?.rating}</td>
                        <td>
                             <button
                                className="btn btn-info btn-sm mx-1"
                                data-bs-toggle="modal"
                                data-bs-target="#editPlacedStuModal"
                                onClick={() => setEditId(stu?.id)}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <h4 className="text-center my-5">No Students Found...</h4>
                )}
              </tbody>
            </table>
        </div>

           {/*============= pagination ============= */}

           <div className="container d-flex justify-content-between my-3 mb-5">
            <button className="btn btn-info mb-4" onClick={handlePrevPage}>
              Prev
            </button>

            <button className="btn btn-info mb-4" onClick={handleNextPage}>
              Next
            </button>
          </div>


           {/* ============ edit student =========== */}

      <div
        className="modal fade"
        id="editPlacedStuModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Student
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
                <form onSubmit={updateStu}>
                    <div className="row py-4">
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Student Name:</label>
                        <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setStuName(e.target.value)}
                        value={StuName}
                        placeholder="Enter Student Name"
                        
                        />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Description:</label>
                        <textarea name="" id="" cols="30" rows="1"
                        className="form-control"
                        placeholder='Enter Students Description'
                        onChange={(e) => setDesc(e.target.value)}
                        value={Desc}
                        ></textarea>
                    </div>

                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile Pic:</label>
                        <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setProfilePic(e.target.files[0])}
                        //   value={CompanyLogo}
                        placeholder="Choose Profile Pic"
                        
                        />
                    </div>

                    {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Company Logo:</label>
                        <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setCompanyLogo(e.target.files[0])}
                        //   value={CompanyLogo}
                        placeholder="Choose Company Logo"
                        
                        />
                    </div> */}

                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Company Name:</label>
                        <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                        placeholder="Company Name"
                        />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Designation:</label>
                        <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setDesignation(e.target.value)}
                        value={Designation}
                        placeholder="Designation"
                        
                        />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Review</label>
                        <textarea name="" id="" cols="30" rows="1"
                        className="form-control"
                        onChange={(e) => setReview(e.target.value)}
                        value={Review}
                        placeholder="Review"
                        
                        ></textarea>
                    </div>

                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Rating</label>
                        <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setRating(e.target.value)}
                        value={Rating}
                        placeholder="Enter Your Rating"
                        />
                    </div>

                    <div className="d-flex justify-content-end my-2">
                        <button className="btn btn-primary ms-2" data-bs-dismiss="modal">Update</button>
                    </div>
                    
                    </div>
                </form>    
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
