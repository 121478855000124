import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function InvoiceList() {
  const [StuList, setStuList] = useState([]);
  const [listNo, setlistNo] = useState(0);
  const [pageNo, setpageNo] = useState(1);
  const [ListCount, setListCount] = useState(0);
  const [TotalCount, setTotalCount] = useState();


  const getInvoiceList = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/payment/invoice/list/?page=${pageNo}`);
    if (data.status == "0x0") {
      setStuList(data.data.invoiceList.results);
      setTotalCount(data?.data?.invoiceList.count);
      setListCount(data?.data?.invoiceList.count / 20);
    }
  };

  useEffect(() => {
    getInvoiceList();
  }, []);

  useEffect(() => {
    getInvoiceList();
  }, [pageNo]);

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 20);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 20);
    }
  };

  return (
    <div>
      <div className="table table-responsive">
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th className="text-center">
                <p className="srNo">Sr. No.</p>
              </th>
              <th className="text-center">
                <p className="srNo">Invoice No.</p>
              </th>
              <th className="supportTable">
                <p className="supportTable">Name</p>
              </th>
              <th className="supportTable">
                <p className="supportTable">Email (Username)</p>
              </th>
              <th className="supportTable">
                <p className="supportTable">Phone No.</p>
              </th>
              <th className="supportTable">
                <p className="supportTable"> Total Fees</p>
              </th>
              <th className="supportTable">
                {" "}
                <div className="dropdown ">
                  <p
                    className="dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Fees Bal.
                  </p>
                </div>
              </th>
              <th className="supportTable">
                {" "}
                <p className="supportTable">Last payment Date</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {StuList?.length === 0 ? (
              <h2 className="text-center my-5">Empty stu</h2>
            ) : (
              <>
                {StuList?.map((stu, i) => (
                  <tr>
                    <td>{i + 1 + listNo}</td>
                    <td>{stu.invoice_no}</td>
                    <td>{stu.full_name}</td>
                    <td>{stu.email}</td>
                    <td>{stu.phone_number}</td>
                    <td>{stu.pitched_amount - stu.discount}</td>
                    <td>{stu.remaining_amount_to_be_paid}</td>
                    <td>{stu.payment_date}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between my-5">
        <button className="btn btn-info" onClick={handlePrevPage}>
          Prev
        </button>
        <button className="btn btn-info" onClick={handleNextPage}>
          Next
        </button>
      </div>
    </div>
  );
}
