import axios from "axios";
import React, { useEffect, useState } from "react";
// material ui
import BlockIcon from "@mui/icons-material/Block";

export default function CoachList() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("")
  const [Email, setEmail] = useState("");
  const [Username, setUsername] = useState("");
  const [ProfileState, setProfileState] = useState("");
  const [InternalFeedback, setInternalFeedback] = useState("")
  const [Summary, setSummary] = useState("");
  const [TagLine, setTagLine] = useState("");
  const [Gender, setGender] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [Experiance, setExperiance] = useState("");
  const [Charges, setCharges] = useState("");
  const [Experties, setExperties] = useState("");
  const [Certificate, setCertificate] = useState("");
  const [Capacity, setCapacity] = useState("");
  const [Remark, setRemark] = useState("");
  const [ProfilePic, setProfilePic] = useState("");
  const [Rating, setRating] = useState("");
  const [AllStuList, setAllStuList] = useState([]);
  const [EditId, setEditId] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [listNo, setlistNo] = useState(0);
  const [ListCount, setListCount] = useState(0);

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleGetAllCoaches = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    // const { data } = await axios.get(`/users/coach/list/?page=${pageNo}`);
    const { data } = await axios.get(`/users/coach/list/?data_from_old_db=False&page=${pageNo}`);
    if(data.status == "0x0"){
      setAllStuList(data?.data?.coach_list?.results);
      setListCount(data.data.coach_list.count / 10);
    }
  };
  console.log(pageNo);
  const getCoachProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(`/users/coach/profile/?id=${EditId}`);
    setFirstName(data.data.userData[0].first_name);
    setLastName(data.data.userData[0].last_name);
    setEmail(data.data.userData[0].email);
    setUsername(data.data.userData[0].username);
    setProfileState(data.data.userData[0].profile_state);
    setInternalFeedback(data.data.userData[0].internal_feedback);
    setSummary(data.data.userData[0].summary);
    setTagLine(data.data.userData[0].tag_line);
    setGender(data.data.userData[0].gender);
    setLinkedIn(data.data.userData[0].linkedin_url);
    setPhoneNo(data.data.userData[0].phone_numbers);
    setExperiance(data.data.userData[0].years_of_exp);
    setCharges(data.data.userData[0].charge_per_hour);
    setExperties(data.data.userData[0].subjects);
    setCertificate(data.data.userData[0].certification);
    setRating(data.data.userData[0].rating);
    setCapacity(data.data.userData[0].batch_handeling_capacity);
    setRemark(data.data.userData[0].remark);
    setProfilePic(data.data.userData[0].profilePic);
  };

  const EditCoach = async (e) => {
    console.log("hello coach");
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();
    // if (FirstName!=undefined) {
    //   formdata.append("first_name", FirstName);
    // }
    // if (LastName!=undefined) {
    //   formdata.append("last_name", LastName);
    // }
    // if (Email!=undefined) {
    //   formdata.append("email", Email);
    // }
    // if (Username!=undefined) {
    //   formdata.append("username", Username);
    // }
    // if (ProfileState!=undefined) {
    //   formdata.append("profile_state", ProfileState);
    // }
    // if (Gender!=undefined) {
    //   formdata.append("gender", Gender);
    // }
    // if (PhoneNo!=undefined) {
    //   formdata.append("phone_numbers", PhoneNo);
    // }
    // if (Education!=undefined) {
    //   formdata.append("education", Education);
    // }
    // // if (yrsOfGraduation==undefined) {
    // //   setyrsOfGraduation()
    // // }
    // if (yrsOfExp!=undefined) {
    //   formdata.append("years_of_exp", yrsOfExp);
    // }
    // if (City!=undefined) {
    //   formdata.append("current_city", City);
    // }
    // if (LinkedIn!=undefined) {
    //   formdata.append("linkedin_url", LinkedIn);
    // }
    // if (Industry!=undefined) {
    //   formdata.append("industry", Industry);
    // }
    // if (Designation!=undefined) {
    //   formdata.append("designation", Designation);
    // }
    // if (ClousererName!=undefined) {
    //   formdata.append("counsellor", ClousererName);
    // }
    // if (StuStatus!=undefined) {
    //   formdata.append("student_status", StuStatus);
    // }
    formdata.append("id", EditId);
    formdata.append("first_name", FirstName);
    formdata.append("last_name", LastName);
    formdata.append("email", Email);
    formdata.append("username", Email);
    formdata.append("profile_state", ProfileState);
    formdata.append("internal_feedback", Summary);
    formdata.append("tag_line", TagLine);
    formdata.append("gender", Gender);
    formdata.append("linkedin_url", LinkedIn);
    formdata.append("phone_numbers", PhoneNo);
    formdata.append("years_of_exp", Experiance);
    formdata.append("charge_per_hour", Charges);
    formdata.append("subjects", Experties);
    formdata.append("rating", Rating);
    formdata.append("certification", Certificate);
    formdata.append("batch_handeling_capacity", Capacity);
    formdata.append("remark", Remark);
    // formdata.append("profilePic", ProfilePic);
    const { data } = await axios.put(`/users/coach/profile/`, formdata);

    if (data.status == "0x0") {
      handleGetAllCoaches();
    }
  };

 

  const ActiveDeactivate = async (id, isActive) => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();

    if (isActive) {
      console.log("deactivating...");
      formdata.append("id", id);
      formdata.append("is_active", "False");
      const { data } = await axios.post(
        `/users/coach/profile/deactivate`,
        formdata
      );

      if ((data.status = "0x0")) {
        handleGetAllCoaches();
      }
    } else {
      console.log("reactivating...");

      formdata.append("id", id);
      formdata.append("is_active", "True");
      const { data } = await axios.post(
        `/users/coach/profile/reactivate`,
        formdata
      );

      if ((data.status = "0x0")) {
        handleGetAllCoaches();
      }
    }
  };

  // pagination
  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 10);
    }
  };

  const handleNextPage = () => {
    if (pageNo <= ListCount) {
      console.log("next");
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 10);
    }
  };

  useEffect(() => {
    getCoachProfile();
  }, [EditId]);

  useEffect(() => {
    handleGetAllCoaches();
  }, []);

  useEffect(() => {
    console.log("enter");
    handleGetAllCoaches();
  }, [pageNo]);

  return (
    <div>
      <h4 className="mb-4">Coach List</h4>
      <div className="row">
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Batch handeling Capacity</th>
              <th>Rating</th>
              <th>yrs of Exp.</th>
              <th>Charges</th>
              <th>Sub. Experties</th>
              <th>Username</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {AllStuList?.length > 0 ? (
              AllStuList?.map((coach, i) => (
                <tr style={{ cursor: "pointer" }}>
                  <td>{i + 1 + listNo}</td>
                  <td>{coach?.first_name}</td>
                  <td>{coach?.email}</td>
                  <td>{coach?.batch_handeling_capacity}</td>
                  <td>{coach?.rating}</td>
                  <td>{coach?.years_of_exp}</td>
                  <td>{coach?.charge_per_hour}</td>
                  <td>{coach?.subjects}</td>
                  <td>{coach?.username}</td>
                  <td>
                    <div className="d-flex justify-content-center align-items-center">
                    <BlockIcon
                      className={coach?.is_active ? "text-info" : "text-danger"}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        ActiveDeactivate(coach?.id, coach?.is_active)
                      }
                    />
                    <button
                      className="btn btn-info btn-sm ms-1"
                      data-bs-toggle="modal"
                      data-bs-target="#editCoachModal"
                      onClick={() => setEditId(coach?.id)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <h4 className="text-center my-5">No Data Found...</h4>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-between my-5">
          <button className="btn btn-info" onClick={handlePrevPage}>
            Prev
          </button>
          <button className="btn btn-info" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>

      {/* ============ edit coach ============= */}

      <div
        className="modal fade"
        id="editCoachModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Coach
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={EditCoach}>
                    <div className="row py-4">
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">FirstName:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="FirstName"
                          value={FirstName}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">LastName:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="LastName"
                          value={LastName}
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Email:</label>
                        <input
                          type="Email"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          value={Email}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Username:</label>
                        <input
                          type="text"
                          className="form-control"
                          // onChange={(e) => setUsername(e.target.value)}
                          placeholder="Username"
                          value={Email}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile State:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setProfileState(e.target.value)}
                          aria-label="Profile Status"
                          value={ProfileState}
                          
                        >
                          <option value="I">Incomplete</option>
                          <option value="C">Complete</option>
                        </select>
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Internal Feedback:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          type="text"
                          className="form-control"
                          onChange={(e) => setInternalFeedback(e.target.value)}
                          value={InternalFeedback}
                          placeholder="Internal Feedback"
                          
                        ></textarea>
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Tag line:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTagLine(e.target.value)}
                          value={TagLine}
                          placeholder="Tag line"
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Gender:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setGender(e.target.value)}
                          value={Gender}
                          aria-label="Gender"
                          
                        >
                          <option value="" selected>
                            Select Gender
                          </option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Phone no.:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setPhoneNo(e.target.value)}
                          placeholder="Phone No."
                          value={PhoneNo}
                          
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Rating:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setRating(e.target.value)}
                          placeholder="Rating"
                          value={Rating}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">linkedIn Url:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLinkedIn(e.target.value)}
                          placeholder="linkedIn Url"
                          value={LinkedIn}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Yrs. of Experiance:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setExperiance(e.target.value)}
                          placeholder="Yrs. of Experiance"
                          value={Experiance}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Subject Experties:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setExperties(e.target.value)}
                          placeholder="Subject Experties"
                          value={Experties}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Certifications:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setCertificate(e.target.value)}
                          placeholder="Certifications"
                          value={Certificate}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Per Hour Charge:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setCharges(e.target.value)}
                          placeholder="Per Hour Charge"
                          value={Charges}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Batch Handeling Capacity:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setCapacity(e.target.value)}
                          placeholder="Batch Handeling Capacity"
                          value={Capacity}
                          
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Remark:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setRemark(e.target.value)}
                          placeholder="Remark"
                          value={Remark}
                          
                        />
                      </div>
                      {/* <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Profile pic:</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setProfilePic(e.target.value)}
                        placeholder="Profile pic"
                        value={ProfilePic}
                        
                      />
                    </div> */}
                      <div className="d-flex justify-content-end mt-5">
                        <button
                          className="btn btn-info"
                          data-bs-dismiss="modal"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
