import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function StuView({StuId}) {
  
    const [allBatches, setallBatches] = useState([])

    const handleStuVilew=async()=>{
      const {data}=await axios.get(`/course/batch/student/v1_1?pay_id=${StuId}`)

      if( data.status == "0x0" ) {
        setallBatches(data.data.batchData)
      }

    }

    useEffect(() => {
      handleStuVilew()
    }, [])
    
  return (
    <div>
      {allBatches.length==0
      ?<h4 className='text-center my-5'>No Batches Found</h4>
      : <table className="table table-striped">
      <thead className="table-dark">
        <tr>
          <th className="text-center">Sr. No.</th>
          <th>Course Name</th>
          <th>batch-Id</th>
        </tr>
      </thead>
      <tbody>
        { allBatches?.map((batch, i) => (
          <tr>
            <td>{i+1}</td>
            <td>{batch.course_info}</td>
            <td>{batch.user_batch_id}</td>
          </tr>
        ))}
      </tbody>
    </table>
      }
    </div>
  )
}
