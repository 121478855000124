import React from "react";
import Topbar from "../Topbar";
import "../../style/myLMS.css"
// images 
import lmsLady from "../../images/myLMSLady.png"
import rightArrow from "../../images/rightArrow.png"

export default function MyLms() {
  return (
    <div>
      {/* <Topbar topBarHeading={"My LMS"} /> */}
      <div className="container">
        {/* <div className="d-flex justify-content-between mt-4 w-75">
          <h4>Join Your LMS</h4>
          <a href="https://coachxlive-s-school.teachable.com/" target="_blank">
            <button className="btn btn-light">Click Here</button>
          </a>
        </div> */}
        <div className="row recording-content">
          <div className="col-lg-6 col-12 pt-3 recording-left">
          <div className="my-4">
            <h3 className="lms-heading ms-3 mb-5">How to access the recordings?</h3>
          <ul>
            <li>
              <p>Username is your registered email id with us.</p>
            </li>
            <li>
              <p>
                Password is your first name 123. For e.g.: If my name is
                Radhika, my Password is radhika123 (No caps, no space)
              </p>
            </li>
            <li>
              <p>
                Change your password for security reasons once you are able to
                access your recordings.
              </p>
            </li>
            <li>
              <p>
                All the notes and assignments will be uploaded under the LMS
                with session recordings.
              </p>
            </li>
            <li>
              <p>
                Recordings will be available to you in the next few hours after
                the session.
              </p>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-center ">
          <a href="https://coachxlive-s-school.teachable.com/" target="_blank"><button className="btn lms-btn p-0"><span className="h-100 d-flex justify-content-between align-items-center"><span className="ms-4 btn-text">Get your LMS now</span> <span className="lmsImg-wrapper "><img src={rightArrow} alt="arrow" width="20" className=" arrowImg ms-2  me-3"/></span></span></button></a>
        </div>
          </div>
          <div className="col-lg-6 col-12 recording-right">
            <img src={lmsLady} alt="lmsImg" width="100%" />
          </div>
        </div>
      
      </div>
    </div>
  );
}
