import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Topbar from '../Topbar'
// images 
import handArrow from "../../images/downHand.png";
import payments from "../../images/paymentImg.png";
import hackerrank from "../../images/hackerRankImg.png";
import rightArrow from "../../images/rightArrow.png"
import rayzorpay from "../../images/Razorpay.png"

export default function HackerRank() {
  
  return (
    <div>
           {/* <Topbar topBarHeading={"Payments"} /> */}
        <div className="container hacker-wrapper">
        {/* <div className="d-flex justify-content-between mt-4 w-75">
        <h4>Pay Your Installments</h4>
        <a href="https://mui.com/material-ui/icons/" target="_blank">
            <button className='btn btn-light'>Click Here</button>
        </a>
        </div> */}
         <div className="row mt-5 pt-5">
          <div className="col-lg-6 col-12">
            <div className="">
              <img src={hackerrank} alt="recordImg" width="100%" />
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-4 mt-lg-0 d-flex justify-content-center ">
            <div className="w-100 d-flex justify-content-evenly align-items-center flex-column">
              
              <div>
                <h6 className="recText">Gain Your Hacker Rank Badge Here</h6>
              </div>
              {/* <div>
                <p className='text-danger'>Delay in payments may disable your LMS</p>
              </div> */}
              <div className="">
                <img src={handArrow} alt="handArrow" width="80" />
              </div>
              <div>
                <a href=" https://www.hackerrank.com/" target="_blank">
                  <button className="accessRecordingBtn btn">Click Here</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}
