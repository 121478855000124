import axios from "axios";
import React, { useEffect, useState } from "react";



export default function CourseList() {
  const [Title, setTitle] = useState("");
  const [Summary, setSummary] = useState("");
  const [desc, setdesc] = useState("");
  const [Language, setLanguage] = useState("");
  const [ModuleCount, setModuleCount] = useState("");
  const [SessionCount, setSessionCount] = useState("");
  const [Fees, setFees] = useState("");
  const [DiscountedFees, setDiscountedFees] = useState("");
  const [Syllabus, setSyllabus] = useState("");
  const [CourseType, setCourseType] = useState("");
  const [Prerequisite, setPrerequisite] = useState("");
  const [CourseLevel, setCourseLevel] = useState("");
  const [AgeGrp, setAgeGrp] = useState("");
  const [Category, setCategory] = useState("");
  const [CourseFeature, setCourseFeature] = useState("");
  const [Roadmap, setRoadmap] = useState("");
  const [Brochure, setBrochure] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [RegURL, setRegURL] = useState("");
  const [CourseImg, setCourseImg] = useState("");
  const [CourseVideo, setCourseVideo] = useState("");
  const [CourseId, setCourseId] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [TotalCount, setTotalCount] = useState();
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);
  const [AvgRating, setAvgRating] = useState(0);
  const [Duration, setDuration] = useState("");
  const [Type, setType] = useState("");
  const [DummyCert, setDummyCert] = useState("");
  const [DurationInMonths, setDurationInMonths] = useState(0);
  const [Frequency, setFrequency] = useState([]);
  const [Size, setSize] = useState(0);
  const [ShowCourse, setShowCourse] = useState("");


  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post(
      "/users/token/refresh/",
      formdata
    );
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const getAllCourses = async () => {
    const { data } = await axios.get(`/course/course/list?data_from_old_db=False&page=${pageNo}`);

    if (data.status == "0x0") {
      setCourseList(data.data.course_list.results);
      setTotalCount(data?.data?.course_list.count);
      setListCount(data?.data?.course_list.count / 10);
    }

  };

useEffect(() => {
  getAllCourses()
}, [pageNo])


  useEffect(() => {
    if (CourseId != "") {
      getCourse();
    }
  }, [CourseId]);

  const getCourse = async () => {
    const { data } = await axios.get(
      `/course/course/?id=${CourseId}`
    );
    setCourseVideo(data.data.courseData.course_video);
    setCourseImg(data.data.courseData.course_image);
    setRegURL(data.data.courseData.registration_url);
    setStartDate(data.data.courseData.start_date);
    setBrochure(data.data.courseData.brochure);
    setRoadmap(data.data.courseData.roadmap);
    setCourseFeature(data.data.courseData.course_key_feature);
    setCategory(data.data.courseData.category);
    setAgeGrp(data.data.courseData.age_group);
    setCourseLevel(data.data.courseData.course_level);
    setPrerequisite(data.data.courseData.prerequisite);
    setCourseType(data.data.courseData.course_type);
    setSyllabus(data.data.courseData.syllabus);
    setFees(data.data.courseData.fees);
    setDiscountedFees(data.data.courseData.discounted_fees);
    setSessionCount(data.data.courseData.session_count);
    setLanguage(data.data.courseData.language);
    setModuleCount(data.data.courseData.module_count);
    setdesc(data.data.courseData.description);
    setSummary(data.data.courseData.summary);
    setTitle(data.data.courseData.title);
    setAvgRating(data.data.courseData.avg_rating);
    setDuration(data.data.courseData.duration);
    setType(data.data.courseData.type);
    setDummyCert(data.data.courseData.course_dummy_certificate);
    setFrequency(data.data.courseData.frequency);
    setShowCourse(data.data.courseData.ShowCourse);
    setDurationInMonths(data.data.courseData.course_duration_in_months);
    setSize(data.data.courseData.size);
  };

  const EditCourse = async (e) => {
    e.preventDefault();
    handleRefreshToken();

    const token = JSON.parse(localStorage.getItem("access"));

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("title", Title);
    formdata.append("summary", Summary);
    formdata.append("description", desc);
    formdata.append("language", Language);
    formdata.append("module_count", ModuleCount);
    formdata.append("session_count", SessionCount);
    formdata.append("fees", Fees);
    formdata.append("discounted_fees", DiscountedFees);
    formdata.append("syllabus", Syllabus);
    formdata.append("course_type", CourseType);
    formdata.append("prerequisite", Prerequisite);
    formdata.append("course_level", CourseLevel);
    formdata.append("age_group", AgeGrp);
    formdata.append("category", Category);
    formdata.append("course_key_feature", CourseFeature);
    formdata.append("roadmap", Roadmap);
    formdata.append("brochure", Brochure);
    formdata.append("start_date", StartDate);
    formdata.append("registration_url", RegURL);
    formdata.append("course_image", CourseImg);
    formdata.append("avg_rating", AvgRating);
    formdata.append("duration", Duration);
    formdata.append("type", Type);
    formdata.append("course_dummy_certificate", DummyCert);
    formdata.append("course_duration_in_months", DurationInMonths);
    formdata.append("frequency", "");
    formdata.append("size", Size);
    formdata.append("course_video", CourseVideo);
    formdata.append("show_course", ShowCourse);
    formdata.append("id", CourseId);

    const { data } = await axios.put(`/course/course/`, formdata);

    if (data.status == "0x0") {
      getAllCourses();
    }
  };

  // pagination 

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 10);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 10);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  return (
    <div>
<h4 className="mb-4">Course List</h4>
      <div className="row">
        <table className="table table-striped">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Sr. No.</th>
              <th>Course Name</th>
              {/* <th>Module Count</th> */}
              {/* <th>Session Count</th> */}
              <th>Start Date</th>
              <th>Course Type</th>
              <th>Course Level</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {CourseList?.map((course, i) => (
              <tr key={i}>
                <td>{i + 1+listNo}</td>
                <td>{course?.title}</td>
                <td>{course?.start_date}</td>
                <td>
                  {course?.course_type == "C" && "Course"}
                  {course?.course_type == "L" && "Live"}
                  {course?.course_type == "W" && "Workshop"}
                </td>
                <td>
                  {course?.course_level == "B" && "Beginner"}
                  {course?.course_level == "I" && "Intermediate"}
                  {course?.course_level == "E" && "Expert"}
                </td>
                <td>
               
                  <button
                    className="btn btn-info btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#EditCourseModal"
                    onClick={() => setCourseId(course?.id)}
                  >
                    <i className="bi bi-pencil-square"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/*============= pagination ============= */}

        <div className="container d-flex justify-content-between my-5">
                <button className="btn btn-info"
                 onClick={handlePrevPage}
                 >
                  Prev
                </button>

                <button className="btn btn-info"
                 onClick={handleNextPage}
                 >
                  Next
                </button>
        </div>
      </div>

      {/* edit course model  */}

      <div
        className="modal fade"
        id="EditCourseModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Course
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={EditCourse}>
                    <div className="row py-4">
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Title:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setTitle(e.target.value)}
                          value={Title}
                          placeholder="Title"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Summary:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          className="form-control"
                          onChange={(e) => setSummary(e.target.value)}
                          value={Summary}
                          placeholder="Summary"
                        ></textarea>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Desc:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          className="form-control"
                          onChange={(e) => setdesc(e.target.value)}
                          value={desc}
                          placeholder="Desc"
                        ></textarea>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Language:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLanguage(e.target.value)}
                          value={Language}
                          placeholder="Select Language"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Module Count:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setModuleCount(e.target.value)}
                          value={ModuleCount}
                          placeholder="Module Count"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Session Count:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setSessionCount(e.target.value)}
                          value={SessionCount}
                          placeholder="Session Count"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Fees:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setFees(e.target.value)}
                          value={Fees}
                          placeholder="Fees"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Discounted Fees:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDiscountedFees(e.target.value)}
                          value={DiscountedFees}
                          placeholder="Discounted Fees"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Syllabus:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="1"
                          onChange={(e) => setSyllabus(e.target.value)}
                          value={Syllabus}
                          className="form-control"
                          placeholder="Syllabus"
                        ></textarea>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Prerequisite:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setPrerequisite(e.target.value)}
                          value={Prerequisite}
                          placeholder="Prerequisite"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Type:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCourseType(e.target.value)}
                          value={CourseType}
                          aria-label="Default select example"
                        >
                          <option>Select Level</option>
                          <option value="L">Live</option>
                          <option value="P">Pre-Recorded</option>
                        </select>
                      </div>
                      
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Level:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCourseLevel(e.target.value)}
                          value={CourseLevel}
                          aria-label="Default select example"
                        >
                          <option>Select Level</option>
                          <option value="B">Beginner</option>
                          <option value="I">Intermediate</option>
                          <option value="E">Expert</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Age Group:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setAgeGrp(e.target.value)}
                          value={AgeGrp}
                          placeholder="Age Group"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course category:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCategory(e.target.value)}
                          value={Category}
                          aria-label="Default select example"
                        >
                          <option>Select Course category</option>
                          <option value="tech">tech</option>
                          <option value="design">design</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Feature:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setCourseFeature(e.target.value)}
                          value={CourseFeature}
                          placeholder="Course Feature"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Roadmap Img:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setRoadmap(e.target.files[0])}
                          // value={Roadmap}
                          placeholder="Roadmap Img"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Brochure:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setBrochure(e.target.files[0])}
                          // value={Brochure}
                          placeholder="Brochure"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Start Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setStartDate(e.target.value)}
                          value={StartDate}
                          placeholder="Start Date"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">RegURL:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setRegURL(e.target.value)}
                          value={RegURL}
                          placeholder="setRegURL"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Img:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setCourseImg(e.target.files[0])}
                          // value={CourseImg}
                          placeholder="Course Img"
                        />
                      </div>
                      {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Video:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setCourseVideo(e.target.files[0])}
                          // value={CourseVideo}
                          placeholder="Course Video"
                        />
                      </div> */}
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Avg Rating:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setAvgRating(e.target.value)}
                          value={AvgRating}
                          placeholder="Avg Rating"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Duration In Hours:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDuration(e.target.value)}
                          value={Duration}
                          placeholder="Course Duration In Hourse"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Duration In Months:</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setDurationInMonths(e.target.value)}
                          value={DurationInMonths}
                          placeholder="Course Duration In Months"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Type:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setType(e.target.value)}
                          value={Type}
                          aria-label="Default select example"
                        >
                          <option>Select Type</option>
                          <option value="W">Workshop</option>
                          <option value="C">Course</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Dummy Certificate:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setDummyCert(e.target.files[0])}
                          // value={Duration}
                          placeholder="Dummy Certificate"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Size:</label>
                        <input
                          type="Number"
                          className="form-control"
                          onChange={(e) => setSize(e.target.value)}
                          value={Size}
                          placeholder="Course Size"
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-5">
                        <button
                          className="btn btn-info"
                          data-bs-dismiss="modal"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
