import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Topbar from '../Topbar'
// images 
import handArrow from "../../images/downHand.png";
import comunityImg from "../../images/comunityImg.png";


export default function Community() {
  const [MyBatches, setMyBatches] = useState([])

  const getMyBatches=async()=>{
    const userId = JSON.parse(localStorage.getItem("Userid"));
    const {data}=await axios.get(`/course/batch/student?stu_id=${userId}`)

    if (data.data.status="0x0") {
      setMyBatches(data.data.batchData)
    }

  }

  useEffect(() => {
    const userGrp = JSON.parse(localStorage.getItem("user_group"));
    if (userGrp.includes("G1Student")) {
      getMyBatches()
    }
  }, [])

  return (
    <div>
        {/* <Topbar topBarHeading={"Community"} /> */}

        <h4 className="mb-4 ms-4">Community Link</h4>
        <div className="container">
          {
            MyBatches?.length>=1
            ?<>
              <div className="row">
          <div className="col-lg-6 col-12">
            <div className="">
              <img src={comunityImg} alt="recordImg" width="100%" />
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-4 mt-lg-0 d-flex justify-content-center ">
          {MyBatches?.map((batch,i)=>(
            <div className="w-100 d-flex justify-content-evenly align-items-center flex-column">
              <div>
                <h6 className="recText">Join Your Telegram Community</h6>
              </div>
              <div>
              <p className='text-center'>Batch ID: {batch?.user_batch_id}</p>
              </div>
              <div className="">
                <img src={handArrow} alt="handArrow" width="80" />
              </div>
              <div>
                <a href={batch?.community_link} target="_blank">
                  <button className="accessRecordingBtn btn">Meet Your Classmates Now</button>
                </a>
              </div>
            </div>
                      ))}

          </div>
        </div>
                {/* {MyBatches?.map((batch,i)=>(
          <div className='my-4'>
            <h5 className='text-center'>Batch ID: {batch?.user_batch_id}</h5>
            <div className="d-flex justify-content-between mt-4 w-75">
              <h5>Community Links</h5>
              <a href={batch?.community_link} target="_blank">
                  <button className='btn btn-info'>Click Here</button>
              </a>
            </div>
            <hr/>
          </div>

          ))}  */}
            </>
            : <h4 className='text-center my-5'>Please... Enroll Course & Batch.</h4>
          }
       
        </div>
    </div>
  )
}
