import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// material ui
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";


export default function EditBatch() {
    const [CourseId, setCourseId] = useState("");
  const [BatchId, setBatchId] = useState("");
  const [BatchName, setBatchName] = useState("");
  const [BatchStatus, setBatchStatus] = useState("");
  const [BatchTiming, setBatchTiming] = useState("");
  const [Trainer, setTrainer] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [EditId, setEditId] = useState("");

  const [Coach, setCoach] = useState({});
  const [allBatches, setallBatches] = useState([]);
  const [AllCoaches, setAllCoaches] = useState([]);
  const [CommLink, setCommLink] = useState("");
  const [AssLink, setAssLink] = useState("");
  const [satLink, setsatLink] = useState("");
  const [sunLink, setsunLink] = useState("");
  const [doubtLink, setdoubtLink] = useState("");

  const getBatch = async () => {
    const { data } = await axios.get(`/course/batch/?user_batch_id=${BatchId}`);
    if (data.status == "0x0") {
      // setCourseId(data.data.batchData.course_info);
      setBatchId(data.data.batchData.user_batch_id);
      setBatchName(data.data.batchData.title);
      setBatchStatus(data.data.batchData.batch_status);
      setBatchTiming(data.data.batchData.batch_timing);
      setTrainer(data.data.batchData.coaches);
      setallBatches(data.data.batch_list.results);
      setStartDate(data.data.batchData.start_date);
      setsatLink(data.data.batchData.saturday_session_zoom_link);
      setsunLink(data.data.batchData.sunday_session_zoom_link);
      setdoubtLink(data.data.batchData.doubt_solving_session_zoom_link);
      setAssLink(data.data.batchData.assignment_link);
      setCommLink(data.data.batchData.community_link);
    }
  };

  const handleChange = (event) => {
    const test = {
      ...Coach,
      [event.target.name]: event.target.checked,
    };
    setCoach(test);
  };

  const editBatches = async () => {
    const cochesId = [];
    Object.entries(Coach).map((key, value) => {
      if (key[1]) cochesId.push(key[0]);
    });

    var formdata = new FormData();
    formdata.append("user_batch_id", BatchId);
    formdata.append("title", BatchName);
    formdata.append("course_info", CourseId);
    formdata.append("start_date", StartDate);
    formdata.append("batch_timing", BatchTiming);
    formdata.append("batch_status", BatchStatus);
    // formdata.append("coaches", JSON.stringify(cochesId));
    formdata.append("coaches", [
      "83bc7def-a682-4f51-8f10-e9beaba06f57",
      "e118bde8-444c-41fc-9281-c334cd57313d",
    ]);

    const { data } = await axios.put("/course/batch/list");
    if (data.status) {
      setallBatches(data.data.batch_list.results);
    }
  };

  const getAllCoaches = async (e) => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/users/coach/list/");
    if (data.status == "0x0") {
      setAllCoaches(data.data.coach_list.results);
    }
  };

  useEffect(() => {
    if (BatchId !== "") {
      getBatch();
    }
  }, [BatchId]);
  
  const [Edit, setEdit] = useState("")

  // const edit=()=>{
  //   console.log("hey");
  //   setEdit("edit")
  // }

  return (
    <div className='container'>
      <div>
        <h3 className='text-center mt-3'>Edit Batch</h3>
        <form onSubmit={editBatches}>
                  <div className="row mt-3">
                    <div className="col-6  my-2">
                      <label htmlFor="">Batch ID (UUID):</label>
                      <input
                        type="text"
                        placeholder="Batch ID (UUID)"
                        className="form-control"
                        onChange={(e) => setBatchId(e.target.value)}
                        value={BatchId}
                      />
                    </div>

                    <div className="col-6 my-2">
                      <label htmlFor="">Batch Name:</label>
                      <input
                        type="text"
                        placeholder="Batch Name"
                        className="form-control"
                        onChange={(e) => setBatchName(e.target.value)}
                        value={BatchName}
                      />
                    </div>

                    <div className="col-6 my-2">
                      <label htmlFor="">Course:</label>
                      <select
                        className="form-select"
                        name="UserRole"
                        onChange={(e) => setCourseId(e.target.value)}
                        value={CourseId}
                        aria-label="Default select example"
                      >
                        <option value="" selected>
                          Select Course Name
                        </option>
                        {CourseList?.map((course, i) => (
                          <option value={course.id}>{course.title}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6 my-2">
                      <label htmlFor="">Batch Status:</label>
                      <select
                        className="form-select"
                        name="UserRole"
                        onChange={(e) => setBatchStatus(e.target.value)}
                        value={BatchStatus}
                        aria-label="Default select example"
                      >
                        <option selected>Select Batch Status</option>
                        <option value="S">Scheduled</option>
                        <option value="N">Not Scheduled</option>
                        <option value="R">Running</option>
                        <option value="C">Completed</option>
                        <option value="F">Force Completed</option>
                      </select>
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Batch Timing:</label>
                      <input
                        type="time"
                        placeholder="Batch Timing"
                        className="form-control"
                        onChange={(e) => setBatchTiming(e.target.value)}
                        value={BatchTiming}
                      />
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Assignment Link:</label>
                      <input
                        type="text"
                        placeholder="Assignment Link"
                        className="form-control"
                        onChange={(e) => setAssLink(e.target.value)}
                        value={AssLink}
                      />
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Community Link:</label>
                      <input
                        type="text"
                        placeholder="Community Link"
                        className="form-control"
                        onChange={(e) => setCommLink(e.target.value)}
                        value={CommLink}
                      />
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Sunday Session Link:</label>
                      <ReactQuill
                        theme="snow"
                        // onChange={(e) => setZoomLink(e.target.value)}
                        onChange={setsunLink}
                        value={sunLink}
                        width="100%"
                      />
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Saturday Session Link:</label>
                      <ReactQuill
                        theme="snow"
                        // onChange={(e) => setZoomLink(e.target.value)}
                        onChange={setsatLink}
                        value={satLink}
                        width="100%"
                      />
                    </div>
                    <div className="col-6 my-2">
                      <label htmlFor="">Doubt Solving Link:</label>
                      <ReactQuill
                        theme="snow"
                        // onChange={(e) => setZoomLink(e.target.value)}
                        onChange={setdoubtLink}
                        value={doubtLink}
                        width="100%"
                      />
                    </div>

                    <div className="col-6 my-2">
                      <label htmlFor="">Batch Start Date:</label>
                      <input
                        type="date"
                        placeholder="Current Trainer"
                        className="form-control"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={StartDate}
                      />
                    </div>
                    <div className="col-6 my-4">
                      <button
                      type='button'
                        className="btn-light btn w-100"
                        data-bs-target="#getCoachModal"
                        data-bs-toggle="modal"
                        onClick={getAllCoaches}
                      >
                        Select Coaches
                      </button>
                    </div>
                    <div className="col-12 my-4 d-flex justify-content-end">
                      <button className="btn btn-primary">save changes</button>
                    </div>
                  </div>
          </form>
      </div>
    </div>
  )
}
