import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// material ui
import CircularProgress from "@mui/joy/CircularProgress";
import SearchBar from "material-ui-search-bar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function StuList() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Username, setUsername] = useState("");
  const [ProfileState, setProfileState] = useState("I");
  const [CourseName, setCourseName] = useState("");
  const [RegAmmt, setRegAmmt] = useState("");
  const [Education, setEducation] = useState("");
  const [Gender, setGender] = useState("");
  const [yrsOfGraduation, setyrsOfGraduation] = useState("");
  const [yrsOfExp, setyrsOfExp] = useState(0);
  const [StuStatus, setStuStatus] = useState("");
  const [TrainerName, setTrainerName] = useState("");
  const [PitchedAmount, setPitchedAmount] = useState("");
  const [CourseFee, setCourseFee] = useState("");
  const [NoOfInstallment, setNoOfInstallment] = useState("");
  const [InstallmentDetail, setInstallmentDetail] = useState("");
  const [PaymentDate, setPaymentDate] = useState("");
  const [ClousererName, setClousererName] = useState("");
  const [FinalFee, setFinalFee] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [AllStuList, setAllStuList] = useState([]);
  const [deleteId, setdeleteId] = useState("");
  const [EditId, setEditId] = useState("");
  const [City, setCity] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Discount, setDiscount] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [UserData, setUserData] = useState();
  const [CourseId, setCourseId] = useState("");
  const [BatchesList, setBatchesList] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [ListUpdate, setListUpdate] = useState(true);
  const [Modal, setModal] = useState();
  const [ProfilePic, setProfilePic] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [listNo, setlistNo] = useState(0);
  const [ListCount, setListCount] = useState(0);
  const [TotalCount, setTotalCount] = useState();
  const [Installment1, setInstallment1] = useState();
  const [Installment2, setInstallment2] = useState();
  const [Installment3, setInstallment3] = useState();
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [Search, setSearch] = useState("");
  const [clouser, setClouser] = useState({});
  const [course, setCourse] = useState({});
  const [IsFiltersActive, setIsFiltersActive] = useState(false);
  const userGrp = JSON.parse(localStorage.getItem("user_group"));
  const name = JSON.parse(localStorage.getItem("name"));
  const [SalesUser, setSalesUser] = useState(
    userGrp.includes("G1Sales") && userGrp.length == 1 ? [name] : []
  );
  const [sort, setSort] = useState("");
  const [CourseTitle, setCourseTitle] = useState([]);

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 20);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 20);
    }
  };

  const asignBatch = async () => {
    console.log("hello");
    // TODO: remove this handleRefreshToken
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();
    formdata.append("batch_id", BatchId);
    formdata.append("students", EditId);
    const { data } = await axios.put("/course/batch/enroll", formdata);
    if (data.status == "0x0") {
      setModal(true);
      handleGetAllStu();
      setModal(false);
    }
  };

  const handleEditStu = async (e) => {
    e.preventDefault();
    // TODO: remove this handleRefreshToken
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    // formdata.append("first_name", FirstName);
    // formdata.append("last_name", "");
    // formdata.append("email", Email);
    // formdata.append("username", Email);
    // formdata.append("profile_state", ProfileState);
    // formdata.append("gender", Gender);
    // formdata.append("phone_numbers", PhoneNo);
    // formdata.append("education", Education);
    // formdata.append("year_of_graduation", yrsOfGraduation);
    // formdata.append("years_of_exp", yrsOfExp);
    // formdata.append("current_city", City);
    // formdata.append("linkedin_url", LinkedIn);
    // formdata.append("entry", "2023-2-24");
    // formdata.append("industry", Industry);
    // formdata.append("designation", Designation);
    // formdata.append("counsellor", ClousererName);
    // formdata.append("student_status", "R");
    // formdata.append("profilePic", ProfilePic);
    // formdata.append("id", EditId);
    //////////////////////////////////////////////////////////
    formdata.append("first_name", FirstName);
    // formdata.append("last_name", "");
    formdata.append("email", Email);
    formdata.append("username", Email);
    formdata.append("profile_state", ProfileState);
    formdata.append("gender", Gender);
    formdata.append("phone_numbers", PhoneNo);
    formdata.append("education", Education);
    formdata.append("year_of_graduation", yrsOfGraduation);
    formdata.append("years_of_exp", yrsOfExp);
    formdata.append("current_city", City);
    formdata.append("linkedin_url", LinkedIn);
    // formdata.append("entry", "2023-2-24");
    formdata.append("industry", Industry);
    formdata.append("designation", Designation);
    formdata.append("counsellor", ClousererName);
    formdata.append("student_status", "R");
    // formdata.append("profilePic", "");
    formdata.append("payment_id", EditId);
    formdata.append("payment_date", PaymentDate);
    formdata.append("registration_amount", RegAmmt);
    formdata.append("final_amount", FinalFee);
    formdata.append("installment1", Installment1);
    formdata.append("installment2", Installment2);
    formdata.append("installment3", Installment3);
    formdata.append("payment_type", PaymentType);
    formdata.append("payment_status", PaymentStatus);

    // const { data } = await axios.put("/users/student/profile/", formdata);
    const { data } = await axios.put(
      "/users/student/create_payment/",
      formdata
    );

    if (BatchId != "") {
      asignBatch();
    }

    if (data.status == "0x0") {
      handleGetAllStu();
    }
  };

  const getStuProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(
      `/users/student/create_payment?payment_id=${EditId}`
    );
    if (data.status == "0x0") {
      setUserData(data?.data?.userData);
      setFirstName(data?.data?.userData.first_name);
      setLastName(data?.data?.userData.last_name);
      setEmail(data?.data?.userData.username);
      setUsername(data?.data?.userData.username);
      setProfileState(data?.data?.userData.profile_state);
      setGender(data?.data?.userData.gender);
      setPhoneNo(data?.data?.userData.phone_numbers);
      setEducation(data?.data?.userData.education);
      setyrsOfGraduation(data?.data?.userData.yr_of_graduation);
      setyrsOfExp(data?.data?.userData.yrs_of_exp);
      setCity(data?.data?.userData.current_city);
      setLinkedIn(data?.data?.userData.linkedin_url);
      // setEmail(data?.data?.userData.email)
      setIndustry(data?.data?.userData.industry);
      setDesignation(data?.data?.userData.designation);
      setClousererName(data?.data?.userData.counsellor);
      // setEmail(data?.data?.userData.payment_date)
      setPaymentDate(data?.data?.userData.payment_date);
      setRegAmmt(data?.data?.userData.registration_amount);
      setPitchedAmount(data?.data?.userData.pitched_amount);
      setDiscount(data?.data?.userData.discount);
      setFinalFee(data?.data?.userData.final_amount);
      setPaymentDate(data?.data?.userData.payment_date);
      setRegAmmt(data?.data?.userData.registration_amount);
      // setFinalFee(data?.data?.userData.final_amount);
      setInstallment1(data?.data?.userData.installment1);
      setInstallment2(data?.data?.userData.installment2);
      setInstallment3(data?.data?.userData.installment3);
      setPaymentType(data?.data?.userData.payment_type);
      setPaymentStatus(data?.data?.userData.payment_status);

      setBatchId("");
      setCourseId("");
      setCourseList([]);
      setBatchesList([]);
    }
  };

  const handleGetAllCourse = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/course/course/list");
    setCourseList(data.data.course_list.results);
  };

  const GetAllBatchesByCourse = async () => {
    var formdata = new FormData();
    // formdata.append("course_info", );
    const { data } = await axios.get(
      `/course/batch/list?course_info=${CourseId}`
    );
    setBatchesList(data.data.batch_list.results);
    console.log(data.data.batch_list.results);
  };

  useEffect(() => {
    if (CourseId != "") {
      GetAllBatchesByCourse();
    }
  }, [CourseId]);

  useEffect(() => {
    getStuProfile();
    handleGetAllCourse();
  }, [EditId]);

  // search

  const handleSearch = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    // const { data } = await axios.get(`/users/student/list/?search=${Search}`);
    const { data } = await axios.get(
      `/users/student/create_payment/list?page=${pageNo}&search=${Search}&counsellor=${JSON.stringify(
        SalesUser
      )}&course=${JSON.stringify(CourseTitle)}&sort=${sort}`
    );

    if (data.status == "0x0") {
      setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 20);
      // setIsLoading(false)
    }
  };

  //get all student

  const handleGetAllStu = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.get(
      `/users/student/create_payment/list?page=${pageNo}&counsellor=${JSON.stringify(
        SalesUser
      )}&search=${Search}&course=${JSON.stringify(CourseTitle)}&sort=${sort}`
      // &sales_lead_info__course_info__title=${}
    );

    if (data.status == "0x0") {
      setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 20);
      setIsLoading(false);
    }
  };

  // filter
  const clousers = ["Gaurav", "Shubhankar", "Tanya", "Rahul", "Renuka","Himanshu","Trapti","Jayesh","Prem","Shreyas","Sourav","Nirmal","Aman","Rahul D","Rituraj","Pratik"];

  const handleClouserFilter = (event) => {
    const testClouser = {
      ...clouser,
      [event.target.name]: event.target.checked,
    };
    setClouser(testClouser);
  };

  const handleCourseFilter = (event) => {
    const testCourse = {
      ...course,
      [event.target.name]: event.target.checked,
    };
    setCourse(testCourse);
  };

  const createFilter = async () => {
    // e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const Clouser = [];
    Object.entries(clouser).map((key, value) => {
      if (key[1]) Clouser.push(key[0]);
    });

    const Course = [];
    Object.entries(course).map((key, value) => {
      if (key[1]) Course.push(key[0]);
    });

    const { data } = await axios.get(
      `/users/student/filter/course/?course_name=${JSON.stringify(
        Course
      )}&counsellor=${JSON.stringify(Clouser)}&page=${pageNo}`
    );

    if (data.status == "0x0") {
      setAllStuList(data?.data?.userData.results);
      setTotalCount(data?.data?.userData.count);
      setListCount(data?.data?.userData.count / 20);
      setIsLoading(false);
      if (Clouser.length == 0 && Course == 0) {
        setIsFiltersActive(false);
      } else {
        setIsFiltersActive(true);
      }
    }
  };

  useEffect(() => {
    handleSearch();
  }, [Search, pageNo]);

  useEffect(() => {
    setpageNo(1);
    setlistNo(0);
    handleGetAllStu();
  }, [sort]);


  useEffect(() => {
    handleGetAllStu();
  }, []);

  return (
    <div className="">
      {/* ============ student List ============*/}
      <h4>Student List</h4>
      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <>
          <div className="row my-3 ms-3">
            <div className="col-10">
              <SearchBar
                value={Search}
                onChange={(newValue) => setSearch(newValue)}
                onCancelSearch={(newValue) => setSearch("")}
                // placeholder	=
                style={{ padding: "-10px" }}
              />
            </div>

            {/* <div className="col-2">
              <button
                className="btn btn-info btn-lg"
                data-bs-target="#ClouserFilterModal"
                data-bs-toggle="modal"
              >
                Filters <i className="ms-1 bi bi-funnel"></i>
              </button>
            </div> */}
          </div>

          <div className="row">
            <table className="table table-striped">
              <thead className="table-dark">
                <tr>
                  <th className="text-center">Sr. No.</th>
                  <th>Names</th>
                  <th>Username</th>
                  <th>Course</th>
                  <th>Batch Id</th>
                  <th>Phone No.</th>
                  <th>Total Fees</th>
                  <th>Reg.Amount</th>
                  <th>
                    <div className="dropdown">
                      <span
                        className="dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Fees Bal.
                      </span>
                      <ul className="dropdown-menu">
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => setSort("")}
                        >
                          <span className="dropdown-item">All students</span>
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => setSort("D")}
                        >
                          <span className="dropdown-item">Descending</span>
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={() => setSort("A")}
                        >
                          <span className="dropdown-item">Ascending</span>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th>Payment Date</th>
                  <th>Clouserer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {AllStuList.length > 0 ? (
                  <>
                    {AllStuList?.map((item, i) => (
                      <tr style={{ cursor: "pointer" }} key={i}>
                        <td>{i + 1 + listNo}</td>
                        <td>
                          {item?.first_name} {item?.last_name}
                        </td>
                        <td>{item?.username}</td>
                        <td>{item?.course}</td>
                        <td>{item?.batch_Id==null?"---":item?.batch_Id}</td>
                        <td>{item?.phone_numbers}</td>
                        <td>{item?.final_amount}</td>
                        <td>{item?.registration_amount}</td>
                        <td>
                          {item?.remaining_amount}
                        </td>
                        <td>{item?.payment_date}</td>
                        <td>{item?.counsellor}</td>
                        <td>
                          <button
                            className="btn btn-info btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#editStuModal"
                            onClick={() => setEditId(item?.payment_id)}
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <h4 className="text-center my-5">No Students Found...</h4>
                )}
              </tbody>
            </table>
          </div>

          {/*============= pagination ============= */}

          <div className="container d-flex justify-content-between my-3 mb-5">
            <button className="btn btn-info mb-4" onClick={handlePrevPage}>
              Prev
            </button>

            <button className="btn btn-info mb-4" onClick={handleNextPage}>
              Next
            </button>
          </div>
        </>
      )}

      {/* ============ edit student =========== */}

      <div
        className="modal fade"
        id="editStuModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Student
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={handleEditStu}>
                    <div className="row py-4">
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Student Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={FirstName}
                          placeholder="Student Name"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Email:</label>
                        <input
                          type="Email"
                          className="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          value={Email}
                          placeholder="Email"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Phone No.:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setPhoneNo(e.target.value)}
                          value={PhoneNo}
                          placeholder="Phone No."
                        />
                      </div>

                      {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile Pic:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setProfilePic(e.target.files[0])}
                          placeholder="Profile Pic"
                          required
                        />
                      </div> */}

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile State:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setProfileState(e.target.value)}
                          value={ProfileState}
                          aria-label="Profile Status"
                        >
                          <option value="I">Incomplete</option>
                          <option value="C">Complete</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Education:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setEducation(e.target.value)}
                          value={Education}
                          placeholder="Education"
                        />
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Gender:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setGender(e.target.value)}
                          value={Gender}
                          placeholder="Gender"
                        >
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">yrs of Graduation:</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setyrsOfGraduation(e.target.value)}
                          value={yrsOfGraduation}
                          placeholder="yrs of Graduation"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">yrs of Exp:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setyrsOfExp(e.target.value)}
                          value={yrsOfExp}
                          placeholder="yrs of Exp"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">City:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setCity(e.target.value)}
                          value={City}
                          placeholder="City"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">LinkedIn URL:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setLinkedIn(e.target.value)}
                          value={LinkedIn}
                          placeholder="LinkedIn URL"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Industry:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setIndustry(e.target.value)}
                          value={Industry}
                          placeholder="Industry"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Designation:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setDesignation(e.target.value)}
                          value={Designation}
                          placeholder="Designation"
                        />
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Name of Clouserer:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setClousererName(e.target.value)}
                          value={ClousererName}
                          placeholder="Gender"
                        >
                          {clousers.map((closerer,i)=>(
                            <option key={i} value={closerer}>{closerer}</option>
                          ))}
                          
                        </select>
                      </div>

                      {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Name:</label>
                        <select
                          className="form-select"
                          // name="UserRole"
                          onChange={(e) => setCourseId(e.target.value)}
                          // aria-label="Default select example"
                          // value=""
                        >
                          <option value="" selected>
                            Select Course
                          </option>
                          {CourseList?.map((item, i) => (
                            <option
                              className="text-dark"
                              value={item.id}
                              key={i}
                            >
                              {item.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Batch Name:</label>
                        <select
                          className="form-select"
                          onChange={(e) => setBatchId(e.target.value)}
                          // value=""
                        >
                          <option value="" selected>
                            Select Batch
                          </option>
                          {BatchesList?.map((item, i) => (
                            <option
                              className="text-dark"
                              value={item?.id}
                              key={i}
                            >
                              {item?.title}
                            </option>
                          ))}
                        </select>
                      </div> */}

                      <div className="d-flex justify-content-end my-2">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-primary ms-2"
                          data-bs-dismiss={Modal ? "" : "modal"}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* filter modal */}

      <div
        className="modal fade"
        id="ClouserFilterModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Apply filters
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <h5>Filter By Clouser</h5>
                  <FormGroup>
                    {clousers.map((clouser, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleClouserFilter}
                            name={clouser}
                          />
                        }
                        label={clouser}
                        key={i}
                      />
                    ))}
                  </FormGroup>
                </div>
                <div className="col-6">
                  <h5>Filter By Course</h5>
                  <FormGroup>
                    {CourseList.map((Course, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleCourseFilter}
                            name={Course.title}
                          />
                        }
                        label={Course.title}
                        key={i}
                      />
                    ))}
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-12 my-4 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={createFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
