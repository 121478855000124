import React, { useState } from "react";
// material ui
import CircularProgress from "@mui/joy/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";

export default function ResetPass() {
  const [IsLoading, setIsLoading] = useState(false);
  const [Error, setError] = useState("");
  const [ShowPass, setShowPass] = useState({
    showPassword: true,
  });
  const [OldPass, setOldPass] = useState("");
  const [NewPass, setNewPass] = useState("");
  const [Username, setUsername] = useState("");
  const [newPassword, setnewPassword] = useState({
    showNew: true,
  });
  const [open, setOpen] = React.useState(true);

  const history = useHistory();
  const userGrp = JSON.parse(localStorage.getItem("user_group"));

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleReset = async (e) => {
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();

    formdata.append("username", Username);
    formdata.append("old_password", OldPass);
    formdata.append("new_password", NewPass);
    setError("");
    try {
      const { data } = await axios.post("/users/reset_password/v1_1", formdata);

      if (data.status == "0x0") {
        alert("Your Password Changed Successfully.");
        history.push("/");
      }

      if (data.status == "0x1004") {
        setError("Please Enter Correct Username.");
      }
    } catch (error) {
      if (error.response.data.status == "0x1") {
        setError("Please Enter Correct Old Password.");
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPass({ ...ShowPass, showPassword: !ShowPass.showPassword });
  };
  
  const handleClickShowNewPassword = () => {
    setnewPassword({ ...newPassword, showNew: !newPassword.showNew });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      {Error != "" && (
        <div className="col-md-4 offset-md-4 col-6 offset-3 mt-2">
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            {Error}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center flex-column">
        <form onSubmit={handleReset}>
          <h4 className="my-4 text-center welcome-text">Change Password</h4>
          <div className="col-md-4 offset-md-4 col-6 offset-3 my-3">
            <Input
              type="text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-4 offset-md-4 col-6 offset-3 my-3">
            <Input
              type={ShowPass.showPassword ? "password" : "text"}
              placeholder="Old Password"
              onChange={(e) => setOldPass(e.target.value)}
              className="form-control"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {ShowPass.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="col-md-4 offset-md-4 col-6 offset-3 my-3">
            <Input
              type={newPassword.showNew ? "password" : "text"}
              placeholder="New Password"
              onChange={(e) => setNewPass(e.target.value)}
              className="form-control"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {newPassword.showNew ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div className="col-md-4 offset-md-4 col-6 offset-3 my-3">
            <button className="btn sub-btn text-light">Submit</button>
          </div>
          <div className="col-6 offset-3 d-flex justify-content-center my-3">
            <Link to="/">Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
