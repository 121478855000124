import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../images/logo png.png";
import db from "../images/Dashboard.png";
import Student from "../images/Student.png";
import SupportIcon from "../images/Support.png";
import MyCourses from "../images/MyCourses.png"; 
import testpayment from "../images/testpayment.png"; 
import Coach from "../images/Coach.png";
import Batch from "../images/Batch.png";
import manageBatch from "../images/manageBatch.png";
import supportLight from "../images/supportLight.png";
import DashboardLight from "../images/DashboardLight.png";
import HomeIcon from "../images/HomeIcon.png";
import HomeClicked from "../images/HomeClicked.png";
import hackerRankClicked from "../images/hackerRankClicked.png";
import hackerRank from "../images/hackerrank.png";
import githubClicked from "../images/githubClicked.png";
import githubIcon from "../images/githubIcon.png";
import paymentIcon from "../images/paymentIcon.png";
import paymentClicked from "../images/paymentClicked.png";
import lmsIcon from "../images/lmsIcon.png";
import lmsClicked from "../images/lmsClicked.png";
import assIcon from "../images/assIcon.png";
import assClicked from "../images/assClicked.png";
import zoomClicked from "../images/zoomClicked.png";
import zoomIcon from "../images/zoomIcon.png";
import commIcon from "../images/commIcon.png";
import commClicked from "../images/commClicked.png";
import SalesClicked from "../images/SalesClicked.png";
import SupportIcon1 from "../images/SupportIcon1.png";
import SupportClicked from "../images/SupportClicked.png";
import BatchesIcon from "../images/BatchesIcon.png";
import BatchesClicked from "../images/BatchesClicked.png";
import StudentLight from "../images/StudentLight.png";
import MyCoursesLight from "../images/MyCoursesLight.png";
import CoachLight from "../images/CoachLight.png";
import manageBatchLight from "../images/manageBatchLight.png";
import courseDownArrow from "../images/courseDownArrow.png";

//css import
import "../style/adminPanel.css";

// material ui

import { makeStyles, useTheme } from "@material-ui/core/styles";

import MenuIcon from "@mui/icons-material/Menu";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AddBlog from "../Components/AddBlog";
import AnnounceBatch from "../Components/AnnounceBatch";
import FeedbackForm from "../Components/FeedbackForm";
import CreateBatch from "../Components/CreateBatch";
import StuOnboarding from "../Components/addStudent/StuOnboarding";
import EduOnboarding from "../Components/EduOnboarding";
import CertificateGen from "../Components/CertificateGen";
import AddCourse from "../Components/AddCourse";
import AddEmployee from "../Components/AddEmployee";
import SupportTicket from "../Components/SupportTicket";
import Support from "../Components/Support/SupportPanel";
import Payment from "../Components/Payment/Payment";
import Recordings from "../Components/recordings/Recordings";
import Assignment from "../Components/assignment/Assignment";
import ZoomLink from "../Components/zoomLink/ZoomLink";
import Community from "../Components/community/Community";
import AddLinksPanel from "../Components/addLinks/AddLinksPanel";
import AnnounceBatchPanel from "../Components/announceBatch/AnnounceBatchPanel";
import Dashboard from "../Components/dashboard/Dashboard";
import MyLms from "../Components/myLms/MyLms";
import CoursePanel from "../Components/Course/CoursePanel";
import CoachPanel from "../Components/coach/CoachPanel";

import axios from "axios";

// images
import d_icon_3 from "../images/Dashboard.png";
import AddStuWithPaymentDetails from "../Components/addStudent/AddStuWithPaymentDetails";
import StuList from "../Components/addStudent/StuList";
import AnnounceBatchForm from "../Components/announceBatch/AnnounceBatchForm";
import AddLinks from "../Components/announceBatch/AddLinks";
import BatchList from "../Components/announceBatch/BatchList";
import AddCoach from "../Components/coach/AddCoach";
import CoachList from "../Components/coach/CoachList";
import CreateCourse from "../Components/Course/CreateCourse";
import CourseList from "../Components/Course/CourseList";
import AddModule from "../Components/Course/AddModule";
import SupportTicketList from "../Components/Support/SupportTicketList";
import StuListForSupport from "../Components/Support/StuListForSupport";
import Topbar from "../Components/Topbar";
import StuInfo from "../Components/StuInfo";
import StuInfo2 from "../Components/StuInfo2";
import Leads from "../Components/contactUs/Leads";
import Report from "../Components/Support/Report";
import MockStuList from "../Components/Mock Interview/MockStuList";
import PaidStudent from "../Components/Support/PaidStudent";
import PlacementList from "../Components/Placement/PlacementList";
import CompanyOnboard from "../Components/Placement/CompanyOnboard";
import CompanyList from "../Components/Placement/CompanyList";
import Invoice from "../Components/Support/Invoice";
import InvoiceList from "../Components/Support/InvoiceList";
import AddPlacedStu from "../Components/placedStu/AddPlacedStu";
import PlacedStuList from "../Components/placedStu/PlacedStuList";
import HrPortal from "../Components/HrPortal/HrPortal";
// import SalesClousererReport from "../Components/addStudent/RecoveryReport";
import RecoveryReport from "../Components/clousererReport/RecoveryReport";
import PaidStuReport from "../Components/clousererReport/PaidStuReport";
import DropOffReport from "../Components/clousererReport/DropOffReport";
import Reference from "../Components/references/Reference";
import Referals from "../Components/Support/Referals";
import GitComp from "../Components/Payment/GitComp";
import HackerRank from "../Components/Payment/HackerRank";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function AdminPanel(props) {
  const [open, setOpen] = React.useState(true);
  const [height, width] = useWindowSize();
  const [comp, setcomp] = useState(0);
  const [SubData, setSubData] = useState([]);
  const [IndexNo, setIndexNo] = useState(0);
  const [CompIndex, setCompIndex] = useState("d");
  const [Isblur, setIsblur] = useState();
  const [courseTabs, setcourseTabs] = useState( [
    {
      img: Student,
      lightIcon: StudentLight,
      tabName: "Student",
      permissions: "G1Sales",
      subItems: [
        {
          img: BatchesIcon,
          lightIcon: BatchesClicked,
          tabName: "Student Onboarding",
          component: <AddStuWithPaymentDetails />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Student List",
          component: <StuList />,
        },
      ],
    },
    // {
    //   img: Student,
    //   lightIcon: StudentLight,
    //   tabName: "My Reports",
    //   permissions: "G1Sales",

    //   subItems: [
    //     {
    //       img: Student,
    //       lightIcon: StudentLight,
    //       tabName: "Pending Collection",
    //       component: <RecoveryReport />,
    //     },
    //     {
    //       img: Student,
    //       lightIcon: StudentLight,
    //       tabName: "No dues Left",
    //       component: <PaidStuReport />,
    //     },
    //     {
    //       img: Student,
    //       lightIcon: StudentLight,
    //       tabName: "Drop off",
    //       component: <DropOffReport />,
    //     },
    //   ],
    // },
    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "Manage Batch",
      permissions: "G1Support",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Create Batch",
          component: <AnnounceBatchForm />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Add Links",
          component: <AddLinks />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Batch List",
          component: <BatchList />,
        },
      ],
    },

    {
      img: SupportIcon1,
      lightIcon: SupportClicked,
      tabName: "Support",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Ticket Raised",
          permissions: "G1Support",
          component: <SupportTicketList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Enrolled Students",
          permissions: "G1Support",
          component: <StuListForSupport />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Invoice List",
          permissions: "G1Support",
          component: <InvoiceList />,
        },
       
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Receipt",
        //   permissions: "G1Support",
        //   component: <Invoice />,
        // },
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Mock",
        //   permissions: "G1Support",
        //   component: <MockStuList />,
        // },
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Placements",
        //   permissions: "G1Support",
        //   component: <Report />,
        // },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Paid Students",
          permissions: "G1Support",
          component: <PaidStudent />,
        },
      ],
    },

    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "Clouserer Report",
      permissions: "G1Sales",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Pending Collection",
          component: <RecoveryReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "No Dues Left",
          component: <PaidStuReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Drop off",
          component: <DropOffReport />,
        },
      ],
    },
    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "Clouserer Report",
      permissions: "G1Support",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Pending Collection",
          component: <RecoveryReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "No Dues Left",
          component: <PaidStuReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Drop off",
          component: <DropOffReport />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Mock",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <MockStuList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Mock",
      permissions: "G1Mock",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <MockStuList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Placements",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Onboard Company",
          permissions: "G1Support",
          component: <CompanyOnboard />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <PlacementList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Company's",
          permissions: "G1Support",
          component: <CompanyList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Placements",
      permissions: "G1Placement",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Onboard Company",
          permissions: "G1Support",
          component: <CompanyOnboard />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <PlacementList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Company's",
          permissions: "G1Support",
          component: <CompanyList />,
        },
      ],
    },

    {
      img: CoachLight,
      lightIcon: Coach,
      tabName: "Placed Students",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Add student",
          permissions: "G1Support",
          component: <AddPlacedStu />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students List",
          permissions: "G1Support",
          component: <PlacedStuList />,
        },
      ],
    },
    {
      img: CoachLight,
      lightIcon: Coach,
      tabName: "Coach",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Add Coach",
          permissions: "G1Support",
          component: <AddCoach />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Coach List",
          permissions: "G1Support",
          component: <CoachList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCourses,
      tabName: "Course",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Create Course",
          permissions: "G1Support",
          component: <CreateCourse />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Course List",
          permissions: "G1Support",
          component: <CourseList />,
        },
        {
          img: SupportIcon,
          lightIcon: supportLight,
          tabName: "Add Module",
          permissions: "G1Support",
          component: <AddModule />,
        },
      ],
    },
    // {
    //   img: MyCourses,
    //   lightIcon: MyCourses,
    //   tabName: "My Courses",
    //   permissions: "G1Student",
    //   subItems: [
    //     {
    //       img: MyCourses,
    //       lightIcon: MyCourses,
    //       tabName: "My LMS",
    //       permissions: "G1Student",
    //       component: <MyLms />,
    //     },
    //     {
    //       img: MyCourses,
    //       lightIcon: MyCourses,
    //       tabName: "Payment",
    //       permissions: "G1Student",
    //       component: <Payment />,
    //     },
    //     {
    //       img: MyCourses,
    //       lightIcon: MyCoursesLight,
    //       tabName: "Assignment",
    //       permissions: "G1Student",
    //       component: <Assignment />,
    //     },
    //     {
    //       img: MyCourses,
    //       lightIcon: MyCoursesLight,
    //       tabName: "Zoom Link",
    //       permissions: "G1Student",
    //       component: <ZoomLink />,
    //     },
    //     {
    //       img: MyCourses,
    //       lightIcon: MyCoursesLight,
    //       tabName: "Community",
    //       permissions: "G1Student",
    //       component: <Community />,
    //     },
    //     {
    //       img: d_icon_3,
    //       tabName: "Recordings",
    //       permissions: "",
    //       component: <Recordings />,
    //     },
    //   ],
    // },
    {
      img: lmsIcon,
      lightIcon: lmsClicked,
      tabName: "My LMS",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <MyLms />,
        },
      ],
    },
    {
      img: paymentIcon,
      lightIcon: paymentClicked,
      tabName: "Payment",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <Payment />,
        },
      ],
    },
    {
      img: assIcon,
      lightIcon: assClicked,
      tabName: "Assignment",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <Assignment />,
        },
      ],
    },
    {
      img: zoomIcon,
      lightIcon: zoomClicked,
      tabName: "Zoom Link",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <ZoomLink />,
        },
      ],
    },
    {
      img: commIcon,
      lightIcon: commClicked,
      tabName: "Community",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <Community />,
        },
      ],
    },
    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "References",
      permissions: "G1Student",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "",
          permissions: "G1Support",
          component: <Reference />,
        },
      ],
    },
    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "Referals",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "Students",
          permissions: "G1Student",
          component: <Referals />,
        },
      ],
    },
    {
      img: hackerRank,
      lightIcon:hackerRankClicked ,
      tabName: "HackerRank",
      permissions: "G1Student",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "",
          permissions: "G1Student",
          component: <HackerRank/>,
        },
      ],
    },
    {
      img:githubIcon ,
      lightIcon: githubClicked,
      tabName: "Github",
      permissions: "G1Student",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "",
          permissions: "G1Student",
          component: <GitComp />,
        },
      ],
    },
    {
      img: BatchesIcon,
      lightIcon: BatchesClicked,
      tabName: "Contact Us",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "Leads",
          permissions: "G1Student",
          component: <Leads />,
        },
      ],
    },
  ]
);

  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const userGrp = JSON.parse(localStorage.getItem("user_group"));

  const handleBlur = (xx) => {
    setIsblur(xx);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

// const [courseTabs, setcourseTabs] = useState([])

  var courseTabs1 = [
    {
      img: Student,
      lightIcon: StudentLight,
      tabName: "Student",
      permissions: "G1Sales",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Student Onboarding",
          component: <AddStuWithPaymentDetails />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Student List",
          component: <StuList />,
        },
      ],
    },
    {
      img: Student,
      lightIcon: StudentLight,
      tabName: "My Reports",
      permissions: "G1Sales",

      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Pending Collection",
          component: <RecoveryReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "No dues Left",
          component: <PaidStuReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Drop off",
          component: <DropOffReport />,
        },
      ],
    },
    {
      img: manageBatch,
      lightIcon: manageBatch,
      tabName: "Manage Batch",
      permissions: "G1Support",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Create Batch",
          component: <AnnounceBatchForm />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Add Links",
          component: <AddLinks />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Batch List",
          component: <BatchList />,
        },
      ],
    },
    {
      img: SupportIcon,
      lightIcon: SupportIcon,
      tabName: "Support",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Ticket Raised",
          permissions: "G1Support",
          component: <SupportTicketList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Enrolled Students",
          permissions: "G1Support",
          component: <StuListForSupport />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Invoice List",
          permissions: "G1Support",
          component: <InvoiceList />,
        },
       
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Receipt",
        //   permissions: "G1Support",
        //   component: <Invoice />,
        // },
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Mock",
        //   permissions: "G1Support",
        //   component: <MockStuList />,
        // },
        // {
        //   img: MyCourses,
        //   lightIcon: MyCoursesLight,
        //   tabName: "Placements",
        //   permissions: "G1Support",
        //   component: <Report />,
        // },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Paid Students",
          permissions: "G1Support",
          component: <PaidStudent />,
        },
      ],
    },
    {
      img: SupportIcon,
      lightIcon: SupportIcon,
      tabName: "Clouserer Report",
      permissions: "G1Support",
      subItems: [
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Pending Collection",
          component: <RecoveryReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "No Dues Left",
          component: <PaidStuReport />,
        },
        {
          img: Student,
          lightIcon: StudentLight,
          tabName: "Drop off",
          component: <DropOffReport />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Mock",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <MockStuList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Mock",
      permissions: "G1Mock",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <MockStuList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Placements",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Onboard Company",
          permissions: "G1Support",
          component: <CompanyOnboard />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <PlacementList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Company's",
          permissions: "G1Support",
          component: <CompanyList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCoursesLight,
      tabName: "Placements",
      permissions: "G1Placement",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Onboard Company",
          permissions: "G1Support",
          component: <CompanyOnboard />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students",
          permissions: "G1Support",
          component: <PlacementList />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Company's",
          permissions: "G1Support",
          component: <CompanyList />,
        },
      ],
    },

    {
      img: CoachLight,
      lightIcon: Coach,
      tabName: "Placed Students",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Add student",
          permissions: "G1Support",
          component: <AddPlacedStu />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Students List",
          permissions: "G1Support",
          component: <PlacedStuList />,
        },
      ],
    },
    {
      img: CoachLight,
      lightIcon: Coach,
      tabName: "Coach",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Add Coach",
          permissions: "G1Support",
          component: <AddCoach />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Coach List",
          permissions: "G1Support",
          component: <CoachList />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCourses,
      tabName: "Course",
      permissions: "G1Support",
      subItems: [
        {
          img: Coach,
          lightIcon: CoachLight,
          tabName: "Create Course",
          permissions: "G1Support",
          component: <CreateCourse />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Course List",
          permissions: "G1Support",
          component: <CourseList />,
        },
        {
          img: SupportIcon,
          lightIcon: supportLight,
          tabName: "Add Module",
          permissions: "G1Support",
          component: <AddModule />,
        },
      ],
    },
    {
      img: MyCourses,
      lightIcon: MyCourses,
      tabName: "My Courses",
      permissions: "G1Student",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "My LMS",
          permissions: "G1Student",
          component: <MyLms />,
        },
        {
          img: testpayment,
          lightIcon: testpayment,
          tabName: "Payment",
          permissions: "G1Student",
          component: <Payment />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Assignment",
          permissions: "G1Student",
          component: <Assignment />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Zoom Link",
          permissions: "G1Student",
          component: <ZoomLink />,
        },
        {
          img: MyCourses,
          lightIcon: MyCoursesLight,
          tabName: "Community",
          permissions: "G1Student",
          component: <Community />,
        },

        {
          img: d_icon_3,
          tabName: "References",
          permissions: "",
          component: <Recordings />,
        },
      ],
    },
  
    {
      img: MyCourses,
      lightIcon: MyCourses,
      tabName: "Contact Us",
      permissions: "G1Support",
      subItems: [
        {
          img: MyCourses,
          lightIcon: MyCourses,
          tabName: "Leads",
          permissions: "G1Student",
          component: <Leads />,
        },
      ],
    },
  ];

const  duplicateCourseTab=()=>{
  // Create a map to keep track of seen tabNames
const seenTabNames = new Map();

// Filter the courseTabs array to remove duplicates
const uniqueCourseTabs = courseTabs.filter(tab => {
    // Check if the tabName has been seen before
    if ((!seenTabNames.has(tab.tabName)&&tab.permissions!="G1Student")) {
        // If not, mark it as seen and keep it in the result
        seenTabNames.set(tab.tabName, true);
        return true;
    }
    return false;
});
setcourseTabs(uniqueCourseTabs)
}

useEffect(() => {
  if(userGrp.length>4){
    duplicateCourseTab()
  }
}, [userGrp.length>3])

  // const newCourseTab={
  //   G1Support:[
  //     {
  //       img: manageBatch,
  //       lightIcon: manageBatch,
  //       tabName: "Manage Batch",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Create Batch",
  //           component: <AnnounceBatchForm />,
  //         },
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Add Links",
  //           component: <AddLinks />,
  //         },
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Batch List",
  //           component: <BatchList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: SupportIcon,
  //       lightIcon: SupportIcon,
  //       tabName: "Support",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Ticket Raised",
  //           permissions: "G1Support",
  //           component: <SupportTicketList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Enrolled Students",
  //           permissions: "G1Support",
  //           component: <StuListForSupport />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Invoice List",
  //           permissions: "G1Support",
  //           component: <InvoiceList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Closurer Report",
  //           permissions: "G1Support",
  //           component: <Report />,
  //         },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Receipt",
  //         //   permissions: "G1Support",
  //         //   component: <Invoice />,
  //         // },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Mock",
  //         //   permissions: "G1Support",
  //         //   component: <MockStuList />,
  //         // },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Placements",
  //         //   permissions: "G1Support",
  //         //   component: <Report />,
  //         // },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Paid Students",
  //           permissions: "G1Support",
  //           component: <PaidStudent />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Mock",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <MockStuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Placements",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Onboard Company",
  //           permissions: "G1Support",
  //           component: <CompanyOnboard />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <PlacementList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Company's",
  //           permissions: "G1Support",
  //           component: <CompanyList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Placements",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Onboard Company",
  //           permissions: "G1Support",
  //           component: <CompanyOnboard />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <PlacementList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Company's",
  //           permissions: "G1Support",
  //           component: <CompanyList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: CoachLight,
  //       lightIcon: Coach,
  //       tabName: "Placed Students",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Add student",
  //           permissions: "G1Support",
  //           component: <AddPlacedStu />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students List",
  //           permissions: "G1Support",
  //           component: <PlacedStuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: CoachLight,
  //       lightIcon: Coach,
  //       tabName: "Coach",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Add Coach",
  //           permissions: "G1Support",
  //           component: <AddCoach />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Coach List",
  //           permissions: "G1Support",
  //           component: <CoachList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Course",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Create Course",
  //           permissions: "G1Support",
  //           component: <CreateCourse />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Course List",
  //           permissions: "G1Support",
  //           component: <CourseList />,
  //         },
  //         {
  //           img: SupportIcon,
  //           lightIcon: supportLight,
  //           tabName: "Add Module",
  //           permissions: "G1Support",
  //           component: <AddModule />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Hr Portal",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "Attendance",
  //           permissions: "G1Student",
  //           component: <HrPortal />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Contact Us",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "Leads",
  //           permissions: "G1Student",
  //           component: <Leads />,
  //         },
  //       ],
  //     },
  //   ],
  //   G1SuperAdmin:[
  //     {
  //       img: Student,
  //       lightIcon: StudentLight,
  //       tabName: "Student",
  //       permissions: "G1Sales",
  
  //       subItems: [
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Student Onboarding",
  //           component: <AddStuWithPaymentDetails />,
  //         },
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Student List",
  //           component: <StuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: manageBatch,
  //       lightIcon: manageBatch,
  //       tabName: "Manage Batch",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Create Batch",
  //           component: <AnnounceBatchForm />,
  //         },
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Add Links",
  //           component: <AddLinks />,
  //         },
  //         {
  //           img: Student,
  //           lightIcon: StudentLight,
  //           tabName: "Batch List",
  //           component: <BatchList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: SupportIcon,
  //       lightIcon: SupportIcon,
  //       tabName: "Support",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Ticket Raised",
  //           permissions: "G1Support",
  //           component: <SupportTicketList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Enrolled Students",
  //           permissions: "G1Support",
  //           component: <StuListForSupport />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Invoice List",
  //           permissions: "G1Support",
  //           component: <InvoiceList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Closurer Report",
  //           permissions: "G1Support",
  //           component: <Report />,
  //         },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Receipt",
  //         //   permissions: "G1Support",
  //         //   component: <Invoice />,
  //         // },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Mock",
  //         //   permissions: "G1Support",
  //         //   component: <MockStuList />,
  //         // },
  //         // {
  //         //   img: MyCourses,
  //         //   lightIcon: MyCoursesLight,
  //         //   tabName: "Placements",
  //         //   permissions: "G1Support",
  //         //   component: <Report />,
  //         // },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Paid Students",
  //           permissions: "G1Support",
  //           component: <PaidStudent />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Mock",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <MockStuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Mock",
  //       permissions: "G1Mock",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <MockStuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Placements",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Onboard Company",
  //           permissions: "G1Support",
  //           component: <CompanyOnboard />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <PlacementList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Company's",
  //           permissions: "G1Support",
  //           component: <CompanyList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCoursesLight,
  //       tabName: "Placements",
  //       permissions: "G1Placement",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Onboard Company",
  //           permissions: "G1Support",
  //           component: <CompanyOnboard />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students",
  //           permissions: "G1Support",
  //           component: <PlacementList />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Company's",
  //           permissions: "G1Support",
  //           component: <CompanyList />,
  //         },
  //       ],
  //     },
  
  //     {
  //       img: CoachLight,
  //       lightIcon: Coach,
  //       tabName: "Placed Students",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Add student",
  //           permissions: "G1Support",
  //           component: <AddPlacedStu />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Students List",
  //           permissions: "G1Support",
  //           component: <PlacedStuList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: CoachLight,
  //       lightIcon: Coach,
  //       tabName: "Coach",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Add Coach",
  //           permissions: "G1Support",
  //           component: <AddCoach />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Coach List",
  //           permissions: "G1Support",
  //           component: <CoachList />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Course",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: Coach,
  //           lightIcon: CoachLight,
  //           tabName: "Create Course",
  //           permissions: "G1Support",
  //           component: <CreateCourse />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Course List",
  //           permissions: "G1Support",
  //           component: <CourseList />,
  //         },
  //         {
  //           img: SupportIcon,
  //           lightIcon: supportLight,
  //           tabName: "Add Module",
  //           permissions: "G1Support",
  //           component: <AddModule />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "My Courses",
  //       permissions: "G1Student",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "My LMS",
  //           permissions: "G1Student",
  //           component: <MyLms />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "Payment",
  //           permissions: "G1Student",
  //           component: <Payment />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Assignment",
  //           permissions: "G1Student",
  //           component: <Assignment />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Zoom Link",
  //           permissions: "G1Student",
  //           component: <ZoomLink />,
  //         },
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCoursesLight,
  //           tabName: "Community",
  //           permissions: "G1Student",
  //           component: <Community />,
  //         },
  
  //         {
  //           img: d_icon_3,
  //           tabName: "Recordings",
  //           permissions: "",
  //           component: <Recordings />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Hr Portal",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "Attendance",
  //           permissions: "G1Student",
  //           component: <HrPortal />,
  //         },
  //       ],
  //     },
  //     {
  //       img: MyCourses,
  //       lightIcon: MyCourses,
  //       tabName: "Contact Us",
  //       permissions: "G1Support",
  //       subItems: [
  //         {
  //           img: MyCourses,
  //           lightIcon: MyCourses,
  //           tabName: "Leads",
  //           permissions: "G1Student",
  //           component: <Leads />,
  //         },
  //       ],
  //     },
  //   ],
  // }

  // const displayTab=()=>{
  //   if(userGrp.length > 0){
  //     var filteredKeyValuePairs = [];  
  //     for (const key of userGrp) {
  //       if (newCourseTab.hasOwnProperty(key)) {
  //         filteredKeyValuePairs.push(newCourseTab[key]);
  //       }
  //     }
  //   }
  //   // setcourseTabs(filteredKeyValuePairs)
  // }

  // useEffect(() => {
  //   // console.log(userGrp[4])
  //   // displayTab()
  // }, [userGrp])
  
  // const courseTabs1 = [
  //   {
  //     img: d_icon_3,
  //     lightIcon:"",
  //     tabName: "Dashboard",
  //     permissions: "",
  //     component: <Dashboard />,
  //   },
  //   {
  //     img: Student,
  //     lightIcon:StudentLight,
  //     tabName: "Student",
  //     permissions: "G1Sales",
  //     component: <StuOnboarding />,
  //   },
  //   {
  //     img: manageBatch,
  //     lightIcon:manageBatchLight,
  //     tabName: "Announce Batch",
  //     permissions: "G1Support",
  //     component: <AnnounceBatchPanel />,
  //   },
  //   {
  //     img: Coach,
  //     lightIcon:CoachLight,
  //     tabName: "Coach",
  //     permissions: "G1Support",
  //     // component: <EduOnboarding/>,
  //     component: <CoachPanel />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "Course",
  //     permissions: "G1Support",
  //     component: <CoursePanel />,
  //   },
  //   {
  //     img: SupportIcon,
  //     lightIcon:supportLight,
  //     tabName: "Support",
  //     permissions: "G1Support",
  //     component: <Support />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "My LMS",
  //     permissions: "G1Student",
  //     component: <MyLms />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "Assignment",
  //     permissions: "G1Student",
  //     component: <Assignment />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "Zoom Link",
  //     permissions: "G1Student",
  //     component: <ZoomLink />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "Community",
  //     permissions: "G1Student",
  //     component: <Community />,
  //   },
  //   {
  //     img: MyCourses,
  //     lightIcon:MyCoursesLight,
  //     tabName: "Support Ticket",
  //     permissions: "G1Student",
  //     component: <SupportTicket />,
  //   },
  //   {
  //     img: d_icon_3,
  //     tabName: "Payment",
  //     permissions: "",
  //     component: <Payment />,
  //   },
  //   {
  //     img: d_icon_3,
  //     tabName: "Recordings",
  //     permissions: "",
  //     component: <Recordings />,
  //   },

  //   // {
  //   //   img: "",
  //   //   tabName: "Employee",
  //   //   component: <AddEmployee/>,
  //   // },
  //   // {
  //   //   img: "",
  //   //   tabName: "Add Links",
  //   //   component: <AddLinksPanel/>,
  //   // },
  //   // {
  //   //   img: "",
  //   //   tabName: "Manage Batch",
  //   //   component: <CreateBatch/>,
  //   // },
  //   // {
  //   //   img: "",
  //   //   tabName: "CertificateGen",
  //   //   component: <CertificateGen/>,
  //   // },
  //   // {
  //   //   img: "",
  //   //   tabName: "Feedback",
  //   //   component: <FeedbackForm/>,
  //   // },
  //   // {
  //   //   img: "",
  //   //   tabName: "Blog",
  //   //   component: <AddBlog/>,
  //   // },
  // ];

  // useEffect(() => {

  //   if(userGrp.includes("G1Sales")){
  //     setcourseTabs([
  //       {
  //         img: "",
  //         tabName: "Student",
  //         permissions: "G1Sales",
  //         component: <StuOnboarding />,
  //       },
  //     ]
  //     )
  //   }

  //   if(userGrp.includes("G1Support")){
  //     setcourseTabs([
  //       {
  //         img: "",
  //         tabName: "Announce Batch",
  //         permissions: "G1Support",
  //         component: <AnnounceBatchPanel />,
  //       },
  //       {
  //         img: "",
  //         tabName: "Support",
  //         permissions: "G1Support",
  //         component: <Support />,
  //       },
  //     ]
  //     )
  //   }

  //   if(userGrp.includes("G1Student")){
  //     setcourseTabs([
  //       {
  //         img: "",
  //         tabName: "Assignment",
  //         permissions: "G1Student",
  //         component: <Assignment />,
  //       },
  //       {
  //         img: "",
  //         tabName: "Zoom Link",
  //         permissions: "G1Student",
  //         component: <ZoomLink />,
  //       },
  //       {
  //         img: "",
  //         tabName: "Community",
  //         permissions: "G1Student",
  //         component: <Community />,
  //       },
  //       {
  //         img: "",
  //         tabName: "Support Ticket",
  //         permissions: "",
  //         component: <SupportTicket />,
  //       },
  //       {
  //         img: "",
  //         tabName: "Payment",
  //         permissions: "",
  //         component: <Payment />,
  //       },
  //     ]
  //     )
  //   }
  // }, [])

  // window size

  
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      // cleanup function
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  }

  const handleTabs = (index) => {
    setcomp(index);
    if (width < 768) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (width < 768) {
      setOpen(false);
    }
  }, []);

  const handleMobileDrawerClose = (i) => {
    setCompIndex(i);
    width < 768 && setOpen(false);
  };

  ////////////////////

  const toggleSubMenu = (subData, indexNo) => {
    setSubData(subData);
    setIndexNo(indexNo);
    setCompIndex(0);
    setcomp(indexNo)
  };

  const handelDashboard = () => {
    setCompIndex("d");
    setIndexNo("");
    setSubData([]);
    setcomp("d")
  };

  return (
    <div className={Isblur ? "bg-admin isBlur" : "bg-admin"}>
      <div className="pt-2 d-flex justify-content-between align-items-center menuBtn">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className="mb-4 ms-2 "
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Topbar />
      </div>

      {/* /////////// StuInfo /////////// */}

      <StuInfo viewStuInfo={handleBlur} />

      <div className="container ">
        {/* <div> */}
        <div className="row main-wrapper">
          <div className="col-4 myDrower">
            <Drawer
              variant="persistent"
              anchor="left"
              open={open}
              sx={{ background: "rgba(228, 246, 255, 100%)" }}
              // className="myDrower"
            >
              <div className="adminMenuIcon ">
                <IconButton onClick={handleDrawerClose}>
                  <span className="text-dark fs-6">Back</span>
                </IconButton>
              </div>
              <Divider />
              <div className="col-2 admin-sidebar-col text-light pb-5 sidebarItems">
                <div className="adminDashboardImg sidebarItems w-100 mb-5">
                  {/* <Link to="/"> */}
                  <img
                    src={logo}
                    alt="footer_logo"
                    width="120px"
                    className="sidebarItems"
                  />
                  {/* </Link> */}
                </div>
                <div className=" sidebarItems tab-wrapper ms-0 mb-5 mt-0">

                  {/* <p
                  className="adminSidebar-item-wrapper"
                  // onClick={() => handleTabs(0)}
                >
                  <span
                    className={
                      comp == 0
                        ? "admin-sidebar-item text-primary sidebarItems"
                        : "admin-sidebar-item text-dark sidebarItems"
                    }
                  >
                    <img src={comp == 0?db:DashboardLight} alt="d_icon_3" width="18px" className="sidebarItems"/><span className="sidebarItems ms-2">Dashboard</span>
                  </span>
                </p> */}

                  <div className="sidebar sidebarItems">
                    <p
                      onClick={handelDashboard}
                      className={
                       ( (userGrp.includes("G1Mock") && userGrp.length == 1)||(userGrp.includes("G1Placement") && userGrp.length == 1))
                          ? "dbPointer d-none"
                          : "dbPointer px-4"
                      }
                    >
                      <img
                        // src={d_icon_3}
                        // src={HomeIcon}
                        src={comp=="d" ? HomeClicked:HomeIcon}
                        className={comp=="d"?"sidebarItems me-2 ActiveTabIcon":"sidebarItems me-3 ms-2 tabIcon"}
                      />
                      Dashboard
                    </p>
                    <ul>
                      {
                       courseTabs?.map(
                        (item, index) =>userGrp.includes(item.permissions) && (
                            <li key={index} className="mainTab px-4">
                              <p
                                onClick={() =>
                                  toggleSubMenu(item?.subItems, index)
                                }
                                className= {comp==index?"tabText tabTextPara mt-3":"tabTextPara mt-3"}
                              >
                                <img
                                  src={
                                    comp == index ? item?.lightIcon:item?.img 
                                  }
                                  className={comp==index?"sidebarItems me-2 ActiveTabIcon":"sidebarItems me-3 ms-2 tabIcon"}
                                />

                                <span className={comp==index&&"tabText"}>{item?.tabName}</span>
                                {
                                item?.permissions!="G1Student"
                                && <img
                                  src={courseDownArrow}
                                  alt="dWnar"
                                  className={
                                    SubData.length != 0 && index === IndexNo
                                      ? "ms-1"
                                      : "ms-1 downArrow"
                                  }
                                />
                              }
                              </p>
                              {item?.subItems.length != 0 && index == IndexNo && (
                              <ul className="ms-5">
                                  {SubData.map((subTab, i) => (
                                  subTab?.tabName!=""
                                    &&<li
                                      key={i}
                                      className={
                                        CompIndex == i
                                          ? "ms-3 text-info "
                                          : "ms-3 "
                                      }
                                      onClick={() => handleMobileDrawerClose(i)}
                                    >
                                      {subTab?.tabName}
                                    </li>
                                  
                                  ))}
                              </ul>
                              )}
                      
                            </li>
                          )
                      )}
                    </ul>
                  </div>

                  {/* { courseTabs?.map((item, index) => (
                  <p
                    onClick={() => handleTabs(index)}
                    key={index}
                    className="adminSidebar-item-wrapper"
                  >
                    {
                      <span
                        className={
                          userGrp?.includes(item?.permissions)
                            ? "admin-sidebar-item text-dark"
                            : "admin-sidebar-item text-dark d-none"
                        }
                      >
                        {userGrp?.includes(item?.permissions) && <span className={comp==index?"text-primary sidebarItems":"text-dark sidebarItems"}><img src={comp==index?item?.img:item?.lightIcon} className="sidebarItems" width="18px"/> {item?.tabName}</span> }
                      </span>
                    }
                  </p>
                ))} */}
                </div>
                <div className="admin-footer-terms d-flex flex-column">
                  {/* <span>
                  <Link to="/">Terms,</Link>
                    <Link to="/">Privacy Policy</Link>
                </span> */}
                  <span className="text-dark sidebarItems">
                    &copy;copyright:Coachx.Live Rights Reserved
                  </span>
                </div>
              </div>
            </Drawer>
          </div>

          <div className="col-8 p-0 md-column main-wrapper ">
            <main className="adminDashboard-wrapper mt-1">
              {CompIndex == "d" && <Dashboard />}
              <div>
                {SubData?.map(
                  (tabComp, i) => i === CompIndex && tabComp.component
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
