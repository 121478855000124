import axios from 'axios'
import React, { useState } from 'react'
// material ui
import Snackbar from "@mui/material/Snackbar";

export default function AddPlacedStu() {
    
const [StuName, setStuName] = useState("")
const [Desc, setDesc] = useState("")
const [ProfilePic, setProfilePic] = useState("")
const [CompanyLogo, setCompanyLogo] = useState("")
const [Designation, setDesignation] = useState("")
const [Review, setReview] = useState("")
const [Rating, setRating] = useState(0)
const [companyName, setCompanyName] = useState("")

const [open, setOpen] = React.useState(false);
const [submitting, setSubmitting]= useState(false)


    const reset = ()=>{
        setStuName("")
        setDesc("")
        setDesignation("")
        setProfilePic("")
        setCompanyLogo("")
        setReview("")
        setRating("")
    }

   const handleAddStu=async(e)=>{
    setSubmitting(true)
    e.preventDefault()
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();
    formdata.append("stud_name", StuName);
    formdata.append("description", Desc);
    formdata.append("profile_pic", ProfilePic);
    // formdata.append("company_logo", CompanyLogo);
    formdata.append("designation", Designation);
    formdata.append("review", Review);
    formdata.append("rating", Rating);
    formdata.append("company_name", companyName);
    const {data}=await axios.post("/placement/placed_students/",formdata)

    if(data.status=="0x0"){
        reset()
        setSubmitting(false)
        setOpen(true);
        setTimeout(() => setOpen(false), 5000);
    }
   }

  return (
    <div>
        {
        <>
        <Snackbar
        open={open}
        message="Student Added successfully...!!!"
        className="snackbar"
      />
        <Snackbar
        open={submitting}
        message="Please Wait..."
        className="snackbar"
      />
      </>
      }
        <h4 className="ms-5 ps-5">Add Placed Student</h4>
      <div className="row">
        <div className="col-10 offset-1 mb-4">
          <form onSubmit={handleAddStu}>
            <div className="row py-4">
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Student Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setStuName(e.target.value)}
                  value={StuName}
                  placeholder="Enter Student Name"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Description:</label>
                <textarea name="" id="" cols="30" rows="1"
                  className="form-control"
                  placeholder='Enter Students Description'
                  onChange={(e) => setDesc(e.target.value)}
                  value={Desc}
                  ></textarea>
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Pofile Pic:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setProfilePic(e.target.files[0])}
                //   value={CompanyLogo}
                  placeholder="Choose Pofile Pic"
                  required
                />
              </div>
              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Company Logo:</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => setCompanyLogo(e.target.files[0])}
                  // value={CompanyLogo}
                  placeholder="Choose Company Logo"
                  
                />
              </div> */}

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Company Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  placeholder="Company Name"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Designation:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setDesignation(e.target.value)}
                  value={Designation}
                  placeholder="Designation"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Review</label>
                <textarea name="" id="" cols="30" rows="1"
                  className="form-control"
                  onChange={(e) => setReview(e.target.value)}
                  value={Review}
                  placeholder="Review"
                  maxLength={300} 
                  required
                ></textarea>
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Rating</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setRating(e.target.value)}
                  value={Rating}
                  placeholder="Enter Your Rating"
                  required
                />
              </div>

              <div className="d-flex justify-content-end my-2">
                <button className="btn btn-primary ms-2">Add Student</button>
              </div>
              
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}
