import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function CopyToClipboardButton({data}) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Reset the copied state after 2 seconds
  };

  return (
    <div>
      
      <CopyToClipboard text={data}>
        <button onClick={handleCopy} className={copied?'btn btn-success btn-sm':'btn btn-info btn-sm'}>
          {copied ? "Copied!": <> Copy Email Id's <i className="bi bi-clipboard"></i></>
          }
        </button>
      </CopyToClipboard>
    </div>
  );
}

export default CopyToClipboardButton;
