import AddBlog from "./Components/AddBlog";
import AnnounceBatch from "./Components/AnnounceBatch";
import CreateBatch from "./Components/CreateBatch";
import FeedbackForm from "./Components/FeedbackForm";
import SupportTicket from "./Components/SupportTicket";
import AdminPanel from "./pages/AdminPanel";
import LoginPage from "./pages/LoginPage";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CourseDetails from "./Components/CourseDetails";
import axios from "axios";
import EditBatch from "./Components/announceBatch/EditBatch";
import ResetPass from "./pages/ResetPass";

function App() {

  //  ================= base url setup ================
  
  if(process.env.REACT_APP_ENV_key =="prod")
  {
    axios.defaults.baseURL = "https://pro.coachx.live/";
  }
  if(process.env.REACT_APP_ENV_key =="stage")
  {
    axios.defaults.baseURL = "https://apistage.coachx.live/";
  }
  
  if(process.env.REACT_APP_ENV_key =="dev2")
  {
    axios.defaults.baseURL = "https://dev2.coachx.live";
  }
  if(process.env.REACT_APP_ENV_key =="local")
  {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
  }
  if(process.env.REACT_APP_ENV_key =="dev0")
  {
    axios.defaults.baseURL = "https://dev0.coachx.live";
  }
  
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={LoginPage} />
          <Route exact path="/AdminPanel" component={AdminPanel} />
          <Route exact path="/CourseDetails" component={CourseDetails} />
          <Route exact path="/ResetPass" component={ResetPass} />
        </Switch>
      </BrowserRouter>
      
      {/* <EditBatch/> */}
      {/* <AddBlog/> */}
      {/* <AnnounceBatch/> */}
      {/* <TicketStatus/> */}
      {/* <LoginPage/> */}
      {/* <AdminPanel/> */}
      {/* <CreateBatch/> */}
      {/* <FeedbackForm/> */}
      
    </div>
  );
}

export default App;
