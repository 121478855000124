import axios from "axios";
import React, { useEffect, useState } from "react";
// material ui
import Snackbar from "@mui/material/Snackbar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AddStuWithPaymentDetails() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Username, setUsername] = useState("");
  const [ProfileState, setProfileState] = useState("I");
  const [CourseName, setCourseName] = useState("");
  const [RegAmmt, setRegAmmt] = useState();
  const [Education, setEducation] = useState("");
  const [Gender, setGender] = useState("");
  const [yrsOfGraduation, setyrsOfGraduation] = useState("");
  const [yrsOfExp, setyrsOfExp] = useState(0);
  const [StuStatus, setStuStatus] = useState("");
  const [TrainerName, setTrainerName] = useState("");
  const [PitchedAmount, setPitchedAmount] = useState("");
  const [CourseFee, setCourseFee] = useState("");
  const [NoOfInstallment, setNoOfInstallment] = useState("");
  const [InstallmentDetail, setInstallmentDetail] = useState("");
  const [PaymentDate, setPaymentDate] = useState("");
  const [ClousererName, setClousererName] = useState("");

  const [PhoneNo, setPhoneNo] = useState("");
  const [AllStuList, setAllStuList] = useState([]);
  const [deleteId, setdeleteId] = useState("");
  const [EditId, setEditId] = useState("");
  const [City, setCity] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [Entry, setEntry] = useState(`${yyyy}-${mm}-${dd}`);
  const [Industry, setIndustry] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Discount, setDiscount] = useState(0);
  const [CourseList, setCourseList] = useState([]);
  const [UserData, setUserData] = useState();
  const [CourseId, setCourseId] = useState("");
  const [BatchesList, setBatchesList] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [ListUpdate, setListUpdate] = useState(true);
  const [Modal, setModal] = useState();
  const [ProfilePic, setProfilePic] = useState("");
  const [installment1, setInstallment1] = useState();
  const [Installment1Status, setInstallment1Status] = useState();

  const [installment2, setInstallment2] = useState();
  const [Installment2Status, setInstallment2Status] = useState();

  const [Installment3Status, setInstallment3Status] = useState();
  const [installment3, setInstallment3] = useState();

  const [PaymentStatus, setPaymentStatus] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  // const [CourseId, setCourseId] = useState("")
  const [CourseTitle, setCourseTitle] = useState("");
  const [InstallMentStructure, setInstallMentStructure] = useState("");
  const [ModuleName, setModuleName] = useState("");
  const [AllModules, setAllModules] = useState([]);
  const [modules, setmodules] = useState({});
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [regType, setregType] = useState("");
  const [FinalFee, setFinalFee] = useState();

  useEffect(() => {
    setFinalFee(PitchedAmount - Discount);
  }, [PitchedAmount, Discount]);

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleGetAllStu = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/users/student/list/");
    console.log(data?.data?.student_list?.results);
    setAllStuList(data?.data?.student_list?.results);
  };

  const resetfields = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setUsername("");
    setProfileState("");
    setGender("");
    setPhoneNo("");
    setEducation("");
    setyrsOfGraduation("");
    setyrsOfExp(0);
    setCity("");
    setLinkedIn("");
    setIndustry("");
    setDesignation("");
    setClousererName("");
    setPaymentDate("");
    setRegAmmt("");
    setPitchedAmount("");
    setDiscount("");
    setFinalFee("");
    setInstallment1("");
    setInstallment2("");
    setInstallment3("");
    setInstallMentStructure("");
  };

  const handleAddStu = async (e) => {
    e.preventDefault();
    // TODO: remove this handleRefreshToken
    setSubmitting(true);
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const moduleId = [];

    Object.entries(modules).map((key, value) => {
      if (key[1]) moduleId.push(key[0]);
    });

    var formdata = new FormData();
    formdata.append("first_name", FirstName);
    formdata.append("last_name", LastName);
    formdata.append("email", Email);
    formdata.append("username", Email);
    formdata.append("profile_state", "Incomplete");
    formdata.append("gender", "NA");
    formdata.append("phone_numbers", PhoneNo);
    formdata.append("education", "NA");
    formdata.append("year_of_graduation", "2023-2-24");
    formdata.append("years_of_exp", yrsOfExp);
    formdata.append("current_city", City);
    formdata.append("linkedin_url", "NA");
    formdata.append("entry", Entry);
    formdata.append("industry", "NA");
    formdata.append("designation", "NA");
    formdata.append("counsellor", ClousererName);
    // payment details
    formdata.append("payment_date", PaymentDate);
    formdata.append("registration_amount", RegAmmt);
    formdata.append("final_amount", PitchedAmount - Discount);
    formdata.append("installment1", installment1);
    formdata.append("installment1_status", Installment1Status);
    formdata.append("installment2", installment2);
    formdata.append("installment2_status", Installment2Status);
    formdata.append("installment3", installment3);
    formdata.append("installment3_status", Installment3Status);
    formdata.append("payment_type", PaymentType);
    formdata.append("payment_status", PaymentStatus);
    formdata.append("student_status", "A");
    formdata.append("profilePic", "");
    formdata.append("course_info", CourseId);
    formdata.append("installment_structure", "NA");
    formdata.append("modules", JSON.stringify(moduleId));
    formdata.append("pitched_amount", PitchedAmount);
    formdata.append("discount", Discount);
    formdata.append("receipt_number", 0);
    formdata.append("description", "0xxxxx");
    formdata.append("registration_type", regType);

    // const { data } = await axios.post(
    //   "/users/student/create_payment/",
    //   formdata
    // );

    const { data } = await axios.post("/users/student/create_payment/v1_2", formdata);

    if (data.status != "0x0") {
      setSubmitting(false);
      alert("Somthing Went Wrong Please Try Once Again.");
    }

    if (data.status == "0x0") {
      setModal(true);
      setSubmitting(false);
      handleGetAllStu();
      setOpen(true);
      setTimeout(() => setOpen(false), 5000);
      resetfields();
    }
  };

  /// Get Stu Profile

  const getStuProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(`/users/student/profile/?id=${EditId}`);
    setUserData(data?.data?.userData);
    setFirstName(data?.data?.userData.first_name);
    setLastName(data?.data?.userData.last_name);
    setEmail(data?.data?.userData.email);
    setUsername(data?.data?.userData.username);
    setProfileState(data?.data?.userData.profile_state);
    setGender(data?.data?.userData.gender);
    setPhoneNo(data?.data?.userData.phone_numbers);
    setEducation(data?.data?.userData.education);
    setyrsOfGraduation(data?.data?.userData.year_of_graduation);
    setyrsOfExp(data?.data?.userData.years_of_exp);
    setCity(data?.data?.userData.current_city);
    setLinkedIn(data?.data?.userData.linkedin_url);
    // setEmail(data?.data?.userData.email)
    setIndustry(data?.data?.userData.industry);
    setDesignation(data?.data?.userData.designation);
    setClousererName(data?.data?.userData.counsellor);
    // setEmail(data?.data?.userData.payment_date)
    setPaymentDate(data?.data?.userData.payment_date);
    setRegAmmt(data?.data?.userData.registration_amount);
    setPitchedAmount(data?.data?.userData.pitched_amount);
    setDiscount(data?.data?.userData.discount);
    setFinalFee(data?.data?.userData.final_amount);
  };

  const handleGetAllCourse = async () => {
    handleRefreshToken();

    const token = JSON.parse(localStorage.getItem("access"));

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.get("/course/course/");

    if (data.status == "0x0") {
      // setCourseList(data.data.course_list.results);
      setCourseList(data.data.courseDetails);
      // handleGetAllModules()
    }
  };

  const handleGetAllModules = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/course/module/course?course=${CourseTitle}`);
    setAllModules(data.data.batchData);
  };

  const handleModuleChange = (event) => {
    const test = {
      ...modules,
      [event.target.name]: event.target.checked,
    };
    setmodules(test);
  };

  const handleOptionChange = (event, value) => {
    // const { value } = event.target;
    // const selectedCourse = JSON.parse(value);
    // console.log(selectedCourse);
    setCourseTitle(value.title);
    setCourseId(value.id);
  };

  useEffect(() => {
    if (CourseId && CourseTitle != "") {
      handleGetAllModules();
    }
  }, [CourseId, CourseTitle]);

  useEffect(() => {
    handleGetAllCourse();
  }, []);

  useEffect(() => {
    getStuProfile();
  }, [EditId]);

  return (
    <div className="">
      {
        <>
          <Snackbar open={open} message="Student Added successfully...!!!" className="snackbar" />
          <Snackbar open={submitting} message="Please Wait..." className="snackbar" />
        </>
      }
      <h4 className="ms-5 ps-5">Student Onboarding</h4>
      <div className="row">
        <div className="col-10 offset-1 mb-4">
          <form onSubmit={handleAddStu}>
            <div className="row py-4">
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">First Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={FirstName}
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Last Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLastName(e.target.value)}
                  value={LastName}
                  placeholder="Last Name"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Email:</label>
                <input
                  type="Email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  value={Email}
                  placeholder="Email"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Phone No.:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setPhoneNo(e.target.value)}
                  value={PhoneNo}
                  placeholder="Phone No."
                  required
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Registration Type.:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setregType(e.target.value)}
                  value={regType}
                  placeholder="Gender"
                  required
                >
                  <option value="" selected>
                    Select Registration Type
                  </option>
                  <option value="L">Live Learning</option>
                  <option value="R">Recordings Only</option>
                  <option value="J">Job Assistance Only</option>
                </select>
              </div>

              {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile Pic:</label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => setProfilePic(e.target.files[0])}
                          placeholder="Profile Pic"
                          required
                        />
                      </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Profile State:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setProfileState(e.target.value)}
                  value={ProfileState}
                  aria-label="Profile Status"
                  required
                >
                  <option value="" selected >Select profile Status</option>
                  <option value="I">Incomplete</option>
                  <option value="C">Complete</option>
                </select>
              </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Education:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setEducation(e.target.value)}
                  value={Education}
                  placeholder="Education"
                  required
                />
              </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Gender:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setGender(e.target.value)}
                  value={Gender}
                  placeholder="Gender"
                  required
                >
                  <option value="" selected>Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Year of Graduation:</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setyrsOfGraduation(e.target.value)}
                  value={yrsOfGraduation}
                  placeholder="Years of Graduation"
                  required
                />
              </div> */}
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Years of Exp:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setyrsOfExp(e.target.value)}
                  value={yrsOfExp}
                  placeholder="Years of Exp"
                  required
                />
              </div>
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">City:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCity(e.target.value)}
                  value={City}
                  placeholder="City"
                  required
                />
              </div>

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">LinkedIn URL:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLinkedIn(e.target.value)}
                  value={LinkedIn}
                  placeholder="LinkedIn URL"
                  required
                />
              </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Industry:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setIndustry(e.target.value)}
                  value={Industry}
                  placeholder="Industry"
                  required
                />
              </div> */}

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Designation:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setDesignation(e.target.value)}
                  value={Designation}
                  placeholder="Designation"
                  required
                />
              </div> */}

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Name of Clouserer:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setClousererName(e.target.value)}
                  value={ClousererName}
                  placeholder="Gender"
                  required
                >
                  <option value="" selected>
                    Select Clouserer
                  </option>
                  <option value="Gaurav">Gaurav</option>
                  <option value="Shubhankar">Shubhankar</option>
                  <option value="Tanya">Tanya</option>
                  <option value="Prem">Prem</option>
                  <option value="Shreyas">Shreyas</option>
                  <option value="Rahul">Rahul</option>
                  <option value="Himanshu">Himanshu</option>
                  <option value="Renuka">Renuka</option>
                  <option value="Sourav">Sourav</option>
                  <option value="Jayesh">Jayesh</option>
                  <option value="Trapti">Trapti</option>
                  <option value="Parul">Parul</option>
                  <option value="Aman">Aman</option>
                  <option value="Vishakha">Vishakha</option>
                  <option value="Dinisha">Dinisha</option>
                  <option value="Rahul D">Rahul D</option>
                  <option value="Rituraj">Rituraj</option>
                  <option value="Pratik">Pratik</option>
                  <option value="Pragati">Pragati</option>
                  <option value="Rishab">Rishab</option>
                  <option value="Smita">Smita</option>
                  <option value="Sachin">Sachin</option>
                  <option value="Vedant">Vedant</option>
                  <option value="Anjali">Anjali</option>
                </select>
              </div>

              {/* PAYMENT DETAILS  */}

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Payment Date:</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setPaymentDate(e.target.value)}
                  value={PaymentDate}
                  placeholder="Payment Date"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Registration Date:</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setEntry(e.target.value)}
                  value={Entry}
                  placeholder="Registration Date"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Registration Amount:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setRegAmmt(e.target.value)}
                  value={RegAmmt}
                  placeholder="Registration Amount"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Pitched Amount:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setPitchedAmount(e.target.value)}
                  value={PitchedAmount}
                  placeholder="Pitched Amount"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Final Amount:</label>
                <input
                  type="number"
                  className="form-control"
                  // onChange={(e) => setFinalFee(e.target.value)}
                  value={FinalFee}
                  placeholder="Final Amount"
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Discount Amount:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setDiscount(e.target.value)}
                  value={Discount}
                  placeholder="Discount Amount"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2 d-flex">
                <div className="col-6 me-1">
                  <label htmlFor="">Installment1:</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setInstallment1(e.target.value)}
                    value={installment1}
                    placeholder="Installment1"
                    required
                  />
                </div>
                <div className="col-6  ">
                  <label htmlFor="">Installment1 Status:</label>
                  <select
                    className="form-select"
                    name="UserRole"
                    onChange={(e) => setInstallment1Status(e.target.value)}
                    value={Installment1Status}
                    aria-label="Profile Status"
                  >
                    <option selected>Select installment status</option>
                    <option value="P">Paid</option>
                    <option value="U">Unpaid</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-sm-6 my-2 d-flex">
                <div className="col-6 me-1">
                  <label htmlFor="">Installment2:</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setInstallment2(e.target.value)}
                    value={installment2}
                    placeholder="Installment2"
                    required
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="">Installment2 Status:</label>
                  <select
                    className="form-select"
                    name="UserRole"
                    onChange={(e) => setInstallment2Status(e.target.value)}
                    value={Installment2Status}
                    aria-label="Profile Status"
                  >
                    <option selected>Select installment status</option>
                    <option value="P">Paid</option>
                    <option value="U">Unpaid</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-sm-6 my-2 d-flex">
                <div className="col-6 me-1">
                  <label htmlFor="">Installment3:</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setInstallment3(e.target.value)}
                    value={installment3}
                    placeholder="Installment3"
                    required
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="">Installment3 Status:</label>
                  <select
                    className="form-select"
                    name="UserRole"
                    onChange={(e) => setInstallment3Status(e.target.value)}
                    value={Installment3Status}
                    aria-label="Profile Status"
                  >
                    <option selected>Select installment status</option>
                    <option value="P">Paid</option>
                    <option value="U">Unpaid</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Payment Type:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setPaymentType(e.target.value)}
                  value={PaymentType}
                  aria-label="Profile Status"
                  required
                >
                  <option value="" selected>
                    Select payment Type
                  </option>
                  <option value="I">Installment</option>
                  <option value="F">Full Payment</option>
                  <option value="R">Registration</option>
                </select>
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Payment Status:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setPaymentStatus(e.target.value)}
                  value={PaymentStatus}
                  aria-label="Payment Status"
                  required
                >
                  <option value="" selected>
                    Select Payment Status
                  </option>
                  <option value="C">Complete</option>
                  <option value="I">Incomplete</option>
                  <option value="P">Partial Payment</option>
                  {/* <option value="D">Payment Due</option> */}
                </select>
              </div>

              {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          // onChange={(e) => setCourseIdForModule(e.target.value)}
                          onChange={handleOptionChange}
                          // value={Category}
                          aria-label="Default select example"
                          >
                          <option value="" selected>Select Course</option>
                        {
                          CourseList?.map((course,i)=>(
                            <option value={JSON.stringify({ id: course.id, title: course.title })}
                            >{course.title}</option>
                          ))
                        }
                        </select>
                      </div> */}
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor=""></label>
                <Autocomplete
                  id=""
                  sx={{
                    width: "100%",
                    height: 40,
                  }}
                  onChange={handleOptionChange}
                  options={CourseList}
                  // autoHighlight
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.title}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      label="Select Course"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          border: "none",
                          height: "40px",
                          padding: "0",
                        },
                      }}
                    />
                  )}
                />
              </div>

              {/* <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Remark:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>setInstallMentStructure(e.target.value)}
                  value={InstallMentStructure}
                  placeholder="Remark"
                  required
                />
              </div> */}
              <div className="col-md-6 col-12 my-2">
                <div
                  className="btn btn-light w-100 border"
                  data-bs-target="#getModules"
                  data-bs-toggle="modal"
                  // onClick={getAllModules}
                  required
                >
                  Select modules
                </div>
              </div>

              {/* <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course category:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setModuleName(e.target.value)}
                          // value={Category}
                          aria-label="Default select example"
                          >
                          <option>Select Course category</option>
                        {
                          CourseList?.map((course,i)=>(
                            <option value={course.id}>{course.title}</option>
                          ))
                        }
                        </select>
                      </div> */}

              <div className="d-flex justify-content-end my-2">
                <button className="btn btn-primary ms-2">Add Student</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/*=========== batch modules  ========== */}

      <div
        className="modal fade"
        id="getModules"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Select Modules
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <FormGroup>
                {AllModules.length > 0 ? (
                  <>
                    {AllModules?.map((module, i) => (
                      <FormControlLabel
                        control={<Checkbox onChange={handleModuleChange} name={module.id} />}
                        label={module.title}
                        key={i}
                      />
                    ))}
                  </>
                ) : (
                  <h6>Please Select Course.</h6>
                )}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
