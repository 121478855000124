import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useState } from 'react';



export const RefresToken = createAsyncThunk('/RefresToken', async (name) => {
    const [refreshToken, setrefreshToken] = useState(
        JSON.parse(localStorage.getItem("refresh"))
      );
      const [userName, setuserName] = useState(localStorage.getItem("username")?JSON.parse(localStorage.getItem("username")):name)

    let deviceUDID = new Date();
        if (!localStorage.getItem("DEVICE-UDID")) {
          localStorage.setItem("DEVICE-UDID", deviceUDID);
        }

        deviceUDID = localStorage.getItem("DEVICE-UDID");
        axios.defaults.headers.common = {
          "DEVICE-UDID": deviceUDID,
        };

    try {
    var formdata = new FormData();
    formdata.append("username", userName);
    formdata.append("refresh", refreshToken);
        const response = await axios.post(
            "/users/token/refresh/",
            formdata
          );
          
        return response.data; 

    } catch (error) {
        throw new Error(error.message)
    }
});

const RefresTokenSlice = createSlice({
  name: 'RefresToken',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RefresToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RefresToken.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        console.log(action.payload);
      })
      .addCase(RefresToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default RefresTokenSlice.reducer;