import React from 'react'
// import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,Legend  } 
// from 'recharts';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function AriaChart({data}) {
  
    console.log(data);
  return (
    <div>

{/* <ResponsiveContainer width="100%" height="100%"> */}
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="NameOfCounsellor" />
          <YAxis dataKey="TotalFinalAmount"/> 
          <Legend verticalAlign="top" height={36}/>
          <Tooltip />

          <Line type="monotone" dataKey="TotalFinalAmount" stackId="1" fill={`#4fe2dd`} />
          
          {/* {Object.keys(data).map((key, index) => (
        <Line key={key} type="monotone" dataKey="final_amount" data={data[key]} name={key} stackId="1" fill={`#4fe2dd`} stroke={`#${index + 1}f77b`} /> */}
      {/* ))} */}
      
        </LineChart>
      {/* </ResponsiveContainer> */}
 
    </div>
  )
}
