import React from 'react'

export default function StudentList() {
  return (
    <div>
         <div className="row">
        <table className='table table-striped'>
          <thead className='table-dark'>  
            <tr>
              <th>Sr. No.</th>
              <th> Student Name</th>
              <th> Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td>1.</td>
                <td>Student 1</td>
                <td><button
                    className="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteModal"
                    // onClick={() => setdeleteId(item?.id)}
                  >
                    <i className="bi bi-trash"></i>
                  </button></td>
            </tr>
          </tbody>
          {/* <tbody>
            {
              AllSupportTickets.map((ticket,i)=>(
                <tr style={{cursor:"pointer"}}>
                <td>{i+1}</td>
                <td>{ticket?.title}</td>
                <td>{ticket?.ticket_type}</td>
                <td>{TicketStatus=="N"&&"New"}{TicketStatus=="O"&&"Open"}</td>
                <td>
                   <button className='btn btn-info m-1' data-bs-toggle="modal" data-bs-target="#editTicketModal"
                   onClick={()=>setEditId(ticket?.id)}
                   ><i className="bi bi-pencil-square"></i></button>
                </td>
              </tr>
              ))
            }
          </tbody> */}
        </table>
      </div>
    </div>
  )
}
