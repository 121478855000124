import axios from "axios";
import React, { useEffect, useState } from "react";

export default function DoubtSolving() {
  const [MyBatches, setMyBatches] = useState([]);

  const getMyBatches = async () => {
    const userId = JSON.parse(localStorage.getItem("Userid"));
    const { data } = await axios.get(`/course/batch/student?stu_id=${userId}`);
    if ((data.data.status = "0x0")) {
      setMyBatches(data.data.batchData);
    }
  };

  useEffect(() => {
    const userGrp = JSON.parse(localStorage.getItem("user_group"));
    if (userGrp.includes("G1Student")) {
      getMyBatches();
    }
  }, []);

  return (
    <div>
      {MyBatches?.length >= 1 ? (
        <>
          {MyBatches?.map((batch, i) => (
            <div className="my-4 satLink">
              <div className="">
              <h5>Batch ID: {batch?.user_batch_id}</h5>
                <h6>Join Your Sessions Below Link:</h6>
                {/* <p className='my-2'>{batch?.doubt_solving_session_zoom_link
                  }</p> */}
                <div
                className=""
                  dangerouslySetInnerHTML={{
                    __html: batch?.doubt_solving_session_zoom_link,
                  }}
                ></div>
              </div>
              <hr />
            </div>
          ))}
        </>
      ) : (
        <h4 className="text-center my-5">Please... Enroll Course & Batch.</h4>
      )}
    </div>
  );
}
