import { configureStore } from "@reduxjs/toolkit";

// import counterReducer from "./counterSlice";
import contactUsReducer from "../slices/contactUsSlice.jsx"
import refreshTokenReducer from "../slices/RefreshToken.jsx"
import supportStuReducer from "../slices/SupportStuListSlice.jsx"
import signinReducer from "../slices/SignInSlice.jsx"
import companyListReducer from "../slices/companyListSlice.jsx"

const store = configureStore({
  reducer: {
    contactUs: contactUsReducer,
    refreshToken:refreshTokenReducer,
    supportStuList:supportStuReducer,
    singIn:signinReducer,
    companyList:companyListReducer,
  },
});

export default store;
