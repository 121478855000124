import axios from "axios";
import React, { useEffect, useState } from "react";
import closedTicket from "../../images/ClosedTicket.png";
import NewTicket from "../../images/RaisedTicket.png";
import pendingTicket from "../../images/PendingTicket.png";
import onHold from "../../images/Onhold.png";
// material ui
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];


export default function MyTickets() {
  const [EditId, setEditId] = useState("");
  const [AllTicketList, setAllTicketList] = useState([]);
  const [listNo, setlistNo] = useState(0);
  const [Reply, setReply] = useState("");
  const [Title, setTitle] = useState("");
  const [Desc, setDesc] = useState("");
  const [TicketType, setTicketType] = useState("");
  const [Attachment, setAttachment] = useState();
  const [TicketStatus, setTicketStatus] = useState("N");
  const [Modal, setModal] = useState(false);
  const [AllSupportTickets, setAllSupportTickets] = useState([]);
  const [pageNo, setpageNo] = useState(1);
  const [ListCount, setListCount] = useState(0);
  const [TotalCount, setTotalCount] = useState();
  const [IsLoading, setIsLoading] = useState(false);
  const [PendingTickets, setPendingTickets] = useState([]);
  const [ClosedTickets, setClosedTickets] = useState([]);
  const [OpentTickets, setOpentTickets] = useState([]);
  const [dismiss, setdismiss] = useState(false);
  const [onHoldTickets, setOnHoldTickets] = useState([]);
  const [NewTickets, setNewTickets] = useState([]);
  const [Ticket, setTicket] = useState();
  const [Replies, setReplies] = useState("");
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("Userid"))
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const userId = JSON.parse(localStorage.getItem("Userid"));

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const GetAllTickets = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    setIsLoading(true);
    const { data } = await axios.get(`/support/user?user_id=${userId}`);
    if (data.status == "0x0") {
      setAllSupportTickets(data?.data?.ticketData?.results);
      console.log("data?.data?.ticketData?.results");
      setListCount(data?.data?.ticketData.count / 10);
      setIsLoading(false);
      setPendingTickets(
        data?.data?.ticketData?.results.filter(
          (pending) => pending.ticket_status == "P"
        )
      );

      setClosedTickets(
        data?.data?.ticketData?.results.filter(
          (pending) => pending.ticket_status == "C"
        )
      );

      setOnHoldTickets(
        data?.data?.ticketData?.results.filter(
          (pending) => pending.ticket_status == "H"
        )
      );

      setNewTickets(
        data?.data?.ticketData?.results.filter(
          (pending) => pending.ticket_status == "N"
        )
      );
    }
  };

  const AddTicket = async (e) => {
    console.log("hello");
    handleRefreshToken();
    e.preventDefault();
    var formdata = new FormData();
    const userId = JSON.parse(localStorage.getItem("Userid"));
    formdata.append("user_info", userId);
    formdata.append("title", Title);
    formdata.append("description", Desc);
    formdata.append("ticket_type", TicketType);
    formdata.append("ticket_status", "N");
    formdata.append("attachment", Attachment);

    const { data } = await axios.post("/support/", formdata);
    // const {data} = await axios.post("http://127.0.0.1:8000/support/",formdata)

    if (data.status == "0x0") {
      setModal(true);
      GetAllTickets();
    }
    
  };

  const getTicket = async () => {
    const { data } = await axios.get(`/support/?id=${EditId}`);
    // const {data} = await axios.get(`http://127.0.0.1:8000/support/?id=${EditId}`)
    setTicket(data.data.ticketData[0]);
    setTitle(data.data.ticketData[0].title);
    setDesc(data.data.ticketData[0].description);
    setTicketType(data.data.ticketData[0].ticket_type);
    setTicketStatus(data.data.ticketData[0].ticket_status);
    setAttachment(data.data.ticketData[0].attachment);
    setReplies(data.data.ticketData[0].reply);
  };

  const EditTicket = async (e) => {
    handleRefreshToken();
    e.preventDefault();
    var formdata = new FormData();
    const userId = JSON.parse(localStorage.getItem("Userid"));
    console.log(userId);
    formdata.append("user_info", userId);
    formdata.append("title", Title);
    formdata.append("description", Desc);
    formdata.append("ticket_type", TicketType);
    formdata.append("ticket_status", TicketStatus);
    formdata.append("attachment", Attachment);
    // formdata.append("id", EditId);

    const { data } = await axios.put("/support/", formdata);
    // const {data} = await axios.put("http://127.0.0.1:8000/support/",formdata)
    if (data.status == "0x0") {
      setModal(true);
      GetAllTickets();
    }
  };

  useEffect(() => {
    GetAllTickets();
  }, []);

  useEffect(() => {
    if (EditId != "") {
      getTicket();
    }
  }, [EditId]);

// material ui table logic

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(+event.target.value);
  setPage(0);
};

  return (

    <div className="container internalContainer">
      <div className="d-flex justify-content-end my-2">
        <button
          className="btn btn-info"
          data-bs-target="#ticketModal"
          data-bs-toggle="modal"
        >
          Raise Ticket
        </button>
      </div>

      <div className="totalCount-wrapper stuData w-100  my-2">

        <div className=" totalCount d-flex flex-column align-items-center justify-content-center border py-2">
          <img src={NewTicket} alt="d_icon_1" width="35px" />
          <span className="amount">{NewTickets.length}</span>
          <span>New</span>
        </div>

        <div className=" totalCount d-flex flex-column align-items-center justify-content-center border py-2">
          <img src={pendingTicket} alt="d_icon_1" width="35px" />
          <span className="amount">{PendingTickets.length}</span>
          <span>Pending Tickets</span>
        </div>

        <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
          <img src={onHold} alt="d_icon_1" width="35px" />
          <span className="amount">{onHoldTickets.length}</span>
          <span>On Hold</span>
        </div>

        <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
          <img src={closedTicket} alt="d_icon_1" width="35px" />
          <span className="amount">{ClosedTickets.length}</span>
          <span>Closed</span>
        </div>
        
      </div>

{/* material ui table  */}
{/* <div className="stuData my-4">
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div> */}

      {/* <h3>My Courses</h3>
      <hr/>
      <div className="totalCount-wrapper w-100  my-2 row">

        <div className="col-6 totalCount1 d-flex flex-column align-items-center justify-content-center border py-2 ">
          <span className="amount">Data Analytics</span>
          <span>Biginner</span>
          <img src={NewTicket} alt="d_icon_1" width="35px" />
        </div>

        <div className="col-6 totalCount1 d-flex flex-column align-items-center justify-content-center border py-2 ">
          <span className="amount">Data Science</span>
          <span>Advance</span>
          <img src={pendingTicket} alt="d_icon_1" width="35px" />
        </div>
      </div>

      <h3>Recommended Courses</h3>
      <hr/>
      <div className="totalCount-wrapper w-100  my-2 row">

        <div className="col-6 totalCount1 d-flex flex-column align-items-center justify-content-center border py-2 ">
          <span className="amount">Complete AWS Course</span>
          <span>Biginner</span>
          <img src={NewTicket} alt="d_icon_1" width="35px" />
        </div>

        <div className="col-6 totalCount1 d-flex flex-column align-items-center justify-content-center border py-2 ">
          <span className="amount">Complete Power BI Course</span>
          <span>Advance</span>
          <img src={pendingTicket} alt="d_icon_1" width="35px" />
        </div>
      </div> */}

      {/* /////////////// */}

      {/* <h4 className="mb-4">Ticket List</h4> */}

      <div className="row mb-4">
        <div className="col-12 table table-responsive">
        <table className="table table-responsive table-striped">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Sr. No.</th>
              <th>Name</th>
              <th>Title</th>
              <th>Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {AllSupportTickets?.map((ticket, i) => (
              <tr key={i} style={{ cursor: "pointer" }}>
                <td>{i + 1 + listNo}</td>
                <td>{ticket?.first_name}</td>
                <td>{ticket?.title}</td>
                <td>
                  {ticket?.ticket_type == "C" && "Course"}
                  {ticket?.ticket_type == "P" && "Payment"}
                  {ticket?.ticket_type == "R" && "Refund"}
                  {ticket?.ticket_type == "O" && "Other"}
                </td>
                <td>
                  {ticket?.ticket_status == "N" && "New"}
                  {ticket?.ticket_status == "O" && "Open"}
                  {ticket?.ticket_status == "C" && "Closed"}
                  {ticket?.ticket_status == "H" && "On-Hold"}{" "}
                  {ticket?.ticket_status == "P" && "Pending"}
                  {ticket?.ticket_status == "S" && "Solved"}
                </td>
                <td>
                  <button
                    className="btn btn-info m-1 btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#editTicketModal"
                    onClick={() => setEditId(ticket?.id)}
                  >
                    <span className="tableView">View</span>
                    <Badge
                      color="secondary"
                      variant="dot"
                      invisible={ticket?.reply == "" ? true : false}
                    >
                      {ticket?.reply == "" ? (
                        ""
                      ) : (
                        <MailIcon fontSize="small" className="ms-2 mailIcon" />
                      )}
                    </Badge>
                  </button>
                </td>
                
              </tr>
            ))}
          </tbody>
         
        </table>
        </div>
      </div>

      {/* support ticket model  */}

      <div
        className="modal fade create-ticket-model"
        id="ticketModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Create Ticket
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={AddTicket}>
                    <div className="row">
                      <div className="col-12 my-2">
                        <input
                          type="text"
                          placeholder="Title"
                          onChange={(e) => setTitle(e.target.value)}
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="col-12 my-2">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setTicketType(e.target.value)}
                          required
                        >
                          <option selected>Select Ticket Type</option>
                          <option value="C">Course</option>
                          <option value="P">Payment</option>
                          <option value="R">Refund</option>
                          <option value="O">Other</option>
                        </select>
                      </div>

                      <div className="col-12 my-2">
                        <input
                          type="file"
                          placeholder="Attachment"
                          onChange={(e) => setAttachment(e.target.value)}
                          className="form-control"
                        />
                      </div>

                      <div className="col-12 my-2">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="3"
                          placeholder="Description"
                          className="form-control"
                          onChange={(e) => setDesc(e.target.value)}
                          required
                        ></textarea>
                      </div>

                      <div className="col-12 my-4 d-flex justify-content-end">
                        <button
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          type="submit"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* =================== Edit ticket modal ================ */}

      <div
        className="modal fade"
        id="editTicketModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                View Ticket
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={EditTicket}>
                    <div className="row">
                      <div className="col-12 my-2">
                        <label htmlFor="">Title:</label>
                        <input
                          type="text"
                          placeholder="Title"
                          // onChange={(e)=>setTitle(e.target.value)}
                          value={Title}
                          className="form-control"
                          required
                        />
                      </div>

                      <div className="col-12 my-2">
                        <label htmlFor="">Desc:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="4"
                          placeholder="Description"
                          className="form-control"
                          //  onChange={(e)=>setDesc(e.target.value)}
                          value={Desc}
                          required
                        ></textarea>
                      </div>
                      <div className="col-12 my-2">
                        <label htmlFor="">Replies:</label>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="4"
                          placeholder="Description"
                          className="form-control"
                          //  onChange={(e)=>setDesc(e.target.value)}
                          value={Replies}
                          required
                        ></textarea>
                      </div>

                      {/* <div className="col-12 my-4 d-flex justify-content-end">
            <button className="btn btn-primary" data-bs-dismiss={Modal&&"modal"}>Create</button>
          </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
