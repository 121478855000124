import React, { useState } from 'react'
import Topbar from './Topbar'
import courseImg from "../images/myCoursesImg.png"
import ViewBatches from './ViewBatches'
import SessionList from './SessionList'

export default function CourseDetails() {
  const [ViewBatch, setViewBatch] = useState("")

  return (
    <div className='container'>
      {/* <Topbar topBarHeading={"Course Details"}/> */}
      <div className="row my-4">  
        <div className="d-flex justify-content-between ">
            <button
              className="btn btn-info"
              // data-bs-target="#AddCourseModal"
              // data-bs-toggle="modal"
              onClick={()=>setViewBatch("show session list")}
            >
              Sessions
            </button>
            <button
              className="btn btn-info"
              // data-bs-target="#AddCourseModal"
              // data-bs-toggle="modal"
              onClick={()=>setViewBatch("show batches")}
            >
              View Batches
            </button>
            <button
              className="btn btn-info"
              data-bs-target="#AddCourseModal"
              data-bs-toggle="modal"
            >
              Edit Course
            </button>
        </div>
      </div>
      {
        ViewBatch =="" && <div className="courseDetail-wrapper">
             <div className="row">
               <div className="col-6">
                 <img src={courseImg} alt="" width="80%"/>
               </div>
               <div className="col-6">
                 <div className="row">
                   <div className="col-4">
                     <h4>Title:</h4>
                   </div>
                   <div className="col-8">
                   <p>Data Analytics</p>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-4">
                     <h4>Start Date:</h4>
                   </div>
                   <div className="col-8">
                   <p>2023/03/02</p>
                   </div>
                 </div>
               </div>
             </div>
             </div>
      }
      {
      ViewBatch=="show batches"&&<ViewBatches/>
      }
      {
        ViewBatch=="show session list"&&<SessionList/>
      }
 
    </div>
  )
}
