import React, { useEffect, useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component"
// material ui
import CircularProgress from "@mui/joy/CircularProgress";
import SearchBar from "material-ui-search-bar";
import Snackbar from "@mui/material/Snackbar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';

export default function PaidStuReport() {
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [Username, setUsername] = useState("");
    const [ProfileState, setProfileState] = useState("I");
    const [stuStatus, setstuStatus] = useState("");
    const [CourseName, setCourseName] = useState("");
    const [RegAmmt, setRegAmmt] = useState("");
    const [Education, setEducation] = useState("");
    const [Gender, setGender] = useState("");
    const [yrsOfGraduation, setyrsOfGraduation] = useState("");
    const [yrsOfExp, setyrsOfExp] = useState(0);
  
    const [TrainerName, setTrainerName] = useState("");
    const [PitchedAmount, setPitchedAmount] = useState("");
    const [CourseFee, setCourseFee] = useState("");
    const [NoOfInstallment, setNoOfInstallment] = useState("");
    const [InstallmentDetail, setInstallmentDetail] = useState("");
    const [PaymentDate, setPaymentDate] = useState("");
    const [ClousererName, setClousererName] = useState("");
    // const [ClousererArray, setClousererArray] = useState([]);
    const [CourseArray, setCourseArray] = useState([]);
  
    const [FinalFee, setFinalFee] = useState("");
    const [PhoneNo, setPhoneNo] = useState("");
    const [AllStuList, setAllStuList] = useState([]);
    const [deleteId, setdeleteId] = useState("");
    const [EditId, setEditId] = useState("");
    const [City, setCity] = useState("");
    const [LinkedIn, setLinkedIn] = useState("");
    const [Industry, setIndustry] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Discount, setDiscount] = useState("");
    const [CourseList, setCourseList] = useState([]);
    const [UserData, setUserData] = useState();
    const [CourseId, setCourseId] = useState("");
    const [BatchesList, setBatchesList] = useState([]);
    const [BatchId, setBatchId] = useState("");
    const [ListUpdate, setListUpdate] = useState(true);
    const [Modal, setModal] = useState();
    const [ProfilePic, setProfilePic] = useState("");
    const [pageNo, setpageNo] = useState(1);
    const [TotalCount, setTotalCount] = useState();
    const [ListCount, setListCount] = useState(0);
    const [listNo, setlistNo] = useState(0);
    const [IsLoading, setIsLoading] = useState(false);
    const [Search, setSearch] = useState("");
    const [open, setOpen] = React.useState(false);
    const [Installment1, setInstallment1] = useState();
    const [Installment1Status, setInstallment1Status] = useState();
    const [Installment2, setInstallment2] = useState();
    const [Installment2Status, setInstallment2Status] = useState();
    const [Installment3, setInstallment3] = useState();
    const [Installment3Status, setInstallment3Status] = useState();
    const [PaymentStatus, setPaymentStatus] = useState("");
    const [ModuleName, setModuleName] = useState("");
    const [PaymentType, setPaymentType] = useState("");
    const [View, setView] = useState("stuList");
    const [StuId, setStuId] = useState("");
    const [check, setCheck] = useState(null);
    const [clouser, setClouser] = useState({});
    const [course, setCourse] = useState({});
    const [IsFiltersActive, setIsFiltersActive] = useState(false);
    const [SortByBatch, setSortByBatch] = useState(false);
    const userGrp = JSON.parse(localStorage.getItem("user_group"));
    const name = JSON.parse(localStorage.getItem("name"));
    const [SalesUser, setSalesUser] = useState(
      userGrp.includes("G1Sales") && userGrp.length == 1 ? [name] : []
    );
  
    const [InstallMentStructure, setInstallMentStructure] = useState("");
    const [WelcomeStatus, setWelcomeStatus] = useState("");
    const [FilterBatch, setFilterBatch] = useState("");
    const [sort, setSort] = useState("D");
    const [CourseTitle, setCourseTitle] = useState([]);
    const [ModuleList, setModuleList] = useState([]);
    const [AllModuleList, setAllModuleList] = useState([]);
    const [CheckedModules, setCheckedModules] = useState([]);
    const [Checked, setChecked] = useState({});
    const [NextPage, setNextPage] = useState(false);
    const [SelectedModules, setSelectedModules] = useState([]);
    const [Remark, setRemark] = useState([])

    const handleRefreshToken = async () => {
        var formdata = new FormData();
        formdata.append("username", JSON.parse(localStorage.getItem("username")));
        formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
        formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
        const { data } = await axios.post("/users/token/refresh/", formdata);
        if (data.data.access) {
          localStorage.setItem("access", JSON.stringify(data.data.access));
        }
      };

      const clousers = [
        "Gaurav",
        "Shubhankar",
        "Tanya",
        "Rahul",
        "Aman",
        "Himanshu",
        "Renuka",
        "Sourav",
        "Trapti",
        "Dinisha",
        "Rituraj",
        "Pratik",
        "Rahul D",
        "Shreyas",
        "Nirmal",
        "Prem",
        "Sachin"
      ];
      const getStuProfile = async () => {
        handleRefreshToken();
        const { data } = await axios.get(
          `/users/student/create_payment?payment_id=${EditId}`
        );    
        if (data.status == "0x0") {
          setRemark([data?.data?.userData.remark[0]]);
          // setBatchId("");
          // setCourseId("");
          // setCourseList([])
          // setBatchesList([]);
        }
      };
    const handleGetAllStu = async () => {
        AllStuList.length == 0 && setIsLoading(true);
        pageNo==1&&setAllStuList([])
        setNextPage(false)
        const token = JSON.parse(localStorage.getItem("access"));
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
    
    const { data } = await axios.get(
          `/users/student/create_payment/list?page=${pageNo}&counsellor=${JSON.stringify(
            SalesUser
          )}&search=${Search}&course=${JSON.stringify(
            CourseArray
          )}&stud_info__student_status=${FilterBatch}&sort=&sales_lead_info__remaining_amount=0`
        );

        if (data.status == "0x0") {
          pageNo==1 && setAllStuList([])
          IsFiltersActive && setAllStuList([])
          setAllStuList((prev)=>[...prev, ...data?.data?.student_list.results]);
          setTotalCount(data?.data?.student_list.count);
          setListCount(data?.data?.student_list.count / 20);
          setIsLoading(false);
          setNextPage(true)
          setSortByBatch(false);
          setIsFiltersActive(false)
        }
      };
    
    const handleNextPage = () => {
        if (pageNo < ListCount && NextPage) {
          setpageNo((prev) => prev + 1);
          setlistNo((prev) => prev + 20);
        }
      };

    const handleGetAllCourse = async () => {
        handleRefreshToken();
        const token = JSON.parse(localStorage.getItem("access"));
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
    
        // const { data } = await axios.get("/course/course/list");
    
        const { data } = await axios.get("/course/course/");
        setCourseList(data?.data?.courseDetails);
      };

    const handleCourseFilter = (event) => {
        const testCourse = {
          ...course,
          [event.target.name]: event.target.checked,
        };
        setCourse(testCourse);
      };
    
    const createFilter = async () => {
        // e.preventDefault();
        setpageNo(1)
        setSearch("")
        // setIsLoading(true);
        handleRefreshToken();
        setAllStuList([])
        const token = JSON.parse(localStorage.getItem("access"));
    
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
    
        const Course = [];
        Object.entries(course).map((key, value) => {
          if (key[1]) Course.push(key[0]);
        });
        setCourseArray([...Course])
        setIsFiltersActive(true);
      };

      const handleSearch = async () => {
        handleRefreshToken();
        const token = JSON.parse(localStorage.getItem("access"));
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
    
        const { data } = await axios.get(
          `/users/student/create_payment/list?page=1&search=${Search}&counsellor=${JSON.stringify(
            SalesUser
          )}&course=[]&sort=&sales_lead_info__remaining_amount=0`
        );
    
        if (data.status == "0x0") {
          setAllStuList([])
          setAllStuList(data?.data?.student_list.results);
          setTotalCount(data?.data?.student_list.count);
          setListCount(data?.data?.student_list.count / 20);
          // setIsLoading(false)
        }
    
      };

      const handleClouserFilter = (event) => {
        const testClouser = {
          ...clouser,
          [event.target.name]: event.target.checked,
        };
        setClouser(testClouser);
        setSalesUser(testClouser);
      };

      const AddRemark = async (e) => {
        e.preventDefault();
        handleRefreshToken();
        const token = JSON.parse(localStorage.getItem("access"));
        axios.defaults.headers.common = {
          Authorization: `Bearer ${token}`,
        };
        // const remark=[]
        // remark.push(Remark)
        // console.log(remark)
        var formdata = new FormData();
        formdata.append("payment_id", EditId);
        formdata.append("remark", JSON.stringify(Remark));
    
        const { data } = await axios.put(
          "/users/student/add_remark/",
          formdata
        );
    
        if (data.status == "0x0") {
          // handleGetAllStu();
          setEditId("")
          setRemark("")
          // remark=[]
        }
      };

      useEffect(() => {
        getStuProfile();
        handleGetAllCourse();
      }, [EditId]);

      useEffect(() => {
        handleGetAllStu()
      }, [])

      useEffect(() => {
        handleGetAllStu()
      }, [pageNo,Search,CourseArray,SalesUser])

      useEffect(() => {
        handleSearch()
      }, [Search])

  return (
    <div>
    <h4>Paid Students</h4>
    <div className="row mb-4">
    <div className="col-10">
              <SearchBar
                value={Search}
                onChange={(newValue) => setSearch(newValue)}
                onCancelSearch={(newValue) => setSearch("")}
                style={{ padding: "-10px" }}
              />
    </div>
    <div className="col-2">
              <button
                className="btn btn-info btn-lg"
                data-bs-target="#ClouserFilterModal"
                data-bs-toggle="modal"
              >
                Filters <i className="ms-1 bi bi-funnel"></i>
              </button>
          </div>
    </div>
    {
            IsLoading 
            ?<div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
                  <CircularProgress size="lg" />
             </div>
    :<div className="table table-responsive mt-2" id="hello">
            <table className="table table-striped custom-bordered">
              <thead className="table-dark">
                <tr>
                  <th className="text-center  ">
                    <p className="srNo">Sr. No.</p>
                  </th>
                  <th className="supportTable">
                      <p
                        className="dropdown-toggle supportTable"
                        role="button"
                        // data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Name
                      </p>
                  </th>
                  
                  <th className="supportTable">
                    <p className="supportTable">Total Fees</p>
                  </th>
                  <th className="supportTable">
                    <p className="supportTable">Paid Amount</p>
                  </th>
                 
                  <th className="supportTable">
                    {/* <div className="dropdown supportTable"> */}
                      <p
                        className="dropdown-toggle supportTable"
                        role="button"
                        // data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Fees Bal.
                      </p>
                      {/* <ul className="dropdown-menu">
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={GetAllStuByBatchEnroll}
                        >
                          <span className="dropdown-item">
                            Show All
                          </span>
                        </li>
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={handleGetAllStu}
                        >
                          <span className="dropdown-item">
                            Show Fees Balance Only
                          </span>
                        </li>
                      </ul> */}
                    {/* </div> */}
                  </th>
                  <th className="supportTable"><p className="supportTable">Last Payment Date</p></th>
                  <th className="supportTable">
                    <p className="supportTable">Clouserer</p>
                  </th>

                  <th className="supportTable">
                    <p className="supportTable">Email (Username)</p>
                  </th>
                  <th className="supportTable">
                    <p className="supportTable">BatchId</p>
                  </th>
                  <th className="supportTable">
                    <p className="supportTable">Course</p>
                  </th>
                  <th className="supportTable">
                    <p className="supportTable">Actions</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {AllStuList.length == 0 ? (
                  <tr><td className="" colspan="8" ><h4> No Results Found OR Select Clouser From Filter</h4></td></tr>
                ) : (
                  <>
                    {AllStuList.map((item, i) => (
                      <>
                      <tr key={i} className="p-0 m-0">
                        <td>{i + 1}</td>
                        <td>
                          {item?.first_name} {item?.last_name}
                        </td>
                        <td>{item?.final_amount}</td>
                        <td>
                          {item?.final_amount - item?.remaining_amount}
                        </td>
                        <td>{item?.remaining_amount}</td>
                        <td>{item?.payment_date}</td>
                        <td>{item?.counsellor}</td>
                        <td>{item?.username}</td>
                        <td
                          style={{ cursor: "pointer" }}
                        //   onClick={() => handleView(item?.payment_id)}
                        >
                          {item?.batch_Id == null ? "----" : item?.batch_Id}
                          {item?.batch_count > 1 && "..."}
                        </td>
                        <td>
                          {item?.course == null ? "----" : item?.course}
                        </td>
                         <td className="d-flex justify-content-center align-items-center"> 

                          <button
                            className="btn btn-info btn-sm mx-1"
                            data-bs-toggle="modal"
                            data-bs-target="#editStuModal"
                            onClick={() => setEditId(item?.payment_id)}
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                         
                        </td>
                      </tr>
                      </>
                    ))}
                    <InfiniteScroll
                      dataLength={AllStuList.length}
                      next={handleNextPage}
                      hasMore={true}
                      loader={TotalCount!=AllStuList.length&&<h4>Loading...</h4>}
                      endMessage={
                        TotalCount==AllStuList.length && <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                    </InfiniteScroll>
                  </>
                )}
              </tbody>
            </table>
    </div>
}
            {/* filter modal */}

  <div
    className="modal fade"
    id="ClouserFilterModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="exampleModalLabel">
            Apply filters
          </h1>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
          {(!userGrp.includes("G1Sales")||userGrp.length>2)&&<div className="col-3">
                  <h6>Filter By Clouser</h6>
                  <FormGroup>
                    {clousers.map((clouser, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleClouserFilter}
                            name={clouser}
                          />
                        }
                        label={clouser}
                        key={i}
                      />
                    ))}
                  </FormGroup>
                </div>}
            <div className="col-6">
              <h6>Filter By Course</h6>
              <FormGroup>
                {CourseList.map((Course, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCourseFilter}
                        name={Course.title}
                      />
                    }
                    label={Course.title}
                    key={i}
                  />
                ))}
              </FormGroup>
            </div>
            <div className="col-3">
            <h6>Filter By Fees Balance</h6>
              <select
                className="form-select"
                onChange={(e) => setSort(e.target.value)}
              >
                <option value="" selected>
                 Select Option
                </option>
                <option value="D" >
                  Descending
                </option>
                <option value="A">
                  Ascending
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="col-12 my-4 d-flex justify-content-end">
            <button
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={createFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

{/* edit stu  */}
<div
        className="modal fade"
        id="editStuModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Your Remark
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
             <textarea name="" id="" cols="30" rows="5" className='form-control' placeholder="Enter Remark" onChange={(e)=>setRemark([e.target.value])} value={Remark}></textarea>
             <button className="btn btn-info my-2" onClick={AddRemark} data-bs-dismiss="modal">Submit</button>
            </div>
          </div>
        </div>
      </div>

</div>
  )
}
