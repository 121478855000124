import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Topbar from "../Topbar";
import "../../style/dashboard.css";
import AriaChart from "../charts/AriaChart";
// images
import d_icon_1 from "../../images/Customers.png";
import d_icon_2 from "../../images/Revenue.png";
import d_icon_3 from "../../images/Balance.png";
import d_icon_4 from "../../images/Registration.png";
import d_icon_5 from "../../images/Dashboard.png";
import DbBarCharts from "../charts/DbBarCharts";
/////
// import { DateRangePicker, Stack } from 'rsuite';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import DbPiechart from "../charts/DbPiechart";
import MyTickets from "../student/MyTickets";
// material ui 
import CircularProgress from "@mui/joy/CircularProgress";
import SupportTicket from "../SupportTicket";
import StuListForSupport from "../Support/StuListForSupport";


export default function Dashboard({ onChange }) {
  const [TotalCount, setTotalCount] = useState();
  const [StuList, setStuList] = useState([]);

  const [selfData, setselfData] = useState([]);
  const [AllSaleData, setAllSaleData] = useState({});
  const [TRC, setTRC] = useState(0);
  const [TFB, setTFB] = useState(0);
  const [TotalStudent, setTotalStudent] = useState(0);
  const [RegAmount, setRegAmount] = useState(0);
  const userGrp = JSON.parse(localStorage.getItem("user_group"));
  const name = JSON.parse(localStorage.getItem("name"));
  const abc = userGrp.includes("G1Sales") && userGrp.length == 1 ? name : "";
  const [Closurer, setClosurer] = useState(abc);
  const [GraphData, setGraphData] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);


  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  // const [FromDate, setFromDate] = useState(`${yyyy}-${mm}-${dd}`);
  // const [ToDate, setToDate] = useState(`${yyyy}-${mm}-${dd}`);
  // const [fd, setfd] = useState(`${mm == 1 ? yyyy - 1 : yyyy}-${mm == 1 ? 12 : mm - 1}-${dd}`)

  const [FromDate, setFromDate] = useState(
    `${yyyy}-${mm}-1`
  );

  const [ToDate, setToDate] = useState(`${yyyy}-${mm}-${dd}`);

  const salesTeam = [
    "Gaurav",
    "Shubhankar",
    "Tanya",
    "Rahul",
    "Aman",
    "Himanshu",
    "Renuka",
    "Sourav",
    "Trapti",
    "Dinisha",
    "Rituraj",
    "Pratik",
    "Rahul D",
    "Prem",
    "Nirmal",
    "shreyas"
  ];

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleGetAllStu = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/users/student/list/`);
    if (data.status == "0x0") {
      setTotalCount(data?.data?.student_list.count);
    }
  };

  const handleTotalRevenueCount = () => {
    let totalRegistrationAmount = 0;
    if (Object.keys(StuList).length == 0) {
      setTRC(0);
    } else {
      Object.values(StuList).forEach((userArray) => {
        userArray.forEach((user) => {
          totalRegistrationAmount += user.final_amount;
          setTRC(totalRegistrationAmount);
        });
      });
    }
  };

  const handleTotalStuCount = () => {
    let totalStu = 0;
    if (Object.keys(StuList).length == 0) {
      setTotalStudent(0);
    } else {
      Object.values(StuList).forEach((userArray) => {
        totalStu += userArray.length;
        setTotalStudent(totalStu);
      });
    }
  };

  const totalRegAmount = () => {
    let totalRegistrationAmount = 0;
    if (Object.keys(StuList).length == 0) {
      setRegAmount(0);
    } else {
      Object.values(StuList).forEach((counsellorData) => {
        counsellorData.forEach((data) => {
          totalRegistrationAmount += data.registration_amount;
          setRegAmount(totalRegistrationAmount);
        });
      });
    }
  };

  const handleTotalFeesBalance = () => {
    let totalRecovery = 0;
    let totalRegistrationAmount = 0;
    if (Object.keys(StuList).length == 0) {
      setTFB(0);
    } else {
      Object.values(StuList).forEach((userArray) => {
        userArray.forEach((user) => {
          totalRegistrationAmount += user.final_amount;

          totalRecovery +=
            user.registration_amount +
            (user.installment1_status == "P" && user.installment1) +
            (user.installment2_status == "P" && user.installment2) +
            (user.installment3_status == "P" && user.installment3);
          setTFB(totalRegistrationAmount - totalRecovery);
        });
      });
    }
  };

  const handleRevenue = async () => {
    handleRefreshToken();
    setIsLoading(true)
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `/users/student/dashboard/list/?from_date=${FromDate}&to_date=${ToDate}&counsellor=${Closurer}`
    );
    if ((data.status = "0x0")) {
      setIsLoading(false)
      setStuList(data.data.userData);
    }
  };

  useEffect(() => {
    handleGetAllStu();
    if (!userGrp.includes("G1Student") && userGrp.length == 1) {
      handleRevenue();
    }
  }, []);

  /////////////////
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  //   },
  // ]);

  // const [dateRange, setDateRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: 'selection',
  // });
  // const [isOpen, setIsOpen] = useState(false);

  // const handleDateRangeChange = (ranges) => {
  //   setDateRange(ranges.selection);
  // };

  // const setDateRanges=()=>{
  //   const Sdate = new Date(dateRange.startDate);
  //   const Syear = Sdate.getFullYear();
  //   const Smonth = ('0' + (Sdate.getMonth() + 1)).slice(-2);
  //   const Sday = ('0' + Sdate.getDate()).slice(-2);
  //   const Edate = new Date(dateRange.endDate);
  //   const Eyear = Edate.getFullYear();
  //   const Emonth = ('0' + (Edate.getMonth() + 1)).slice(-2);
  //   const Eday = ('0' + Edate.getDate()).slice(-2);

  //   setFromDate(`${Syear}-${Smonth}-${Sday}`)
  //   setToDate(`${Eyear}-${Emonth}-${Eday}`)
  // }

  // const handleOpen = () => {
  //   setIsOpen((prev)=>!prev);
  // };
  // const handleClose = () => {
  //   setIsOpen(false);
  // };

  // useEffect(() => {
  //   setDateRanges()
  // }, [dateRange])

  // useEffect(() => {
  //   handleClose()
  // }, [ToDate])

  ////////

  // useEffect(() => {
  //   alert("");
  //   if (userGrp.includes("G1Sales") && userGrp.length == 1) {
  //     setClosurer(name);
  //   }
  // }, []);

  useEffect(() => {
    // if (Closurer != "") {
    // }
    handleRevenue();
    
  }, [Closurer, FromDate, ToDate]);

  const createGraphData = () => {
    const array = [];
    Object.keys(StuList).reduce((acc, counsellor) => {
      const counsellorData = StuList[counsellor];
      const noOfStu = counsellorData.length;
      const totalFinalAmount = counsellorData.reduce(
        (sum, data) => sum + data.final_amount,
        0
      );
      array.push({ NameOfCounsellor: counsellor, NumberOfStudents:noOfStu, TotalFinalAmount:totalFinalAmount });
    }, []);
    setGraphData(array);
  };

  useEffect(() => {
    handleTotalRevenueCount();
    handleTotalFeesBalance();
    handleTotalStuCount();
    totalRegAmount();
    createGraphData();
  }, [StuList]);

  useEffect(() => {
    handleRevenue();
    console.log(FromDate);
  }, []);
  
  return (
    <div className="dasboard1">
      <div>
        {/* <Topbar topBarHeading={"Dashboard"} /> */}
      </div>

      {/* <div className="container">
        <h3 className="my-2">Welcome {name}</h3>
      </div> */}
            <h4 className="mt-2">Dashboard Overview</h4>

      {userGrp.includes("G1Student") && userGrp.length == 1 ? (
        ""
      ) : (
        <>
          {/* <div className="d-flex align-items-center justify-content-start">
          <button className="btn btn-light border" onClick={handleOpen}>{FromDate!=""?FromDate:"yyyy-mm-dd"}~{ToDate!=""?ToDate:"yyyy-mm-dd"}</button>
          {isOpen&&
         <DateRangePicker
        ranges={[dateRange]}
        onChange={handleDateRangeChange}
      />}
      <div>
       {userGrp.includes("G1Sales") && userGrp.length == 1 ? (
              ""
            ) : (
              <div className="mx-1">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setClosurer(e.target.value)}
                  value={Closurer}
                >
                  <option value="" selected>
                    Select Closurer:
                  </option>
                  {salesTeam.map((sale, i) => (
                    <option value={sale} className="text-dark" key={i}>
                      {sale}
                    </option>
                  ))}
                </select>
              </div>
            )}
            </div>
              <button
                className="btn btn-info"
                type="button"
                onClick={handleRevenue}
              >
                Go
              </button>
              </div> */}

          <div className="d-flex justify-content-between align-items-center ">
            <div className=" d-flex justify-content-end my-3 border">
              <div className="ms-1">
                {/* <label htmlFor="">From Date:</label> */}
                <input
                  type="date"
                  className="form-control db-inputs"
                  placeholder="From Date"
                  value={FromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className=" ms-1">-</div>
              <div className=" ms-1">
                {/* <label htmlFor="">To Date:</label> */}
                <input
                  type="date"
                  className="form-control db-inputs"
                  placeholder="To Date"
                  value={ToDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>

              {userGrp.includes("G1Sales") && userGrp.length == 1 ? (
                ""
              ) : (
                <div className="ms-1">
                  <select
                    className="form-select db-inputs"
                    aria-label="Default select example"
                    onChange={(e) => setClosurer(e.target.value)}
                    value={Closurer}
                  >
                    <option value="" selected>
                     Select Closurer
                    </option>
                    {salesTeam.map((sale, i) => (
                      <option value={sale} className="text-dark" key={i}>
                        {sale}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* <div className="ms-1">
              <button
                className="btn btn-info mt-4"
                type="button"
                onClick={handleRevenue}
              >
                Go
              </button>
            </div> */}

              {/* <button
              className="btn btn-info btn-sm ms-1"
              type="button"
              onClick={handleRevenue}
            >
              Go
            </button> */}
            </div>
          </div>

          <div className="d-flex justify-content-between w-100  my-2">
            <div className=" totalCount d-flex flex-column align-items-center justify-content-center border py-2">
              <img src={d_icon_1} alt="d_icon_1" width="35px" />
              <span className="amount">{TotalStudent}</span>
              <span>Students</span>
            </div>
            <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
              <img src={d_icon_4} alt="d_icon_1" width="35px" />
              <span className="amount">{RegAmount}</span>
              <span>Registration Amount</span>
            </div>
            <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
              <img src={d_icon_2} alt="d_icon_1" width="35px" />
              <span className="amount">{TRC}</span>
              <span>Projected Revenue</span>
            </div>
            <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
              <img src={d_icon_2} alt="d_icon_1" width="35px" />
              <span className="amount">{TRC-TFB}</span>
              <span>Actual Revenue</span>
            </div>
            <div className=" totalCount d-flex flex-column align-items-center justify-content-center border p-2">
              <img src={d_icon_3} alt="d_icon_1" width="35px" />
              <span className="amount">{TFB}</span>
              <span>Balance</span>
            </div>
          </div>

          {/* ================= dashboard filters ================ */}

          <div className="row mb-5">
            {
              <>
                <div className="row my-3">
                  <div className="col-6 graph py-4 px-0 border">
                    {/* <h4>AriaChart</h4> */}
                    <h5 className="text-center">Total Revenue Per Counsellor</h5>
                    <AriaChart data={GraphData} />
                  </div>

                  <div className="col-6 graph py-4 px-0 border">
                    {/* <DbBarCharts data={GraphData} /> */}
                    <h5 className="text-center">Number Of Students Per Counsellor</h5>
                    <DbPiechart data={GraphData} />
                  </div>

                </div>

                {/* <div className="row mb-3 ms-3 mb-4 ">
                  <div className="col-3">
                    <label htmlFor="">From Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={FromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-3">
                    <label htmlFor="">To Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={ToDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                  {userGrp.includes("G1Sales") && userGrp.length == 1 ? (
                    ""
                  ) : (
                    <div className="col-3">
                      <label htmlFor="">Select closurer:</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setClosurer(e.target.value)}
                        value={Closurer}
                      >
                        <option value="" selected>
                          Select Closurer:
                        </option>
                        {salesTeam.map((sale, i) => (
                          <option value={sale} className="text-dark" key={i}>
                            {sale}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="col-3">
                    <button
                      className="btn btn-info mt-4"
                      type="button"
                      onClick={handleRevenue}
                    >
                      Go
                    </button>
                  </div>
                </div> */}

                <table className="table table-striped table mt-2 mb-5 ">
                  <thead className="table-dark">
                    <tr>
                      <th>Closurer</th>
                      <th>No. of Admissions</th>
                      <th>Revenue Generated</th>
                      <th>Balance Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    { Object.keys(StuList).length >= 1 ? (
                      <>
                        {Object.keys(StuList).map((key, value) => (
                          <tr>
                            <td>{key}</td>
                            <td>{StuList[key].length}</td>
                            
                            <td>
                              {StuList[key].reduce(
                                (a, c) => a + c.final_amount,
                                0
                              )}
                            </td>

                            <td>
                              {StuList[key].reduce(
                                (a, c) => a + c.final_amount,
                                0
                              ) -
                                StuList[key].reduce(
                                  (a, c) => a + c.registration_amount,
                                  0
                                ) -
                                StuList[key].reduce(
                                  (a, c) =>
                                    a +
                                    (c.installment1_status == "P" &&
                                      c.installment1),
                                  0
                                ) -
                                StuList[key].reduce(
                                  (a, c) =>
                                    a +
                                    (c.installment2_status == "P" &&
                                      c.installment2),
                                  0
                                ) -
                                StuList[key].reduce(
                                  (a, c) =>
                                    a +
                                    (c.installment3_status == "P" &&
                                      c.installment3),
                                  0
                                )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      // <tr>
                      //   <td>{Closurer}</td>
                      //   <td>{StuList.length}</td>
                      //   <td>
                      //     {StuList.reduce((a, c) => a + c.final_amount, 0)}
                      //   </td>
                      //   <td>
                      //     {StuList.reduce((a, c) => a + c.final_amount, 0) -
                      //       StuList.reduce(
                      //         (a, c) => a + c.registration_amount,
                      //         0
                      //       ) -
                      //       StuList.reduce(
                      //         (a, c) =>
                      //           a +
                      //           (c.installment1_status == "P" &&
                      //             c.installment1),
                      //         0
                      //       ) -
                      //       StuList.reduce(
                      //         (a, c) =>
                      //           a +
                      //           (c.installment2_status == "P" &&
                      //             c.installment2),
                      //         0
                      //       ) -
                      //       StuList.reduce(
                      //         (a, c) =>
                      //           a +
                      //           (c.installment3_status == "P" &&
                      //             c.installment3),
                      //         0
                      //       )}
                      //   </td>
                      // </tr>
                      <h4 className="text-center my-5">No Data Found...</h4>
                    )}
                    </tbody>
                </table>
                
              </>
            }
          </div>
        </>
      )}
      
      {(userGrp.includes("G1Student")&& userGrp.length==1) && <MyTickets/>}
      {/* {(userGrp.includes("G1Student")&& userGrp.length==1) && <SupportTicket/>} */}
      
    </div>
  );
}
