import axios from "axios";
import React, { useEffect, useState } from "react";
// material ui
import CircularProgress from "@mui/joy/CircularProgress";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyToClipboardButton from "../CopyToCliboard";
import SearchBar from "material-ui-search-bar";
import DownloadExcelSheet from "../Support/DownloadExcelSheet";

export default function BatchStuList({
  BatchId,
  RemoveBatchId,
  viewBatch,
  setViewBatch,
}) {
  const [pageNo, setpageNo] = useState(1);
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);
  const [TotalCount, setTotalCount] = useState();
  const [StuList, setStuList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [RemoveStuId, setRemoveStuId] = useState("");
  const [StuId, setStuId] = useState("");
  const [CertStatus, setCertStatus] = useState("");
  const [emails, setemails] = useState([]);
  const [Closerer, setCloserer] = useState("");
  const [ModuleList, setModuleList] = useState([]);
  const [PaymentDate, setPaymentDate] = useState("");
  const [sort, setSort] = useState("");
  const [BatchRevenue, setBatchRevenue] = useState(0);
  const [Search, setSearch] = useState("");
  const [dataForXLSX, setdataForXLSX] = useState();
  const [AllBatchStu, setAllBatchStu] = useState([]);

  const NameOfCloserer = [
    "",
    "Gaurav",
    "Aman",
    "Nirmal",
    "Sourav",
    "Shreyas",
    "shubhankar",
    "Himanshu",
    "Renuka",
    "Rahul",
  ];

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const getBatch = async () => {
    setIsLoading(true);
    handleRefreshToken();

    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    // const { data } = await axios.get(
    //   `/course/batch/student_list?batch_id=${BatchId}`
    // );
    
    const { data } = await axios.get(
      `/course/batch/student_list/v1_1?batch_id=${BatchId}&page=${pageNo}&stud_info__counsellor=${Closerer}&payment_date=${PaymentDate}&sort=${sort}&search=${Search}`
    );

    if (data.status == "0x0") {
      setStuList(data.data.student_list.results);
      setListCount(data.data.student_list.count);
      setIsLoading(false);
      setBatchRevenue(data.data.batchRevenue);
    } else {
      setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 20);
    }
  };

  const handlePrevPage = () => {
    console.log("hello prev");
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 20);
    }
  };

  const handleRemoveStu = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("batch_id", RemoveBatchId);
    formdata.append("remove_students", RemoveStuId);

    const { data } = await axios.put("/course/batch/enroll/v1_1", formdata);

    if (data.status == "0x0") {
      getBatch();
    }
  };

  const UpdateCertStatus = async (e) => {
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("payment_id", StuId);
    formdata.append("certification_status", CertStatus);

    const { data } = await axios.put(
      "/users/student/profile/cert_status",
      formdata
    );
    if (data.status == "0x0") {
      getBatch();
    }
  };

  const getStuProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(
      `/users/student/create_payment?payment_id=${StuId}`
    );
    if (data.status == "0x0") {
      setCertStatus(data?.data?.userData.certification_status);
    }
  };

  const getStuEmails = async () => {
    handleRefreshToken();
    const batchId = [BatchId];

    const { data } = await axios.get(
      `/users/student/emails?batch_id=${JSON.stringify(batchId)}`
    );

    if (data.status == "0x0") {
      setemails(data?.data?.studentData);
    }
  };

  const copyToClipboard = () => {
    // navigator.clipboard.writeText(emails.join("'\n'"));
    navigator.clipboard.writeText(emails.join("\n"));
  };

  const handleGetAllBatchStu = async () => {
    const { data } = await axios.get(
      `/course/batch/student_list?batch_id=${BatchId}`
    );
    if (data.status == "0x0") {
      setAllBatchStu(data.data.StudentData);
    }
  };

  const handleExcelSheetData = () => {
    const excelData = [["Name", "Course", "Email Id", "Phone No.","Fees Balance", "Module"]];
    AllBatchStu.map(
      (stu, i) =>
        // stu.remaining_amount == 0 &&
        excelData.push([
          stu.first_name + " " + stu.last_name,
          stu.course,
          stu.username,
          stu.phone_numbers,
          stu.remaining_amount,
          stu.modules.length != 0 &&
            stu.modules.reduce(
              (acc, current, index) => acc + ", " + current.toString()
            ),
        ])
    );
    setdataForXLSX(excelData);
  };

  useEffect(() => {
    handleGetAllBatchStu();
  }, [BatchId]);

  useEffect(() => {
    if (StuId != "") {
      getStuProfile();
    }
  }, [StuId]);

  useEffect(() => {
    getBatch();
    getStuEmails();
  }, []);

  useEffect(() => {
    getBatch();
  }, [Search]);

  useEffect(() => {
    getBatch();
  }, [pageNo, Closerer, sort]);

  useEffect(() => {
    handleExcelSheetData();
  }, [StuList]);

  return (
    <div>
      <div className="my-3 d-flex justify-content-between align-items-center">
        <div className="arrow-wrapper">
          <h2
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => setViewBatch("batchList")}
          >
            <i className="bi bi-arrow-left-circle-fill"></i>
          </h2>
        </div>
        <div className="">
          <div className="batchRevenue ">
            <span>Batch Revenue</span>
            <h4 className="revenueAmount">{BatchRevenue}</h4>
          </div>
        </div>

        <div className="searchWrapper">
          <SearchBar
            value={Search}
            onChange={(newValue) => setSearch(newValue)}
            onCancelSearch={(newValue) => setSearch("")}
            style={{ padding: "-10px" }}
          />
        </div>
      </div>
      <div className="row">
        {IsLoading ? (
          <>
            <div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
              <CircularProgress size="lg" />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between mb-2">
              <h4>Batch Id: {BatchId}</h4>
              <div className="d-flex justify-content-between">
                <div className="me-2">
                  <DownloadExcelSheet dataForXLSX={dataForXLSX} />
                </div>
                <div>
                  <CopyToClipboardButton data={emails} />
                </div>
              </div>
            </div>
            <>
              <div className="table table-responsive">
                <table className="table table-striped">
                  <thead className="table-dark">
                    <tr>
                      <th className="text-center">
                        <p className="srNo">Sr. No.</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Name</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Email (Username)</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Phone No.</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Course Name</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Module Name</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable"> Total Fees</p>
                      </th>
                      <th className="supportTable">
                        {" "}
                        <div className="dropdown ">
                          <p
                            className="dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Fees Bal.
                          </p>
                          <ul className="dropdown-menu">
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => setSort("")}
                            >
                              <span className="dropdown-item">
                                All students
                              </span>
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => setSort("D")}
                            >
                              <span className="dropdown-item">Descending</span>
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() => setSort("A")}
                            >
                              <span className="dropdown-item">Ascending</span>
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th className="supportTable">
                        {" "}
                        <p className="supportTable">Last payment Date</p>
                      </th>
                      <th>
                        <div className="dropdown">
                          <p
                            className="dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Closerer
                          </p>
                          <ul className="dropdown-menu">
                            {NameOfCloserer.map((closer, i) => (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() => setCloserer(closer)}
                              >
                                <span className="dropdown-item">
                                  {closer == "" ? "All" : closer}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </th>
                      <th className="supportTable">
                        {" "}
                        <p className="supportTable">Certificate</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Actions</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {StuList?.length === 0 ? (
                      <h2 className="text-center my-5">Empty Batch</h2>
                    ) : (
                      <>
                        {StuList?.map((batch, i) => (
                          <tr>
                            <td>{i + 1 + listNo}</td>
                            <td>
                              {batch.first_name} {batch.last_name}
                            </td>
                            <td>{batch.username}</td>
                            <td>{batch.phone_numbers}</td>
                            <td>{batch.course}</td>
                            <td
                              style={{ cursor: "pointer" }}
                              data-bs-target="#modules"
                              data-bs-toggle="modal"
                              onClick={() => setModuleList(batch.modules)}
                            >
                              {batch.modules.length > 1
                                ? batch.modules[0] + "..."
                                : batch.modules.length === 0
                                ? "---"
                                : batch.modules[0]}
                            </td>
                            <td>{batch.final_amount}</td>
                            <td>{batch.remaining_amount}</td>
                            <td>{batch.payment_date}</td>
                            <td>{batch?.counsellor}</td>
                            <td>
                              {batch?.certification_status == "U" && "Unsent"}
                              {batch?.certification_status == "S" && "Sent"}
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <button
                                  className="btn btn-info btn-sm m-1"
                                  data-bs-toggle="modal"
                                  data-bs-target="#certificateModal"
                                  onClick={() => {
                                    setStuId(batch?.payment_id);
                                  }}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                </button>

                                <button
                                  className="btn btn-danger btn-sm m-1"
                                  data-bs-target="#removeStuModal"
                                  data-bs-toggle="modal"
                                  onClick={() =>
                                    setRemoveStuId(batch?.payment_id)
                                  }
                                >
                                  <i class="bi bi-person-dash"></i>{" "}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between my-5">
                <button className="btn btn-info" onClick={handlePrevPage}>
                  Prev
                </button>
                <button className="btn btn-info" onClick={handleNextPage}>
                  Next
                </button>
              </div>
            </>
          </>
        )}
      </div>

      {/* remove student modal  */}

      <div
        className="modal fade"
        id="removeStuModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h5>Do you want to remove?</h5>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-info mx-2"
                  onClick={handleRemoveStu}
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
                <button className="btn btn-info" data-bs-dismiss="modal">
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* certification status modal  */}

      <div
        className="modal fade"
        id="certificateModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h5>Update Certification Status</h5>
              <form onSubmit={UpdateCertStatus}>
                <div className="row">
                  <div className="col-12 col-sm-6 my-2">
                    <label htmlFor="">Certification Status:</label>
                    <select
                      className="form-select"
                      name="UserRole"
                      onChange={(e) => setCertStatus(e.target.value)}
                      aria-label="Gender"
                      value={CertStatus}
                    >
                      <option value="" selected>
                        Select Status
                      </option>
                      <option value="U">Unsent</option>
                      <option value="S">Sent</option>
                    </select>
                    <button
                      className="btn btn-info mt-3"
                      data-bs-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* model of module  */}

      <div
        className="modal fade"
        id="modules"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h5>List of modules</h5>
              <ul>
                {ModuleList.length === 0
                  ? "Add Modules"
                  : ModuleList.map((module, i) => <li key={i}>{module}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
