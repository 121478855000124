import React from "react";
import { Link, useHistory } from "react-router-dom";
import "../style/topbar.css"
import Avatar from '@mui/material/Avatar';
import { deepOrange,blue } from '@mui/material/colors';

import { useCookies } from 'react-cookie';
import axios from "axios";

export default function Topbar({ topBarHeading }) {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  let history = useHistory();
  var name = JSON.parse(localStorage.getItem("name"));

  const clearAllCookies = () => {
    Object.keys(cookies).forEach((cookieName) => {
      removeCookie(cookieName);
    });
  };

  const Logout = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    const userId = JSON.parse(localStorage.getItem("Userid"));

      localStorage.removeItem("username");
      localStorage.removeItem("refresh");
      localStorage.removeItem("access");
      localStorage.removeItem("Userid");
      localStorage.removeItem("name");
      localStorage.removeItem("user_group");
      localStorage.removeItem("profile_state");
      clearAllCookies()
      history.push("/");

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("id", userId);
    const { data } = await axios.post("/users/signout/",formdata);
    // if(data.status=="0x0"){      
    // }
  };

  return (
      <div className="topBar">
        <div className="container d-flex justify-content-end align-items-center topbarContent">
          <Avatar src="/broken-image.jpg"
          sx={{ bgcolor: blue[200], width:"30px", height:"30px" }}
          /> <span className="ms-1">{name}</span><span className="ms-1">/<Link className=" topbarActions" to="/ResetPass" ><span className="ms-1">Change Password</span></Link> / <span className="topbarActions" onClick={Logout}>Logout</span></span>
        </div>

      </div>
  );
}
