import axios from 'axios';
import React, { useEffect, useState } from 'react'
import "../../style/assignment.css"
import Topbar from '../Topbar'
// images 
import handArrow from "../../images/downHand.png";
import assignmentImg from "../../images/assignmentImg.png";

export default function Assignment() {
  const [MyBatches, setMyBatches] = useState([])

  const getMyBatches=async()=>{
    const userId = JSON.parse(localStorage.getItem("Userid"));
    const {data}=await axios.get(`/course/batch/student?stu_id=${userId}`)
    if (data.data.status="0x0") {
      setMyBatches(data.data.batchData)
    }
  }

  useEffect(() => {
    const userGrp = JSON.parse(localStorage.getItem("user_group"));
    if (userGrp.includes("G1Student")) {
      getMyBatches()
    }
  }, [])
  
  return (
    <div>
        {/* <Topbar topBarHeading={"Assignment"} /> */}
        <h4 className="ms-5 ps-5">Upload Assignment</h4>
        <div className="container">
          {
            MyBatches?.length>=1
            ? <>
             <div className="row mt-5">
          <div className="col-lg-6 col-12  ">
            <div className="">
              <img src={assignmentImg} alt="recordImg" className='AssImg' width="80%" />
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-4 mt-lg-0 d-flex justify-content-center ">
          {
            MyBatches?.map((batch,i)=>(
            <div className="w-100 d-flex justify-content-evenly align-items-center flex-column">
              <div>
              <p className='text-center'>Your Batch ID: <span className='batchName'>{batch?.user_batch_id}</span></p>
              </div>
              <div className="">
                <img src={handArrow} alt="handArrow" width="80" />
              </div>
              <div>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEw5qisTe_JsKhLhZvaF2hE5aCy-j-jeaY0aydxPBpmDOsFA/viewform" target="_blank">
                  <button className="accessRecordingBtn btn">Upload Your Assignment</button>
                </a>
              </div>
            </div>
))
}
          </div>
        </div>
             {/* {
            MyBatches?.map((batch,i)=>(
              <div className='my-4'>
              <h4 className='text-center'>Batch ID: {batch?.user_batch_id}</h4>
              <div className="d-flex justify-content-between mt-4 w-75">
              <h5>Upload Your Assignments </h5>
              <a href={batch?.assignment_link} target="_blank">
                <button className='btn btn-light'>Click Here</button>
              </a>
            </div>
              <hr />
              </div>
            ))
          } */}
            </>
            : <h4 className='text-center my-5'>Please... Enroll Course & Batch.</h4>
          }
         
        </div>
    </div>  
)
}
