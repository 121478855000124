import React, { useEffect, useState } from "react";
import "../style/stuInfo.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  // border: 0px solid gray,
  boxShadow: 4,
  p: 4,
};

export default function StuInfo({viewStuInfo}) {
  // const userProfState = ;
  const [open, setOpen] = React.useState(
    (JSON.parse(localStorage.getItem("user_group").includes("G1Student"))
    &&JSON.parse(localStorage.getItem("user_group")).length === 1
    &&JSON.parse(localStorage.getItem("profile_state"))=="I"
        ? true
        : false
    )
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [moduleList, setmoduleList] = useState();
  const [Education, setEducation] = useState("");
  const [YearsOfExp, setYearsOfExp] = useState(0);
  const [City, setCity] = useState("");
  const [LinkedInUrl, setLinkedInUrl] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Profession, setProfession] = useState("NA");
  const [EmploymentStatus, setEmploymentStatus] = useState("Student");
  const [LanguagesKnown, setLanguagesKnown] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [ProfiePic, setProfiePic] = useState("");
  const [Resume, setResume] = useState("")
  let history = useHistory();

  const Logout = async () => {
    localStorage.removeItem("username");
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    localStorage.removeItem("Userid");
    localStorage.removeItem("name");
    localStorage.removeItem("user_group");
    localStorage.removeItem("profile_state");
    history.push("/");
    
  };

  const getStuProf = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    const userId = JSON.parse(localStorage.getItem("Userid"));

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    const { data } = await axios.get(`/users/student/profile/?id=${userId}`);
    if (data.status == "0x0") {
      setCity(data.data.userData[0].current_city);
      setEducation(data.data.userData[0].education);
      setLinkedInUrl(data.data.userData[0].linkedin_url);
      setLinkedInUrl(data.data.userData[0].linkedin_url);
      // setLanguagesKnown(data.data.userData[0].linkedin_url);
      setProfiePic(data.data.userData[0].profile_pic);
    }
  };

  const handeStuInfo = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("access"));
    const userId = JSON.parse(localStorage.getItem("Userid"));

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("user_id", userId);
    formdata.append("education", Education);
    formdata.append("years_of_exp", YearsOfExp);
    formdata.append("current_city", City);
    formdata.append("linkedin_url", LinkedInUrl);
    formdata.append("industry", Industry);
    formdata.append("designation", Designation);
    formdata.append("profession", Profession);
    formdata.append("employment_status", EmploymentStatus);
    formdata.append("languages_known", LanguagesKnown);
    formdata.append("current_organization", LanguagesKnown);
    formdata.append("profile_state", "C");
    formdata.append("profile_pic", ProfiePic);
    formdata.append("resume", Resume);

    // const { data } = await axios.put(`/users/student/profile/v2/`, formdata);
    const { data } = await axios.put(`/users/student/dashboard/profile`, formdata);
    if (data.status == "0x0") {
      setOpen(false);
    }
  };

  useEffect(() => {
    getStuProf();
  }, []);

  useEffect(() => {
    viewStuInfo(open)
  }, [open]);


  return (
    <div>
      {/* model of module  */}
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: false }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="stu-main-wrapper">
              <div className="container">
                <div className="d-flex justify-content-end">
                  <button className=" ms-auto btn btn-sm popUp-logout-btn" onClick={Logout}>Logout</button>
                </div>
                <form onSubmit={handeStuInfo}>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="stu-heading">Student Information</h3>
                      <p>*Mandatory Fields</p>
                    </div>
                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">*Education</label>
                      <input
                        type="text"
                        onChange={(e) => setEducation(e.target.value)}
                        value={Education}
                        className="form-control"
                        placeholder="Education"
                        required
                      />
                    </div>
                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">*LinkedIn URL</label>
                      <input
                        type="text"
                        onChange={(e) => setLinkedInUrl(e.target.value)}
                        value={LinkedInUrl}
                        className="form-control"
                        placeholder="LinkedIn URL"
                        required
                      />
                    </div>
                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">*City</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setCity(e.target.value)}
                        value={City}
                        placeholder="City"
                        required
                      />
                    </div>
                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">*Languages Known</label>
                      <input
                        type="text"
                        onChange={(e) => setLanguagesKnown(e.target.value)}
                        value={LanguagesKnown}
                        className="form-control"
                        placeholder="Languages Known"
                        required
                      />
                    </div>
                    
                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">Profile Picture</label>
                      <div className="d-flex align-items-center profileWrapper border">
                      <input
                        type="file"
                        onChange={(e)=>setProfiePic(e.target.files[0])}
                        // value={ProfiePic}
                        className="form-control profileInput"
                        placeholder="Profile Picture"
                      />
                       {/* <img src={ProfiePic} alt="ProfiePic" width="30" height="30" className="profileImg m-1" /> */}
                      {/* URL.createObjectURL( */}
                      </div>
                    </div>

                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">Upload Resume</label>
                      <div className="d-flex align-items-center profileWrapper border">
                      <input
                        type="file"
                        onChange={(e)=>setResume(e.target.files[0])}
                        // value={ProfiePic}
                        className="form-control profileInput"
                        placeholder="Upload Resume"
                      />
                      </div>
                    </div>

                    <div className="col-sm-4 col-12 mt-3">
                      <label htmlFor="">*Employment Status</label>
                      <select
                        className="form-select"
                        name="UserRole"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                        value={EmploymentStatus}
                        aria-label="Default select example"
                        required
                      >
                        <option>Select Employment status</option>
                        <option value="Working Professional">Working Professional</option>
                        <option value="Student">Student</option>
                        <option value="Self Employed">Self Employed</option>
                        <option value="business">Business Owner</option>
                      </select>
                    </div>
                    {EmploymentStatus == "Self Employed" && (
                      <div className="col-sm-4 col-12 mt-3">
                        <label htmlFor="">*Industry</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setIndustry(e.target.value)}
                          value={Industry}
                          aria-label="Default select example"
                          required
                        >
                          <option>Type of Industry</option>
                          <option value="IT">IT</option>
                          <option value="Non-IT">Non-IT</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    )}
                    {EmploymentStatus == "Self Employed" && (
                      <div className="col-sm-4 col-12 mt-3">
                        <label htmlFor="">*Years Of Experiance</label>
                        <input
                          type="text"
                          onChange={(e) => setYearsOfExp(e.target.value)}
                          value={YearsOfExp}
                          className="form-control"
                          placeholder="Years Of Experiance"
                          required
                        />
                      </div>
                    )}
                    {EmploymentStatus == "Self Employed" && (
                      <div className="col-sm-4 col-12 mt-3">
                        <label htmlFor="">*Company Name</label>
                        <input
                          type="text"
                          onChange={(e) => setCompanyName(e.target.value)}
                          value={CompanyName}
                          className="form-control"
                          placeholder="Company Name"
                          required
                        />
                      </div>
                    )}
                    {EmploymentStatus == "Self Employed" && (
                      <div className="col-sm-4 col-12 mt-3">
                        <label htmlFor="">*Designation</label>
                        <input
                          type="text"
                          onChange={(e) => setDesignation(e.target.value)}
                          value={Designation}
                          className="form-control"
                          placeholder="Designation"
                          required
                        />
                      </div>
                    )}
                    <div className="col-12 mt-5 d-flex justify-content-center ">
                      <button className="btn stuSubmitBtn">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}
