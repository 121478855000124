import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"
import { useDispatch } from "react-redux"
import { RefresToken } from "./RefreshToken"
import { useEffect } from "react"




export const fetchCompanyList= createAsyncThunk("/CompanyList", async(pageNo)=>{

    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    try {

        const resp=await axios.get(`/course/placement/list?page=${pageNo}`)
        return resp.data

    } catch (error) {
        throw new Error(error.message)
    }
})

const companyListSlice=createSlice({
    name:"company List",
    initialState:{
        data:null,
        loading:false,
        error:null
    },
    reducer:{},
    extraReducers:(builder)=>{
        builder
        .addCase(fetchCompanyList.pending, (state)=>{
            state.loading=true;
            state.error=null;
        })
        .addCase(fetchCompanyList.fulfilled, (state,action)=>{
            state.loading=false;
            state.data=action.payload;
        })
        .addCase(fetchCompanyList.rejected, (state,action)=>{
            state.loading= false;
            state.error= action.error.message;
        })
    }
})

export default companyListSlice.reducer