import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { OnboardCompany } from '../slices/companyOnboarding'

export default function CompanyOnboard() {
    const [CompanyName, setCompanyName] = useState("")
    const [NameOfConcernPerson, setNameOfConcernPerson] = useState("")
    const [Phone, setPhone] = useState()
    const [Email, setEmail] = useState("")
    const [Position, setPosition] = useState("")
    const [JD, setJD] = useState("")
    const [Location, setLocation] = useState("")
    const [NoOfOpenings, setNoOfOpenings] = useState(0)
    const [Ctc, setCtc] = useState(0)
    const [Experiance, setExperiance] = useState(0)
    const [catagory, setcatagory] = useState("")
    const [Profile, setProfile] = useState("")
    const [Skills, setSkills] = useState("")
    const [ContactedBy, setContactedBy] = useState("")
    const [StuInfo, setStuInfo] = useState("")

    const dispatch= useDispatch()

    const resetAll=()=>{
      alert("Onboarding successfull..!!!")
      setCompanyName("")
      setNameOfConcernPerson("")
      setPhone("")
      setEmail("")
      setPosition("")
      setJD("")
      setLocation("")
      setNoOfOpenings("")
      setCtc("")
      setExperiance("")
      setcatagory("")
      setProfile("")
      setSkills("")
      setContactedBy("")
    }
    
    const handleAddStu =(e)=>{
      e.preventDefault()
      let date={
            CompanyName,
            NameOfConcernPerson,
            Phone,
            Email,
            Position,
            JD,
            Location,
            NoOfOpenings,
            Ctc,
            Experiance,
            catagory,
            Profile,
            Skills,
            ContactedBy,
      }
      dispatch(OnboardCompany(date)).then(()=>{resetAll()})
    }

  return (
    <div>
        <h4 className="ms-5 ps-5">Company Onboarding</h4>
      <div className="row">
        <div className="col-10 offset-1 mb-4">
          <form onSubmit={handleAddStu}>
            <div className="row py-4">
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Company Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={CompanyName}
                  placeholder="CompanyName"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Name Of Concerned Person:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setNameOfConcernPerson(e.target.value)}
                  value={NameOfConcernPerson}
                  placeholder="NameOfConcernPerson"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Phone No.:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setPhone(e.target.value)}
                  value={Phone}
                  placeholder="Phone No."
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Email Id:</label>
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  value={Email}
                  placeholder="Email"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Position:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setPosition(e.target.value)}
                  value={Position}
                  placeholder="Position"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Job Description:</label>
                <textarea 
                  className="form-control"
                  onChange={(e) => setJD(e.target.value)}
                  value={JD}
                  placeholder="Position"
                  name="" id="" cols="30" rows="1" required></textarea>
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Location:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setLocation(e.target.value)}
                  value={Location}
                  placeholder="Location"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">CTC in LPA:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setCtc(e.target.value)}
                  value={Ctc}
                  placeholder="CTC in LPA"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Experiance Required:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setExperiance(e.target.value)}
                  value={Experiance}
                  placeholder="Experiance Required"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">No Of Openings:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setNoOfOpenings(e.target.value)}
                  value={NoOfOpenings}
                  placeholder="NoOfOpenings"
                  required
                />
              </div>
             
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Category:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setcatagory(e.target.value)}
                  value={catagory}
                  placeholder="Category"
                  required
                >
                  <option value="" selected>Select Category</option>
                  <option value="IT">IT</option>
                  <option value="Finance">Finance</option>
                  <option value="Sales">Sales</option>
                </select>
              </div>
              
              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Profile:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setProfile(e.target.value)}
                  value={Profile}
                  placeholder="Profile"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Skills:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setSkills(e.target.value)}
                  value={Skills}
                  placeholder="Skills"
                  required
                />
              </div>

              <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Contacted By:</label>
                <select
                  className="form-select"
                  name="UserRole"
                  onChange={(e) => setContactedBy(e.target.value)}
                  value={ContactedBy}
                  placeholder="ContactedBy"
                  required
                >
                  <option value="" selected>Select Contact Person</option>
                  <option value="Sanchita">Sanchita</option>
                  <option value="Vasu">Vasu</option>
                </select>
              </div>

              <div className="d-flex justify-content-end my-2">
                <button type="submit" className="btn btn-primary ms-2">Add Company</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
