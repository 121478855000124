import axios from "axios";
import React, { useEffect, useState } from "react";
import { saveAs } from 'file-saver';
import "../../style/stuList.css";
import { Link } from "react-router-dom";
// material ui
import BlockIcon from "@mui/icons-material/Block";
import CircularProgress from "@mui/joy/CircularProgress";
import SearchBar from "material-ui-search-bar";
import Snackbar from "@mui/material/Snackbar";
import StuView from "../Support/StuView";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { render } from "@testing-library/react";
// import BatchStuList from "./BatchStuList";
import CopyToClipboardButton from "../CopyToCliboard";

import Backdrop from "@mui/material/Backdrop";
// import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import Module from "../Support/Module";
// import EditModule from "./EditModule";
// import { DataGrid } from '@mui/x-data-grid';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function MockStuList() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Username, setUsername] = useState("");
  const [ProfileState, setProfileState] = useState("I");
  const [stuStatus, setstuStatus] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [RegAmmt, setRegAmmt] = useState("");
  const [Education, setEducation] = useState("");
  const [Gender, setGender] = useState("");
  const [yrsOfGraduation, setyrsOfGraduation] = useState("");
  const [yrsOfExp, setyrsOfExp] = useState(0);

  const [TrainerName, setTrainerName] = useState("");
  const [PitchedAmount, setPitchedAmount] = useState("");
  const [CourseFee, setCourseFee] = useState("");
  const [NoOfInstallment, setNoOfInstallment] = useState("");
  const [InstallmentDetail, setInstallmentDetail] = useState("");
  const [PaymentDate, setPaymentDate] = useState("");
  const [ClousererName, setClousererName] = useState("");
  const [FinalFee, setFinalFee] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [AllStuList, setAllStuList] = useState([]);
  const [deleteId, setdeleteId] = useState("");
  const [EditId, setEditId] = useState("");
  const [City, setCity] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [Industry, setIndustry] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Discount, setDiscount] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [UserData, setUserData] = useState();
  const [CourseId, setCourseId] = useState("");
  const [BatchesList, setBatchesList] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [ListUpdate, setListUpdate] = useState(true);
  const [Modal, setModal] = useState();
  const [ProfilePic, setProfilePic] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [TotalCount, setTotalCount] = useState();
  const [ListCount, setListCount] = useState(0);
  const [listNo, setlistNo] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [Search, setSearch] = useState("");
  const [open, setOpen] = React.useState(false);
  const [Installment1, setInstallment1] = useState();
  const [Installment1Status, setInstallment1Status] = useState();
  const [Installment2, setInstallment2] = useState();
  const [Installment2Status, setInstallment2Status] = useState();
  const [Installment3, setInstallment3] = useState();
  const [Installment3Status, setInstallment3Status] = useState();
  const [PaymentStatus, setPaymentStatus] = useState("");
  const [ModuleName, setModuleName] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [View, setView] = useState("stuList");
  const [StuId, setStuId] = useState("");
  const [check, setCheck] = useState(null);
  const [clouser, setClouser] = useState({});
  const [course, setCourse] = useState({});
  const [IsFiltersActive, setIsFiltersActive] = useState(false);
  const [SortByBatch, setSortByBatch] = useState(false);
  const userGrp = JSON.parse(localStorage.getItem("user_group"));
  const name = JSON.parse(localStorage.getItem("name"));
  const [SalesUser, setSalesUser] = useState(
    userGrp.includes("G1Sales") && userGrp.length == 1 ? [name] : []
  );

  const [InstallMentStructure, setInstallMentStructure] = useState("");
  const [WelcomeStatus, setWelcomeStatus] = useState("");
  const [FilterBatch, setFilterBatch] = useState("");
  const [sort, setSort] = useState("");
  const [CourseTitle, setCourseTitle] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [AllModuleList, setAllModuleList] = useState([]);
  const [CheckedModules, setCheckedModules] = useState([]);
  const [Checked, setChecked] = useState({});
  const [SelectedModules, setSelectedModules] = useState([]);
  const [feeBal, setfeeBal] = useState(0);
  const [AllowMock, setAllowMock] = useState();
  const [Score, setScore] = useState(0);
  const [placementStatus, setPlacementStatus]=useState("True");
  const [courseCompletionStatus,setCourseCompletionStatus]=useState();
  const [certStatus,setCertStatus]=useState()
  const [stuResume, setStuResume] = useState("")
  const [Comments, setComments] = useState("")
  const [InterviewStatus, setInterviewStatus] = useState("")

  const status = ["A", "R", "D", "F", "O", ""];

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handleGetAllStu = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `/users/mock/student_list?page=${pageNo}&sales_lead_info__remaining_amount=0&stud_info__mock_interview_status=true`
    );

    if (data.status == "0x0") {
      setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 10);
      setIsLoading(false);
      setSortByBatch(false);
    }
  };

  const ShowAllData = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/users/student/create_payment`);

    if (data.status == "0x0") {
      // setAllStuList(data?.data?.student_list);
      // setTotalCount(data?.data?.totalCount);
      setlistNo(0);
      setIsLoading(false);
      setSortByBatch(false);
    }
  };

  const GetAllStuByBatchEnroll = async () => {
    setIsLoading(true);
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `/users/student/create_payment/list/v1_1?page=${pageNo}`
    );
    if (data.status == "0x0") {
      // setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 10);
      setIsLoading(false);
      setSortByBatch(true);
    }
  };

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 10);
    }
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 10);
    }
  };

    useEffect(() => {
      if (IsFiltersActive) {
        createFilter();
      } else {
        SortByBatch ? GetAllStuByBatchEnroll() : handleGetAllStu();
      }
    }, [pageNo]);

  useEffect(() => {
    handleGetAllStu();
  }, []);

  useEffect(() => {
    setpageNo(1);
    setlistNo(0);
    // handleGetAllStu();
  }, [FilterBatch]);

  const asignBatch = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();
    formdata.append("batch_id", BatchId);
    formdata.append("add_students", EditId);
    // const { data } = await axios.put("/course/batch/enroll", formdata);
    const { data } = await axios.put("/course/batch/enroll/v1_1", formdata);
    if (data.status == "0x0") {
      setModal(true);
      //   handleGetAllStu();
      setModal(false);
      setOpen(true);
      setTimeout(() => setOpen(false), 5000);
    }
  };

  const getModuleList = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `/course/module/course?course=${CourseId}`
    );

    // const { data } = await axios.get(
    //   `/course/module/list/v1_1?course_info=${CourseId}`
    // );

    if (data.status == "0x0") {
      setAllModuleList(data.data.batchData);
    }
  };

 const UpdateMockStu = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("payment_id", EditId);
    formdata.append("grade", Score);
    formdata.append("interview_status", InterviewStatus);
    formdata.append("comments", Comments);

    const { data } = await axios.put("/users/mock/", formdata);

    if (data.status == "0x0") {
      handleGetAllStu();
    }

  };

  const getStuProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(
      `/users/student/create_payment?payment_id=${EditId}`
    );

    if (data.status == "0x0") {
     
      setUserData(data?.data?.userData);
      setFirstName(data?.data?.userData.first_name);
      setLastName(data?.data?.userData.last_name);
      setEmail(data?.data?.userData.username);
      setUsername(data?.data?.userData.username);
      setProfileState(data?.data?.userData.profile_state);
      setstuStatus(data?.data?.userData.student_status);
      setGender(data?.data?.userData.gender);
      setPhoneNo(data?.data?.userData.phone_numbers);
      setEducation(data?.data?.userData.education);
      setyrsOfGraduation(data?.data?.userData.yr_of_graduation);
      setyrsOfExp(data?.data?.userData.yrs_of_exp);
      setCity(data?.data?.userData.current_city);
      setLinkedIn(data?.data?.userData.linkedin_url);
      // setEmail(data?.data?.userData.email)
      setIndustry(data?.data?.userData.industry);
      setDesignation(data?.data?.userData.designation);
      setClousererName(data?.data?.userData.counsellor);
      // setEmail(data?.data?.userData.payment_date)
      setPaymentDate(data?.data?.userData.payment_date);
      setRegAmmt(data?.data?.userData.registration_amount);
      setPitchedAmount(data?.data?.userData.pitched_amount);
      setDiscount(data?.data?.userData.discount);
      setFinalFee(data?.data?.userData.final_amount);
      setPaymentDate(data?.data?.userData.payment_date);
      setRegAmmt(data?.data?.userData.registration_amount);
      // setFinalFee(data?.data?.userData.final_amount);
      setInstallment1(data?.data?.userData.installment1);
      setInstallment2(data?.data?.userData.installment2);
      setInstallment3(data?.data?.userData.installment3);
      setPaymentType(data?.data?.userData.payment_type);
      setPaymentStatus(data?.data?.userData.payment_status);
      setInstallment1Status(data?.data?.userData.installment1_status);
      setInstallment2Status(data?.data?.userData.installment2_status);
      setInstallment3Status(data?.data?.userData.installment3_status);
      setInstallMentStructure(data?.data?.userData.installment_structure);
      setWelcomeStatus(data?.data?.userData.welcome_call_status);
      // setAllModuleList(data?.data?.userData.modules)
      setChecked(data?.data?.userData.modules_id);
      setSelectedModules(data?.data?.userData.modules_id);
      setAllowMock(data?.data?.userData.mock_interview_status?"True":"False");
      setPlacementStatus(data?.data?.userData.placement_eligibility_status?"True":"False");
      setCertStatus(data?.data?.userData.certification_status?"True":"False");
      setCourseCompletionStatus(data?.data?.userData.course_completion_status?"True":"False");
      setStuResume(data?.data?.userData.resume);
      setComments(data?.data?.userData.comments);
      setInterviewStatus(data?.data?.userData.interview_status);
      setScore(data?.data?.userData.grade)
      setBatchId("");
      setCourseId("");
      // setCourseList([])
      setBatchesList([]);
    }
  };

  const handleEditStu = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();
    formdata.append("first_name", FirstName);
    formdata.append("last_name", LastName);
    formdata.append("email", Email);
    formdata.append("username", Email);
    formdata.append("profile_state", ProfileState);
    formdata.append("gender", Gender);
    formdata.append("phone_numbers", PhoneNo);
    formdata.append("education", Education);
    formdata.append("year_of_graduation", yrsOfGraduation);
    formdata.append("years_of_exp", yrsOfExp);
    formdata.append("current_city", City);
    formdata.append("linkedin_url", LinkedIn);
    // formdata.append("entry", "2023-2-24");
    formdata.append("industry", Industry);
    formdata.append("designation", Designation);
    formdata.append("counsellor", ClousererName);
    formdata.append("student_status", stuStatus);
    // formdata.append("profilePic", "");
    formdata.append("payment_id", EditId);
    formdata.append("payment_date", PaymentDate);
    formdata.append("registration_amount", RegAmmt);
    formdata.append("final_amount", FinalFee);
    formdata.append("installment1", Installment1);
    formdata.append("installment2", Installment2);
    formdata.append("installment3", Installment3);
    formdata.append("payment_type", PaymentType);
    formdata.append("payment_status", PaymentStatus);
    // formdata.append("batch_id", "");
    formdata.append("installment1_status", Installment1Status);
    formdata.append("installment2_status", Installment2Status);
    formdata.append("installment3_status", Installment3Status);
    formdata.append("installment_structure", InstallMentStructure);
    formdata.append("welcome_call_status", WelcomeStatus);
    formdata.append("add_modules", JSON.stringify(SelectedModules));
    formdata.append("course_completion_status", courseCompletionStatus);
    formdata.append("certification_status", certStatus);
    formdata.append("placement_eligibility_status", "True");

    const { data } = await axios.put(
      "/users/student/create_payment/v1_2",
      formdata
    );

    if (BatchId != "") {
      asignBatch();
    }

    if (data.status == "0x0") {
      handleGetAllStu();
    }
  };

  const handleGetAllCourse = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    // const { data } = await axios.get("/course/course/list");

    const { data } = await axios.get("/course/course/");
    setCourseList(data?.data?.courseDetails);
  };

  const GetAllBatchesByCourse = async () => {
    var formdata = new FormData();

    const { data } = await axios.get(`/course/batch/course?course=${CourseId}`);
    setBatchesList(data.data.batchData);
  };

  useEffect(() => {
    if (CourseId != "") {
      GetAllBatchesByCourse();
      getModuleList();
    }
  }, [CourseId]);

  useEffect(() => {
    getStuProfile();
    // handleGetAllCourse();
  }, [EditId]);

  const handleSearch = async () => {
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    // const { data } = await axios.get(`/users/student/list/?search=${Search}`);
    // const { data } = await axios.get(
    //   `/users/student/create_payment/list?search=${Search}&sort=${sort}`
    // );

    const { data } = await axios.get(
      `/users/student/create_payment/list?page=${pageNo}&search=${Search}&counsellor=${JSON.stringify(
        SalesUser
      )}&course=${JSON.stringify(CourseTitle)}&sort=${sort}`
    );

    if (data.status == "0x0") {
      // setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 10);
      // setIsLoading(false)
    }
  };

  useEffect(() => {
    handleSearch();
  }, [Search]);

  const handleView = (id) => {
    setStuId(id);
    setView("StuView");
  };

  const clousers = [
    "Gaurav",
    "Shubhankar",
    "Tanya",
    "Rahul",
    "Shreyas",
    "Prem",
    "Aman",
    "Himanshu",
    "Renuka",
    "Sourav",
    "Jayesh",
    "Trapti",
    "Parul",
    "Nirmal",
    "Shrutika",
  ];

  const handleClouserFilter = (event) => {
    const testClouser = {
      ...clouser,
      [event.target.name]: event.target.checked,
    };
    setClouser(testClouser);
  };

  const handleCourseFilter = (event) => {
    const testCourse = {
      ...course,
      [event.target.name]: event.target.checked,
    };
    setCourse(testCourse);
  };

  const createFilter = async () => {
    // e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const Clouser = [];
    Object.entries(clouser).map((key, value) => {
      if (key[1]) Clouser.push(key[0]);
    });

    const Course = [];
    Object.entries(course).map((key, value) => {
      if (key[1]) Course.push(key[0]);
    });
    const { data } = await axios.get(
      `/users/student/create_payment/list?course=${JSON.stringify(
        Course
      )}&counsellor=${JSON.stringify(Clouser)}&page=${pageNo}&sort=${sort}`
    );

    // const { data } = await axios.get(
    //   `/users/student/filter/course/?course_name=${JSON.stringify(
    //     Course
    //   )}&counsellor=${JSON.stringify(Clouser)}&page=${pageNo}&sort=${sort}`
    // );

    if (data.status == "0x0") {
      // setAllStuList(data?.data?.student_list.results);
      setTotalCount(data?.data?.student_list.count);
      setListCount(data?.data?.student_list.count / 10);
      setIsLoading(false);
      if (Clouser.length == 0 && Course == 0) {
        setIsFiltersActive(false);
      } else {
        setIsFiltersActive(true);
      }
    }
    // if (data.status == "0x0") {
    //   setAllStuList(data?.data?.userData.results);
    //   setTotalCount(data?.data?.userData.count);
    //   setListCount(data?.data?.userData.count / 10);
    //   setIsLoading(false);
    //   if (Clouser.length == 0 && Course == 0) {
    //     setIsFiltersActive(false);
    //   } else {
    //     setIsFiltersActive(true);
    //   }
    // }
  };

  const handleChange = (id, name) => (event) => {
    // const test = {
    //   ...Checked,
    //   [id]: event.target.checked,
    // };
    // setChecked(test);

    if (event.target.checked) {
      setSelectedModules((prevSelected) => [...prevSelected, id]);
    } else {
      setSelectedModules((prevSelected) =>
        prevSelected.filter((moduleId) => moduleId !== id)
      );
    }
  };

// Download Resume

const handleDownload = (resume) => {
  if(resume != null && resume != ""){
    saveAs(resume, 'resume.pdf');
    }else if(resume == null){
    alert("CV Not Uploaded")
  }
};


  return (
    <div className="">
      {/* ============ student List ============*/}
      <Snackbar
        open={open}
        className="w-75"
        message="Batch Enrolled successfully...!!!"
        sx={{
          bgcolor: "info",
        }}
      />
      
      <h4 className="mb-4">Mock Eligible Students List</h4>

      {IsLoading ? (
        <div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <>
          {View == "stuList" ? (
            <>
              {/* <div className="row my-3 ms-3">
                <div className="col-10">
                  <SearchBar
                    value={Search}
                    onChange={(newValue) => setSearch(newValue)}
                    onCancelSearch={(newValue) => setSearch("")}
                    style={{ padding: "-10px" }}
                  />
                </div>

                <div className="col-2">
                  <button
                    className="btn btn-info btn-lg"
                    data-bs-target="#ClouserFilterModal"
                    data-bs-toggle="modal"
                  >
                    Filters <i className="ms-1 bi bi-funnel"></i>
                  </button>
                </div>
              </div> */}

              <div className="table table-responsive mt-2">
                <table className="table table-striped custom-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th className=" supportTable "><p>Sr. No.</p></th>
                      <th className="supportTable">
                        {/* <div className="dropdown "> */}
                          <p
                            className="dropdown-toggle "
                            role="button"
                            // data-bs-toggle="dropdown"
                            // aria-expanded="false"
                          >
                            Name
                          </p>
                          {/* <ul className="dropdown-menu">
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={GetAllStuByBatchEnroll}
                            >
                              <span className="dropdown-item">
                                Sort By Batch Enroll
                              </span>
                            </li>
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={handleGetAllStu}
                            >
                              <span className="dropdown-item">
                                Sort By Recently Added
                              </span>
                            </li>
                          </ul> */}
                        {/* </div> */}
                      </th>

                      <th className="supportTable">
                        <p className="supportTable">Email (Username)</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Phone No.</p>
                      </th>

                      <th className="supportTable">
                        <p className="supportTable">Course</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Score</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Comment</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">Interview Status</p>
                      </th>

                      <th className="supportTable">
                        <p className="supportTable">Join Meeting</p>
                      </th>
                
                      <th className="supportTable">
                        <p className="supportTable">Allow Placement</p>
                      </th>
                      <th className="supportTable">
                        <p className="supportTable">CV</p>
                      </th>

                      <th className="supportTable">
                        <p className="supportTable">Actions</p>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {AllStuList.length == 0 ? (
                      <h4 className="text-center my-2">No Results Found</h4>
                    ) : (
                      <>
                        {AllStuList.map((item, i) => (
                          <tr key={i} className="p-0 m-0">

                            <td className="supportTable">
                              <p className="supportTable"> {i + 1 + listNo} </p>
                            </td>

                            <td className="">
                              <p className="supportTable">{item?.first_name} {item?.last_name}</p>
                            </td>

                            <td>{item?.username}</td>
                            <td>{item?.phone_numbers}</td>
                            <td>
                              {item?.course == null ? "----" : item?.course}
                            </td>
                            <td>{item?.grade}</td>
                            
                            <td>
                              {(item?.comments?.length>25)?(item?.comments.slice(0,25) + "..."):(item?.comments)}
                            </td>
                            <td>
                              {item?.interview_status=="N"?"Not Attended":"Attended"}
                            </td>
                            <td>
                              <a href={item?.interview_link} className="btn btn-primary btn-sm" target="_blank">Join Meet</a>
                            </td>
                            <td className="">
                              <button
                                className={item.placement_eligibility_status
                                  ? "btn btn-success btn-sm px-1":"btn btn-info btn-sm px-2"}
                                  onClick={() => setEditId(item.payment_id)}
                                data-bs-target="#allowPlacementModal"
                                data-bs-toggle="modal"
                              >
                               {item.placement_eligibility_status? "Allowed" :"Allow"}
                              </button>
                            </td>
                            <td className="">
                              <button className="btn btn-outline-info btn-sm mx-1"  onClick={() => handleDownload(item.resume)}>
                                Download CV
                              </button>
                            </td>

                              <td className=" ">
                                <button
                                  className="btn btn-outline-info btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#editStuModal"
                                  onClick={() => setEditId(item.payment_id)}
                                  >
                                  <i className="bi bi-pencil-square"></i>       
                                </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {/*============= pagination ============= */}

              <div className="container d-flex justify-content-between my-5">
                <button className="btn btn-info mb-5" onClick={handlePrevPage}>
                  Prev
                </button>
                <div>
                  <button
                    className="btn btn-info mb-5"
                    onClick={handleNextPage}
                  >
                    Next
                  </button>
                 
                </div>
              </div>
            </>
          ) : (
            <>
              <h2
                style={{ cursor: "pointer" }}
                onClick={() => setView("stuList")}
              >
                <i className="bi bi-arrow-left-circle-fill"></i>
              </h2>
              <StuView StuId={StuId} />
            </>
          )}
        </>
      )}

      {/* ============ edit student =========== */}

      <div
        className="modal fade"
        id="allowPlacementModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            
            <div className="modal-body">
            <h5>Do You Want To Allow this Student for Placement ? </h5>
            <div className="w-100 d-flex justify-content-end mt-4">
              <button className="btn btn-outline-dark me-2" data-bs-dismiss="modal">Cancel</button>
              <button className="btn btn-info" onClick={handleEditStu}                 data-bs-dismiss="modal">Allow</button>
            </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============ Add Score =========*/}
      <div
        className="modal fade"
        id="editStuModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Update Student
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
              <div className="col-12 col-sm-6 my-2">
              <label htmlFor="">Enter Score:</label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(e) => setScore(e.target.value)}
                  placeholder="Add Score"
                  value={Score}
                />
                </div>

              

                <div className="col-12 col-sm-6 my-2">
                <label htmlFor="">Interview Status:</label>
                  <select
                    className="form-select"
                    name="UserRole"
                    onChange={(e) => setInterviewStatus(e.target.value)}
                    value={InterviewStatus}
                    placeholder="InterviewStatus"
                  >
                    <option value="">Select Interview Status</option>
                    <option value="A">Attended</option>
                    <option value="N">Not Attended</option>
                  </select>
              </div>
              <div className="col-12 col-sm-6 my-2">
                  <label htmlFor="">Enter Comment:</label>
                  <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="2"
                          className="form-control"
                          onChange={(e) => setComments(e.target.value)}
                          placeholder="Enter Comments"
                          value={Comments}
                        ></textarea>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button
                    className="btn btn-info ms-2"
                    onClick={UpdateMockStu}
                    data-bs-dismiss="modal"
                  >
                    Add
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* filter modal */}

      <div
        className="modal fade"
        id="ClouserFilterModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Apply filters
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-3">
                  <h6>Filter By Clouser</h6>
                  <FormGroup>
                    {clousers.map((clouser, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleClouserFilter}
                            name={clouser}
                          />
                        }
                        label={clouser}
                        key={i}
                      />
                    ))}
                  </FormGroup>
                </div>
                <div className="col-6">
                  <h6>Filter By Course</h6>
                  <FormGroup>
                    {CourseList.map((Course, i) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleCourseFilter}
                            name={Course.title}
                          />
                        }
                        label={Course.title}
                        key={i}
                      />
                    ))}
                  </FormGroup>
                </div>
                <div className="col-3">
                  <h6>Filter By Fees Balance</h6>
                  <select
                    className="form-select"
                    onChange={(e) => setSort(e.target.value)}
                  >
                    <option value="" selected>
                      Select Option
                    </option>
                    <option value="D">Descending</option>
                    <option value="A">Ascending</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-12 my-4 d-flex justify-content-end">
                {/* <button
                  className="btn btn-primary me-2"
                  // data-bs-dismiss="modal"
                  onClick={resetFilters}
                >
                  Reset
                </button> */}

                <button
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={createFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Module ModuleList={ModuleList} />
      {/* module edit*/}
      <div
        class="modal fade"
        id="ModalOfModule"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Module
              </h5>
            </div>

            <div class="modal-body">
              <FormGroup>
                {AllModuleList.map((module, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange(module.id, module.title)}
                        name={module.id}
                        // defaultChecked ={CoachArr.includes(coach.id)?true:false}
                        // checked={CheckedModules[module.id]}
                      />
                    }
                    label={module.title}
                    key={i}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
