import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// material ui
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditBatch from "./EditBatch";
import { render } from "@testing-library/react";
import BatchStuList from "./BatchStuList";
import CircularProgress from "@mui/joy/CircularProgress";
import CopyToClipboardButton from "../CopyToCliboard";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SearchBar from "material-ui-search-bar";

export default function BatchList() {
  const [CourseId, setCourseId] = useState("");
  const [BatchId, setBatchId] = useState("");
  const [Batch_Id_for_Eails, setBatch_Id_for_Eails] = useState();
  const [BatchName, setBatchName] = useState("");
  const [BatchStatus, setBatchStatus] = useState("");
  const [BatchTiming, setBatchTiming] = useState("");
  const [Trainer, setTrainer] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [EditId, setEditId] = useState("");

  const [Coach, setCoach] = useState({});
  const [allBatches, setallBatches] = useState([]);
  const [AllCoaches, setAllCoaches] = useState([]);
  const [CommLink, setCommLink] = useState("");
  const [AssLink, setAssLink] = useState("");
  const [satLink, setsatLink] = useState("");
  const [sunLink, setsunLink] = useState("");
  const [doubtLink, setdoubtLink] = useState("");
  const [CoachArr, setCoachArr] = useState([]);
  const [Edit, setEdit] = useState("");
  const [pageNo, setpageNo] = useState(1);
  const [listNo, setlistNo] = useState(0);
  const [ListCount, setListCount] = useState(0);
  const [Arrow, setArrow] = useState(true);
  const [Checked, setChecked] = useState({});
  const [ViewBatch, setViewBatch] = useState("batchList");
  const [coachesName, setcoachesName] = useState([]);
  const [RemoveBatchId, setRemoveBatchId] = useState("");
  const [IsLoading, setIsLoading] = useState(false);
  const [courseIdForModule, setcourseIdForModule] = useState("")
  const [Allmodules, setAllmodules] = useState([])
  const [CheckedModules, setCheckedModules] = useState([])
  const [endTime, setendTime] = useState("")
  const [FilterBatch, setFilterBatch] = useState("")
  const [emails, setemails] = useState([]);
  const [Search, setSearch] = useState("")
  const [StudentName, setStudentName] = useState("")

const status=["N","S","A","C","F",""]

const handleRefreshToken = async () => {
  var formdata = new FormData();
  formdata.append("username", JSON.parse(localStorage.getItem("username")));
  formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
  formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
  const { data } = await axios.post("/users/token/refresh/", formdata);
  if (data.data.access) {
    localStorage.setItem("access", JSON.stringify(data.data.access));
  }
};

  const getBatches = async () => {
    setIsLoading(true);
    const { data } = await axios.get(`/course/batch/list?page=${pageNo}&batch_status=${FilterBatch}&search=${Search}`);
    if (data.status == "0x0") {
      setallBatches(data.data.batch_list.results);
      setListCount(data.data.batch_list.count / 20);
      setArrow(true);
      setIsLoading(false);
    }
  };

  const getBatch = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/course/batch/?user_batch_id=${BatchId}`);

    if (data.status == "0x0") {
      // setCourseId(data.data.batchData.course_info);
      setEditId(BatchId);
      setBatchName(data.data.batchData.title);
      setBatchStatus(data.data.batchData.batch_status);
      setBatchTiming(data.data.batchData.batch_timing);
      setendTime(data.data.batchData.end_time);
      setTrainer(data.data.batchData.coaches);
      setCourseId(data.data.batchData.course_id);
      setStartDate(data.data.batchData.start_date);
      setsatLink(data.data.batchData.saturday_session_zoom_link);
      setsunLink(data.data.batchData.sunday_session_zoom_link);
      setdoubtLink(data.data.batchData.doubt_solving_session_zoom_link);
      setAssLink(data.data.batchData.assignment_link);
      setCommLink(data.data.batchData.community_link);
      setCoachArr(data.data.batchData.coaches);
      setcourseIdForModule(data.data.batchData.course_id);
      setChecked(
        data.data.batchData.coaches.reduce((a, v) => ({ ...a, [v]: true }), {})
      );
    }
  };

  const handleChange = (id, name) => (event) => {
    const test = {
      ...Checked,
      [id]: event.target.checked,
    };
    setChecked(test);
  };

  const handleModuleChange = (id, name) => (event) => {
    const test = {
      ...CheckedModules,
      [id]: event.target.checked,
    };
    setCheckedModules(test);
  };

  // const handleChange = (event) => {
  //   const test = {
  //     ...Coach,
  //     [event.target.name]: event.target.checked,
  //   };
  //   setCoach(test);
  //   console.log(test);
  //   // setChecked(CoachArr.includes(event.target.name)&&event.target.checked)
  // };

  const cochesId = [];

  Object.entries(Checked).map((key, value) => {
    if (key[1]) cochesId.push(key[0]);
  });
  const Modules = [];

  Object.entries(CheckedModules).map((key, value) => {
    if (key[1]) Modules.push(key[0]);
  });



  const getAllCoaches = async () => {

    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/users/coach/profile/");

    if (data.status == "0x0") {
      // setAllCoaches(data.data.coach_list.results);
      setAllCoaches(data.data.coachDetails);
    }

  };

  const editBatches = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };

    var formdata = new FormData();

    formdata.append("user_batch_id", EditId);
    formdata.append("title", BatchName);
    formdata.append("course_info", CourseId);
    formdata.append("start_date", StartDate);
    formdata.append("end_time", BatchTiming);
    formdata.append("batch_timing", BatchTiming);
    formdata.append("batch_status", BatchStatus);
    formdata.append("batch_community_link", CommLink);
    formdata.append("batch_assignment_link", AssLink);
    formdata.append("saturday_session_zoom_link", satLink);
    formdata.append("sunday_session_zoom_link", sunLink);
    formdata.append("doubt_solving_session_zoom_link", doubtLink);
    formdata.append("add_coaches", JSON.stringify(cochesId));
    formdata.append("add_modules", JSON.stringify(Modules));
    // formdata.append("remove_coaches", JSON.stringify([]));
    // formdata.append("coaches", [
    //   "83bc7def-a682-4f51-8f10-e9beaba06f57",
    //   "e118bde8-444c-41fc-9281-c334cd57313d",
    // ]);

    const { data } = await axios.put("/course/batch/v2", formdata);
    if ((data.data.status = "0x0")) {
      // setallBatches(data.data.batch_list.results);
      getBatches();
    }
  };

  useEffect(() => {
    if (BatchId !== "") {
      getBatch();
    }
  }, [BatchId]);

  useEffect(() => {
    getBatches();
  }, []);
  
  useEffect(() => {
    getBatches();
  }, [FilterBatch]);

  const edit = () => {
    console.log("hey");
    setEdit("edit");
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setpageNo((prev) => prev - 1);
      setlistNo((prev) => prev - 20);
    }
  };

  const handleNextPage = () => {
    if (pageNo < ListCount) {
      setpageNo((prev) => prev + 1);
      setlistNo((prev) => prev + 20);
    }
  };

  useEffect(() => {
    getBatches();
  }, [pageNo,Search]);

  const handleReverse = () => {
    let revData = allBatches.slice().reverse();
    setallBatches(revData);
    setArrow((prev) => !prev);
  };

  const handleViewBatch = (id1, id2) => {
    setViewBatch("stuList");
    setBatchId(id1);
    setRemoveBatchId(id1);
  };

  const getAllModules=async()=>{
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(`/course/module/list/v1_1?course_info=${courseIdForModule}`);
    if (data.status == "0x0") {
      setAllmodules(data.data.module_list.results);
    }
  }

  const getStuEmails = async () => {
    handleRefreshToken();

    const batchId = [];

    const { data } = await axios.get(
      `/users/student/emails?batch_id=${JSON.stringify(batchId)}`
    );

    if (data.status == "0x0") {
      console.log("data");
      setemails(data?.data?.studentData);
    }
    
  };

  useEffect(() => {
    getStuEmails()
  }, [])

  useEffect(() => {
    courseIdForModule !="" && getAllModules()
  }, [courseIdForModule])

  useEffect(() => {
    ViewBatch == "stuList" && setStudentName(Search)
  }, [Search])
 

  return (
    <div className="">
      <h4 className="mb-4">Batch List</h4>
      {ViewBatch == "batchList"
          && <div className="col-4 searchWrapper mb-2">
                 <SearchBar
                   value={Search}
                   onChange={(newValue) => setSearch(newValue)}
                   onCancelSearch={(newValue) => setSearch("")}
                   style={{ padding: "-10px" }}
                 />
             </div>
      }

      {/* {ViewBatch != "batchList" && (
        <h2
          className="w-25"
          style={{ cursor: "pointer" }}
          onClick={() => setViewBatch("batchList")}
        >
          <i className="bi bi-arrow-left-circle-fill"></i>
        </h2>
      )} */}
      
      {Edit == "edit" ? (
        <EditBatch />
      ) : (
        <>
          {ViewBatch == "batchList" && (
            <>
              {IsLoading ? (
                <div className="d-flex justify-content-center align-items-center flex-column  w-100 h-100">
                  <CircularProgress size="lg" />
                </div>
              ) : (

                <div className="row">
                     <div className="d-flex justify-content-end mb-2">
                        <CopyToClipboardButton data={emails}/>
                     </div>
                  <table className="table table-striped">
                    <thead className="table-dark">
                      <tr>
                        <th className="text-center">Sr. No.</th>
                        <th>Course Name</th>
                        <th>Batch Name</th>
                        <th>Batch Id</th>
                        <th
                          onClick={handleReverse}
                          style={{ cursor: "pointer" }}
                        >
                          Start Date
                          {Arrow ? (
                            <i className="bi bi-arrow-up"></i>
                          ) : (
                            <i className="bi bi-arrow-down"></i>
                          )}
                        </th>
                        <th> <div className="dropdown">
                          <span
                            className="dropdown-toggle"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Status
                          </span>
                          <ul className="dropdown-menu">
                            {
                              status.map((sts,i)=>(
                            <li  style={{ cursor: "pointer" }} onClick={()=>setFilterBatch(sts)}>
                              <span className="dropdown-item">
                              {sts=="" && "All Batches"}{sts=="N" && "NotScheduled"} {sts=="S" && "Scheduled"}{sts=="A" && "Active"}{sts=="C" && "Completed"}{sts=="F" && "Force Complete"}
                              </span>
                            </li>
                              ))
                            }
                           
                          </ul>
                        </div></th>
                        <th>Total Students</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                    {allBatches.length==0&& <h4 className="text-center my-5">No Batches Found</h4>}

                      {allBatches?.map((batch, i) => (
                        <tr>
                          <td>{i + 1 + listNo}</td>
                          <td>{batch.course_info}</td>
                          <td>{batch.title}</td>
                          <td>{batch.user_batch_id}</td>
                          <td>{batch.start_date}</td>
                          <td>{batch?.batch_status}</td>
                          <td>{batch?.students}</td>
                          <td>
                            <button
                              className="btn btn-info btn-sm m-1"
                              data-bs-toggle="modal"
                              data-bs-target="#editBatchModal"
                              onClick={() => {
                                setBatchId(batch.user_batch_id);
                              }}
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            <button
                              className="btn btn-sm bg-info"
                              onClick={() =>
                                handleViewBatch(batch.user_batch_id, batch.id)
                              }
                            >
                              view
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between my-5">
                    <button className="btn btn-info" onClick={handlePrevPage}>
                      Prev
                    </button>
                    <button className="btn btn-info" onClick={handleNextPage}>
                      Next
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {ViewBatch == "stuList" && (
            <BatchStuList BatchId={BatchId} RemoveBatchId={RemoveBatchId} setViewBatch={setViewBatch} />
          )}
        </>
      )}

      {/* ============== Edit batch modal ============ */}

      <div
        className="modal fade"
        id="editBatchModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Batch
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={editBatches}>
                <div className="row">
                  <div className="col-12 my-2">
                    <label htmlFor="">Batch ID:</label>
                    <input
                      type="text"
                      placeholder="Batch ID (UUID)"
                      className="form-control"
                      // onChange={(e) => setEditId(e.target.value)}
                      value={EditId}
                    />
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Batch Name:</label>
                    <input
                      type="text"
                      placeholder="Batch Name"
                      className="form-control"
                      onChange={(e) => setBatchName(e.target.value)}
                      value={BatchName}
                    />
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Course:</label>
                    <select
                      className="form-select"
                      name="UserRole"
                      // onChange={(e) => setCourseId(e.target.value)}
                      value={CourseId}
                      aria-label="Default select example"
                    >
                      <option value="" selected>
                        Select Course Name
                      </option>
                      {CourseList?.map((course, i) => (
                        <option value={course.id}>{course.title}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Batch Status:</label>
                    <select
                      className="form-select"
                      name="UserRole"
                      onChange={(e) => setBatchStatus(e.target.value)}
                      value={BatchStatus}
                      aria-label="Default select example"
                    >
                      <option selected>Select Batch Status:</option>
                      <option value="S">Scheduled</option>
                      <option value="N">Not Scheduled</option>
                      <option value="A">Active</option>
                      <option value="C">Completed</option>
                      <option value="F">Force Completed</option>
                    </select>
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">Start Time:</label>
                    <input
                      type="time"
                      placeholder="Batch Timing"
                      className="form-control"
                      onChange={(e) => setBatchTiming(e.target.value)}
                      value={BatchTiming}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">End Time:</label>
                    <input
                      type="time"
                      placeholder="Batch Timing"
                      className="form-control"
                      onChange={(e) => setendTime(e.target.value)}
                      value={endTime}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">Assignment Link:</label>
                    <input
                      type="text"
                      placeholder="Assignment Link"
                      className="form-control"
                      onChange={(e) => setAssLink(e.target.value)}
                      value={AssLink}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">Community Link:</label>
                    <input
                      type="text"
                      placeholder="Community Link"
                      className="form-control"
                      onChange={(e) => setCommLink(e.target.value)}
                      value={CommLink}
                    />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">sunLink:</label>
                    <ReactQuill
                      theme="snow"
                      // onChange={(e) => setZoomLink(e.target.value)}
                      onChange={setsunLink}
                      value={sunLink}
                      width="100%"
                    />
                  </div>
                  <div className="col-12 my-2">
                    <label htmlFor="">Saturday Zoom Link:</label>
                    <ReactQuill
                      theme="snow"
                      // onChange={(e) => setZoomLink(e.target.value)}
                      onChange={setsatLink}
                      value={satLink}
                      width="100%"
                    />
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Doubt Solving Zoom Link:</label>
                    <ReactQuill
                      theme="snow"
                      // onChange={(e) => setZoomLink(e.target.value)}
                      onChange={setdoubtLink}
                      value={doubtLink}
                      width="100%"
                    />
                  </div>

                  <div className="col-12 my-2">
                    <label htmlFor="">Batch Start Date:</label>
                    <input
                      type="date"
                      placeholder="Current Trainer"
                      className="form-control"
                      onChange={(e) => setStartDate(e.target.value)}
                      value={StartDate}
                    />
                  </div>

                  <div className="col-12 my-2">
                    <div
                      className="btn-light btn w-100"
                      data-bs-target="#getCoachModal"
                      data-bs-toggle="modal"
                      onClick={getAllCoaches}
                    >
                      Select Coaches
                    </div>
                    {/* {AllCoaches.map((coach, i) => (
                    coach.id &&
                      <FormGroup>
                      <FormControlLabel
                    control={
                      <Checkbox
                       checked={true}
                      />
                    }
                    label={coach.first_name}
                    key={i}
                  />
                     </FormGroup>            
                  
                    ))} */}
                  </div>
                  <div className="col-12 my-2">
                    <div
                      className="btn-light btn w-100"
                      data-bs-target="#getAllModules"
                      data-bs-toggle="modal"
                      // onClick={getAllCoaches}
                    >
                      Select Modules
                    </div>
                    {/* {AllCoaches.map((coach, i) => (
                    coach.id &&
                      <FormGroup>
                      <FormControlLabel
                    control={
                      <Checkbox
                       checked={true}
                      />
                    }
                    label={coach.first_name}
                    key={i}
                  />
                     </FormGroup>            
                    ))} */}
                  </div>

                  <div className="col-12 my-4 d-flex justify-content-end">
                    <button className="btn btn-primary" data-bs-dismiss="modal">
                      save changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* select coaches modal  */}

      <div
        className="modal fade"
        id="getCoachModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Select Coaches
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <FormGroup>
                {AllCoaches.map((coach, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange(coach.id, coach.first_name)}
                        name={coach.id}
                        // defaultChecked ={CoachArr.includes(coach.id)?true:false}
                        checked={Checked[coach.id]}
                      />
                    }
                    label={coach.first_name}
                    key={i}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="getCoachModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Select Coaches
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <FormGroup>
                {AllCoaches.map((coach, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange(coach.id, coach.first_name)}
                        name={coach.id}
                        // defaultChecked ={CoachArr.includes(coach.id)?true:false}
                        checked={Checked[coach.id]}
                      />
                    }
                    label={coach.first_name}
                    key={i}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>

      {/* course modules */}

      <div
        className="modal fade"
        id="getAllModules"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Select Module
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <FormGroup>
                {Allmodules.map((module, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleModuleChange(module.id, module.first_name)}
                        name={module.id}
                        // defaultChecked ={CoachArr.includes(coach.id)?true:false}
                        checked={CheckedModules[module.id]}
                      />
                    }
                    label={module.title}
                    key={i}
                  />
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
