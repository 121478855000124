import axios from "axios";
import React, { useEffect, useState } from "react";
// material ui
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function AnnounceBatchForm() {
  const [CourseId, setCourseId] = useState([]);
  const [BatchId, setBatchId] = useState("");
  const [BatchName, setBatchName] = useState("");
  const [BatchStatus, setBatchStatus] = useState("");
  const [BatchTiming, setBatchTiming] = useState("");
  const [StartTime, setStartTime] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [Trainer, setTrainer] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [CourseList, setCourseList] = useState([]);
  const [Coach, setCoach] = useState({});
  const [modules, setmodules] = useState({});
  const [AllCoaches, setAllCoaches] = useState([]);
  const [IsBatchIdUnique, setIsBatchIdUnique] = useState("");
  const [Allmodules, setAllmodules] = useState([]);

  const getAllCourses = async (e) => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    // const { data } = await axios.get("/course/course/list/");
    const { data } = await axios.get("/course/course/");
    if (data.status == "0x0") {
      setCourseList(data?.data?.courseDetails);
    }
  };

  const getAllCoaches = async (e) => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    // const { data } = await axios.get("/users/coach/list/");
    const { data } = await axios.get(`/users/coach/profile/`);
    if (data.status == "0x0") {
      // setAllCoaches(data.data.coach_list.results);
      setAllCoaches(data.data.coachDetails);
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const handleChange = (event) => {
    const test = {
      ...Coach,
      [event.target.name]: event.target.checked,
    };
    setCoach(test);
  };

  const handleModuleChange = (event) => {
    const test = {
      ...modules,
      [event.target.name]: event.target.checked,
    };
    setmodules(test);
  };

  const createBatch = async (e) => {
    e.preventDefault();

    const cochesId = [];

    const moduleId = [];

    Object.entries(Coach).map((key, value) => {
      if (key[1]) cochesId.push(key[0]);
    });

    Object.entries(modules).map((key, value) => {
      if (key[1]) moduleId.push(key[0]);
    });

    var formdata = new FormData();
    formdata.append("user_batch_id", BatchId);
    formdata.append("title", BatchName);
    formdata.append("course_info", CourseId);
    formdata.append("start_date", StartDate);
    formdata.append("end_time", EndTime);
    formdata.append("batch_timing", StartTime);
    formdata.append("batch_status", BatchStatus);
    formdata.append("coaches", JSON.stringify(cochesId));
    formdata.append("modules", JSON.stringify(moduleId));
    const { data } = await axios.post("/course/batch/", formdata);

    if (data.status == "0x0") {
      setBatchId("");
      setBatchName("");
      setCourseId([]);
      setStartDate("");
      // setBatchTiming("");
      setStartTime("");
      setEndTime("");
      setBatchStatus("");
      setIsBatchIdUnique("");
      alert("Batch Created Successfully...!!!");
    }

    if (data.status == "0x2101") {
      setIsBatchIdUnique("BatchId is Already Exist.");
    }

    if (data.status == "0x1") {
      setIsBatchIdUnique("BatchId is Required");
    }
  };

  const getAllModules = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get(
      `/course/module/list/v1_1?course_info=${CourseId}`
    );
    if (data.status == "0x0") {
      setAllmodules(data.data.module_list.results);
    }
  };

  console.log(Allmodules);

  useEffect(() => {
    CourseId != "" && getAllModules();
  }, [CourseId]);

  return (
    <div>
      <h4 className="ms-4">Create Batch</h4>
      <div className="container">
        <form onSubmit={createBatch}>
          <div className="row mt-5">
            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Batch Id:</label>
              <input
                type="text"
                placeholder="Batch ID (UUID)"
                className="form-control"
                onChange={(e) => {
                  setBatchId(e.target.value);
                  setIsBatchIdUnique("");
                }}
                value={BatchId}
                required
              />
              {<p className="text-danger m-0">{IsBatchIdUnique}</p>}
            </div>

            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Batch Name:</label>
              <input
                type="text"
                placeholder="Batch Name"
                className="form-control"
                onChange={(e) => setBatchName(e.target.value)}
                value={BatchName}
                required
              />
            </div>

            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Batch Start Date:</label>
              <input
                type="date"
                placeholder="Current Trainer"
                className="form-control"
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>

            {/* <div className="col-md-6 col-12 my-2">
            <label htmlFor="">Module Name:</label>
              <select
                className="form-select"
                name="UserRole"
                onChange={(e) => setmodules(e.target.value)}
                value={modules}
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select Module Name
                </option>
                {Allmodules?.map((module, i) => (
                  <option value={module.id}>{module.title}</option>
                ))}
              </select>
            </div> */}

            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Batch Status:</label>
              <select
                className="form-select"
                name="UserRole"
                onChange={(e) => setBatchStatus(e.target.value)}
                value={BatchStatus}
                aria-label="Default select example"
              >
                <option selected>Select Batch Status</option>
                <option value="S">Scheduled</option>
                <option value="N">Not Scheduled</option>
                <option value="A">Active</option>
                <option value="C">Completed</option>
                <option value="F">Force Completed</option>
              </select>
            </div>

            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Start time</label>
              <input
                type="time"
                placeholder="start Timing"
                className="form-control"
                onChange={(e) => setStartTime(e.target.value)}
                value={StartTime}
                required
              />
            </div>
            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">End time</label>
              <input
                type="time"
                placeholder="end Timing"
                className="form-control"
                onChange={(e) => setEndTime(e.target.value)}
                value={EndTime}
                required
              />
            </div>

            <div className="col-md-6 col-12 my-2">
              <label htmlFor="">Course Name:</label>
              <select
                className="form-select"
                name="UserRole"
                onChange={(e) => setCourseId(e.target.value)}
                value={CourseId}
                aria-label="Default select example"
              >
                <option value="" selected>
                  Select Course Name
                </option>
                {CourseList?.map((course, i) => (
                  <option value={course.id}>{course.title}</option>
                ))}
              </select>
            </div>

            <div className="col-md-6 col-12 my-2">
              <div
                className="btn btn-light w-100"
                data-bs-target="#getCoachModal"
                data-bs-toggle="modal"
                onClick={getAllCoaches}
                required
              >
                Select Coaches
              </div>
            </div>
            <div className="col-md-6 col-12 my-2">
              <div
                className="btn btn-light w-100"
                data-bs-target="#getModules"
                data-bs-toggle="modal"
                // onClick={getAllModules}
                required
              >
                Select module
              </div>
            </div>

            <div className="col-12 my-4 d-flex justify-content-end">
              <button className="btn btn-primary">Create Batch</button>
            </div>
          </div>
        </form>

        {/* select coaches modal  */}

        <div
          className="modal fade"
          id="getCoachModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Coaches
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  {AllCoaches.map((coach, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox onChange={handleChange} name={coach.id} />
                      }
                      label={coach.first_name}
                      key={i}
                    />
                  ))}
                </FormGroup>
              </div>
            </div>
          </div>
        </div>

        {/*=========== batch modules  ========== */}
        <div
          className="modal fade"
          id="getModules"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Select Modules
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <FormGroup>
                  {Allmodules?.map((module, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleModuleChange}
                          name={module.id}
                        />
                      }
                      label={module.title}
                      key={i}
                    />
                  ))}
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
