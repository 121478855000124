import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const contactUsData = createAsyncThunk('/contactUs', async (pageNo) => {
    try {
        const response = await axios.get(`/users/contact_us/list/?page=${pageNo}`);
        return response.data;      
    } catch (error) {
        throw new Error(error.message)
    }
});

const contactUs = createSlice({
  name: 'contact us',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(contactUsData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(contactUsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default contactUs.reducer;