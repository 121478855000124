import axios from 'axios'
import React, { useState } from 'react'
// material ui 
import Snackbar from "@mui/material/Snackbar";

export default function AddCoach() {
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  const [Email, setEmail] = useState("")
  const [Username, setUsername] = useState("")
  const [ProfileState, setProfileState] = useState("")
  const [Summary, setSummary] = useState("")
  const [TagLine, setTagLine] = useState("")
  const [Gender, setGender] = useState("")
  const [LinkedIn, setLinkedIn] = useState("")
  const [PhoneNo, setPhoneNo] = useState("")
  const [Experiance, setExperiance] = useState("")
  const [Charges, setCharges] = useState("")
  const [Experties, setExperties] = useState("")
  const [Certificate, setCertificate] = useState("")
  const [Capacity, setCapacity] = useState("")
  const [Remark, setRemark] = useState("")
  const [ProfilePic, setProfilePic] = useState("")
  const [Rating, setRating] = useState("")
  const [AllStuList, setAllStuList] = useState([])
  const [EditId, setEditId] = useState("")
  const [Module, setModule] = useState("")
  const [open, setOpen] = React.useState(false);

  const handleRefreshToken=async()=>{
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const {data} = await axios.post("/users/token/refresh/",formdata)
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  }

const handleReset=()=>{
  setFirstName("");
  setLastName("");
  setEmail("");
  setUsername("");
  setProfileState("");
  setSummary("");
  setTagLine("");
  setGender("");
  setLinkedIn("");
  setPhoneNo("");
  setExperiance("");
  setCharges("");
  setExperties("");
  setCertificate("");
  setRating("");
  setCapacity("");
  setRemark("");
}

  const AddEdu=async (e)=>{
    handleRefreshToken()
      e.preventDefault()
      var formdata = new FormData();
      formdata.append("first_name", FirstName);
      formdata.append("last_name", LastName);
      formdata.append("email", Email);
      formdata.append("username", Email);
      formdata.append("profile_state", ProfileState);
      formdata.append("internal_feedback", Summary);
      formdata.append("tag_line", TagLine);
      formdata.append("gender", Gender);
      formdata.append("linkedin_url", LinkedIn);
      formdata.append("phone_numbers", PhoneNo);
      formdata.append("years_of_exp", Experiance);
      formdata.append("charge_per_hour", Charges);
      formdata.append("subjects", Experties);
      formdata.append("rating", Rating);
      formdata.append("certification", Certificate);
      formdata.append("batch_handeling_capacity", Capacity);
      formdata.append("remark", Remark);
      // formdata.append("profilePic", ProfilePic);

      const {data} = await axios.post("/users/coach/profile/",formdata)

      if (data.status=="0x0") {
        handleGetAllCoaches()
        handleReset()
        setOpen(true);
        setTimeout(() => setOpen(false), 5000);
      }

    }

    const handleGetAllCoaches = async () => {
      const token=JSON.parse(localStorage.getItem("access"))
      axios.defaults.headers.common = {
        'Authorization': `Bearer ${token}`,
      }
      const {data} = await axios.get("/users/coach/list/")
      console.log(data?.data?.coach_list?.results);
      setAllStuList(data?.data?.coach_list?.results);
    }

  return (
    <div>
       {<Snackbar
        open={open}
        // autoHideDuration={3000}
        // onClose={handleClose}
        message="Coach Added successfully...!!!"
        // action={action}
        sx={{
          bgcolor: "info",
        }}
      />}
      <h4 className="ms-5 ps-5">Add Coach</h4>
           <div className="row">
              <div className="col-10 offset-1 mb-4">
                <form onSubmit={AddEdu}>
                  <div className="row py-4">
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">FirstName:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="FirstName"
                        required
                      />
                    </div>
                    
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">LastName:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="LastName"
                        required
                      />
                    </div>
                
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Email:</label>
                      <input
                        type="Email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                      />
                    </div>

                    {/* <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Username:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                      />
                    </div> */}
                    
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Profile State:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setProfileState(e.target.value)}
                          aria-label="Profile Status"
                          required
                        >
                          <option value="" selected>Select Profile State</option>
                          <option value="I">Incomplete</option>
                          <option value="C">Complete</option>
                        </select>
                      </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Internal Feedback:</label>
                     <textarea name="" id="" cols="30" rows="1"  type="text"
                        className="form-control"
                        onChange={(e) => setSummary(e.target.value)}
                        placeholder="Internal Feedback" required></textarea>
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Tag line:</label>
                     <input  type="text"
                        className="form-control"
                        onChange={(e) => setTagLine(e.target.value)}
                        placeholder="Tag line" />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Gender:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setGender(e.target.value)}
                          aria-label="Gender"
                          required
                        >
                          <option value="" selected>Select Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                    </div>

                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Phone no.:</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setPhoneNo(e.target.value)}
                        placeholder="Phone No."
                        required
                      />
                    </div>
                  
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Rating:</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setRating(e.target.value)}
                        placeholder="Rating"
                        required
                      />
                    </div>

                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">linkedIn Url:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setLinkedIn(e.target.value)}
                        placeholder="linkedIn Url"
                        required
                      />
                    </div>

                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Yrs. of Experiance:</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setExperiance(e.target.value)}
                        placeholder="Yrs. of Experiance"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Subject Experties:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setExperties(e.target.value)}
                        placeholder="Subject Experties"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Certifications:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setCertificate(e.target.value)}
                        placeholder="Certifications"
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Per Hour Charge:</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setCharges(e.target.value)}
                        placeholder="Per Hour Charge"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Batch Handeling Capacity:</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => setCapacity(e.target.value)}
                        placeholder="Batch Handeling Capacity"
                      />
                    </div>
                    <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Remark:</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setRemark(e.target.value)}
                        placeholder="Remark"
                        required
                      />
                    </div>

                    {/* <div className="col-12 col-sm-6 my-2">
                      <label htmlFor="">Profile pic:</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setProfilePic(e.target.value)}
                        placeholder="Profile pic"
                        required
                      />
                    </div> */}

                    <div className="d-flex justify-content-end mt-5" data-bs-dismiss="modal"><button className='btn btn-info'>Add Coach</button></div>
                  </div>
                </form>
              </div>
            </div>
    </div>
  )
}
