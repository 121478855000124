import React, { useState } from "react";
import Topbar from "../Topbar";
import "../../style/recordings.css";

// images

import referFrnd from "../../images/referFrnd.jpg";
import handArrow from "../../images/downHand.png";
import axios from "axios";

export default function Reference() {
    const userId = JSON.parse(localStorage.getItem("Userid"));
    const [referalName, setreferalName] = useState("")
    const [referalMob, setreferalMob] = useState()
    const [courseName, setcourseName] = useState("")
    const [referalCity, setreferalCity] = useState("")
    const [yourName, setyourName] = useState("")
    const [yourMob, setyourMob] = useState()
    const [yourCounseller, setyourCounseller] = useState("")

    const handleRefreshToken = async () => {
        var formdata = new FormData();
        formdata.append("username", JSON.parse(localStorage.getItem("username")));
        formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
        const { data } = await axios.post("/users/token/refresh/", formdata);
        if (data.data.access) {
          localStorage.setItem("access", JSON.stringify(data.data.access));
        }
      };

      const reset=()=>{
        setreferalName("")
        setreferalMob("")
        setcourseName("")
        setreferalCity("")
        setyourName("")
        setyourMob("")
        setyourCounseller("")
      }

    const referFriend=async(e)=>{
        e.preventDefault()

        const referalData={
            "user_info" : userId,
            "title" : "",
            "description" : {
              "ReferralName": referalName,
              "ReferralContactNumber": referalMob,
              "CourseInterest": courseName,
              "ReferralCity": referalCity,
              "YourName":yourName,
              "YourNumber": yourMob,
              "CounsellorName": yourCounseller
            },
            "ticket_type" : "Referral",
            "ticket_status" : "New",
            "replies" : {}
        }

    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const data=await axios.post("/support/ticket/",referalData)

    if (data.data.status == "0x0") {
        window.alert("Your friend is successfully refered. We will contact him shortly.")
        reset()
    }
    }

  return (
    <div>
      <div className="container refer-container">       
        <div className="row mt-5 pt-5">
          <div className="col-lg-6 col-12">
            <div className="">
              <img src={referFrnd} alt="recordImg" width="100%" />
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-lg-0  justify-content-center align-items-center flex-column ">
            <h6 className="recText text-center">Refer Your Friend</h6>
            <div className=" d-flex justify-content-center  flex-column">

              <form onSubmit={referFriend}>
                <div className="my-3 ">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e)=>setreferalName(e.target.value)}
                    value={referalName}
                    placeholder="Enter Your Referral Name"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e)=>setreferalMob(e.target.value)}
                    value={referalMob}
                    placeholder="Enter Your Referral Contact Number"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e)=>setcourseName(e.target.value)}
                    value={courseName}
                    placeholder="Which Course He/She is Looking for?"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e)=>setreferalCity(e.target.value)}
                    value={referalCity}
                    placeholder="Your Referral  City"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e)=>setyourName(e.target.value)}
                    value={yourName}
                    placeholder="Your Name"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e)=>setyourMob(e.target.value)}
                    value={yourMob}
                    placeholder=" Your Number"
                  />
                </div>

                <div className="my-3">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e)=>setyourCounseller(e.target.value)}
                    value={yourCounseller}
                    placeholder=" Your CoachX Counsellor Name"
                  />
                </div>

              <div className="d-flex justify-content-center">
                <button className="accessRecordingBtn btn">Submit</button>
              </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
