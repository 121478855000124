import axios from 'axios';
import React, { useEffect, useState } from 'react'
// material ui 
import Snackbar from "@mui/material/Snackbar";

export default function AddModule() {
  const [CourseList, setCourseList] = useState([])
  const [CourseName, setCourseName] = useState("")
  const [Module, setModule] = useState("")
  const [open, setOpen] = React.useState(false);

  const getAllCourses=async()=>{
    const {data}=await axios.get("/course/course/list")
    console.log(data);
    if(data.status=="0x0"){
      setCourseList(data.data.course_list.results)
    }
  }

  useEffect(() => {
    getAllCourses()
  }, [])

  const handleRefreshToken = async () => {
    var formdata = new FormData();
    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    const { data } = await axios.post(
      "/users/token/refresh/",
      formdata
    );
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const handeAddModule= async(e)=>{
    e.preventDefault();
    handleRefreshToken();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    var formdata = new FormData();
    formdata.append("course_info", CourseName);
    formdata.append("title", Module);
    const {data}=await axios.post("/course/module/v1_1",formdata)

    if(data.status="0x0"){
      setOpen(true);
      setTimeout(() => setOpen(false), 5000);
    }

  }

  return (
    <div>
      
      {<Snackbar
        open={open}
        // autoHideDuration={3000}
        // onClose={handleClose}
        message="Module Added successfully...!!!"
        // action={action}
        sx={{
          bgcolor: "info",
        }}
      />}
<h4 className="ms-5 ps-5">Add Module</h4>
        <div className="row">
                <div className="col-10 offset-1 mb-4">
                  <form onSubmit={handeAddModule}>
                    <div className="row py-4">
                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Course Name:</label>
                        <select
                          className="form-select"
                          name="UserRole"
                          onChange={(e) => setCourseName(e.target.value)}
                          aria-label="Default select example"
                          required
                        >
                          <option>Select Course Name</option>
                          {CourseList?.map((course,i)=>(
                            <option value={course.id} key={i}>{course.title}</option>
                          ))}
                        </select>
                      </div>

                      <div className="col-12 col-sm-6 my-2">
                        <label htmlFor="">Module Name:</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setModule(e.target.value)}
                          placeholder="Module Name"
                          required
                        />
                      </div>

                      <div className="d-flex justify-content-end mt-5">
                        <button className="btn btn-info">Add Module</button>
                      </div>
                    </div>
                  </form>
                </div>
        </div>
        
    </div>
  )
}
