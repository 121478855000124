import React from 'react'
import * as XLSX from 'xlsx/xlsx.mjs';
import { useState } from 'react'

export default function DownloadExcelSheet({dataForXLSX}) {
    console.log(dataForXLSX);

      const handleDownload = () => {
        const workbook = XLSX.utils.book_new();
    
        const worksheet = XLSX.utils.aoa_to_sheet(dataForXLSX);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
        XLSX.writeFile(workbook, 'filename.xlsx');
      };

  return (
    <div>
        <button className='btn btn-info btn-sm ' onClick={handleDownload}>Download Batch Students List <i class="bi bi-file-earmark-arrow-down"></i></button>
    </div>
  )
}
