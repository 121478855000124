import React, { useState } from 'react'
import Topbar from '../Topbar'
import "../../style/zoomLink.css"
import DoubtSolving from './DoubtSolving'
import SatSessionLink from './SatSessionLink'
import SunSession from './SunSession'
// images 
import zoomLinkImg from "../../images/zoomLinkImg.png"
import zoomLogo from "../../images/zoomLogo.png"

export default function ZoomLink() {
  const [Tabs, setTabs] = useState("SatSessionLink")
  const userGrp = JSON.parse(localStorage.getItem("user_group"));

  return (
    <div className=''>
      {/* <Topbar topBarHeading={"Zoom Link"}/> */}
      {
        userGrp.includes("G1Student")
        ?<>
                
                
        <div className="container">
        <h4 className="ms-2 mb-4">Zoom Links</h4>
        <div className="row my-2 ms-1">
                <div className={Tabs=="SatSessionLink"?"col-4 d-flex justify-content-center border p-2 tabs slectTab":"col-4 d-flex justify-content-center border bg-info p-2 tabs"} onClick={()=>setTabs("SatSessionLink")} >Sat Session</div>
                <div className={Tabs=="SunSession"?"col-4 d-flex justify-content-center border p-2 tabs slectTab":"col-4 d-flex justify-content-center border bg-info p-2 tabs"} onClick={()=>setTabs("SunSession")}> Sun Session</div>
                <div className={Tabs=="DoubtSolving"?"col-4 d-flex justify-content-center border p-2 tabs slectTab":"col-4 d-flex justify-content-center border bg-info p-2 tabs"} onClick={()=>setTabs("DoubtSolving")}> Doubt Solving</div>
        </div>
          {
          Tabs=="SatSessionLink"&&<SatSessionLink/>
          }

          {
            Tabs=="DoubtSolving"&& <DoubtSolving/>
          }

          {
            Tabs=="SunSession" && <SunSession/>
          }
          
          <div className="row">
            <div className="col-6 left-half d-flex flex-column justify-content-center py-0">
            <div className=" left-half leftImg-wrapper">
            <img src={zoomLinkImg}  alt="zoomLinkImg" className='zoomLinkImg' />
            </div>
            </div>
            <div className="col-6 left-half d-flex flex-row flex-lg-column justify-content-center ">
              <div className=''>
              <img src={zoomLogo} alt="zoomLogo" width="30%" className='mt-0 mt-sm-5'/>
              <h5 className='learnLiveText mt-2 mt-sm-4'>Let's Learn Live With The Experts!</h5>
              </div>
            </div>
          </div>
          </div>
        </>
        :<h3 className='text-center my-5'>Sorry! You Have No Permissions.</h3>
      }
    </div>
  )
}
