import React, { useEffect, useState } from "react";
import "../../style/invoice.css";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
// import htmlToDocx from 'html-to-docx';
// import mammoth from 'mammoth';
// import JSZip from 'jszip';
// import Docxtemplater from 'docxtemplater';

import logo from "../../images/logo.png";
import numberToWords from "number-to-words";

// material UI

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Invoice({ invoiceId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  ////
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Username, setUsername] = useState("");
  const [RegAmmt, setRegAmmt] = useState(0);
  const [PhoneNo, setPhoneNo] = useState("");
  const [PaymentDate, setPaymentDate] = useState("");
  const [FeeBal, setFeeBal] = useState("");
  const [Installment1, setInstallment1] = useState("");
  const [Installment2, setInstallment2] = useState("");
  const [Installment3, setInstallment3] = useState("");
  const [Installment1Status, setInstallment1Status] = useState("")
  const [Installment2Status, setInstallment2Status] = useState("")
  const [Installment3Status, setInstallment3Status] = useState("")
  const [FinalFee, setFinalFee] = useState("");
  const [FeesInWord, setFeesInWord] = useState();
  const [PitchedAmount, setPitchedAmount] = useState(0);
  const [InvoiceNo, setInvoiceNo] = useState();
  const [Discount, setDiscount] = useState(0);
  const [regDate, setregDate] = useState("");
  const [BasicAmt, setBasicAmt] = useState(0);
  const [ClousererName, setClousererName] = useState(0);
  const [GST, setGST] = useState(0);
  const [SGST, setSGST] = useState(0);
  const [CGST, setCGST] = useState(0);
  const [Course, setCourse] = useState("");
  const [TotalAmt, setTotalAmt] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [CourseName, setCourseName] = useState("");
  const [fileFormat, setfileFormat] = useState("PDF");
  const [TotalAmtReceived, setTotalAmtReceived] = useState()
  const [lastInvoiceNo, setLastInvoiceNo] = useState(0)

  useEffect(() => {
    setFeesInWord(TotalAmt == "" ? "---" : numberToWords.toWords(TotalAmt));
  }, [TotalAmt]);

  useEffect(() => {
    if(invoiceId != "" && invoiceId !=undefined){
      setOpen(true);
    }
  }, [invoiceId]);

  const handleRefreshToken = async () => {
    var formdata = new FormData();

    formdata.append("username", JSON.parse(localStorage.getItem("username")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));
    formdata.append("refresh", JSON.parse(localStorage.getItem("refresh")));

    const { data } = await axios.post("/users/token/refresh/", formdata);
    if (data.data.access) {
      localStorage.setItem("access", JSON.stringify(data.data.access));
    }
  };

  const getStuProfile = async () => {
    handleRefreshToken();
    const { data } = await axios.get(
      `/users/student/create_payment?payment_id=${invoiceId}`
    );

    if (data.status == "0x0") {
      setFirstName(data?.data?.userData.first_name);
      setLastName(data?.data?.userData.last_name);
      setEmail(data?.data?.userData.username);
      setUsername(data?.data?.userData.email);
      setPhoneNo(data?.data?.userData.phone_numbers);
      setClousererName(data?.data?.userData.counsellor);
      setPaymentDate(data?.data?.userData.payment_date);
      setRegAmmt(data?.data?.userData.registration_amount);
      setregDate(data?.data?.userData.registration_date);
      setPitchedAmount(data?.data?.userData.pitched_amount);
      setDiscount(data?.data?.userData.discount);
      // setFinalFee(data?.data?.userData.final_amount);
      setFeeBal(data?.data?.userData.remaining_amount);
      setInstallment1(data?.data?.userData.installment1);
      setInstallment2(data?.data?.userData.installment2);
      setInstallment3(data?.data?.userData.installment3);
      setCourse(data?.data?.userData.course);
      // setPaymentStatus(data?.data?.userData.payment_status);
      setInstallment1Status(data?.data?.userData.installment1_status);
      setInstallment2Status(data?.data?.userData.installment2_status);
      setInstallment3Status(data?.data?.userData.installment3_status);
      setTotalAmtReceived((RegAmmt)+(Installment1Status=="P"?Installment1:0)+(Installment2Status=="P"?Installment2:0)+(Installment3Status=="P"?Installment3:0))
    }
  };

  useEffect(() => {
    setGST(((PitchedAmount - Discount) * 18) / 118);
  }, [PitchedAmount, Discount]);

  useEffect(() => {
    setTotalAmt(PitchedAmount - Discount);
    setSGST(GST / 2);
    setCGST(GST / 2);
  }, [GST]);

  useEffect(() => {
    setBasicAmt(TotalAmt - GST);
  }, [TotalAmt]);

  const pdfConv = () => {
    const componentElement = document.getElementById("hello");
    const options = {
      margin: 10,
      filename: "Invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a3", orientation: "portrait" },
    };
    html2pdf().from(componentElement).set(options).save();
  };

function Export2Word(element, filename = "") {
  var preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var postHtml = "</body></html>";
  var html = preHtml + document.getElementById("hello").innerHTML + postHtml;
console.log(html)
  var blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });

  // Specify link url
  var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  // Specify file name
  filename = filename ? filename + ".doc" : "docInvoice.doc";

  // Create download link element
  var downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;

    // Setting the file name
    downloadLink.download = filename;

    // Triggering the function
    downloadLink.click();
  }

  document.body.removeChild(downloadLink);
}


  const DownloadInvoicePDF = () => {
    // convertToWord()
    // Export2Word('hello')
    // pdfConv();
    // Export2Word()
  //   if (fileFormat == "PDF") {
  //     pdfConv();
  //   } else {
  //     convertToWord();
  //   }
    // setLoading(false);
    // handleClose();
  };

  const takeRecord = async (e) => {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    setLoading(true);
    var formdata = new FormData();
    formdata.append("payment_id", invoiceId);
    // formdata.append("first_name", FirstName);
    // formdata.append("last_name", LastName);
    // formdata.append("phone_number", PhoneNo);
    // formdata.append("email", Email);
    // formdata.append("username", Email);
    // formdata.append("advance_amount_paid", RegAmmt);
    // formdata.append("installment1_date", "2023-07-23");
    // formdata.append("installment1_amount", Installment1);
    // formdata.append("installment2_date", "2023-07-23");
    // formdata.append("installment2_amount", Installment2);
    // formdata.append("installment3_date", "2023-07-23");
    // formdata.append("installment3_amount", Installment3);
    formdata.append("receipt_number", lastInvoiceNo);
    // formdata.append("description1", "");
    // formdata.append("description2", "");
    // formdata.append("pitched_amount", PitchedAmount);
    // formdata.append("discount", Discount);
    // formdata.append("amount_in_word", FeesInWord);
    // formdata.append("registration_date", regDate);
    // formdata.append("counsellor", ClousererName);
    // formdata.append("cgst", CGST);
    // formdata.append("sgst", SGST);

    const { data } = await axios.put("/payment/invoice/", formdata);

    if (data.status == "0x0") {
      // DownloadInvoicePDF();
      pdfConv()
      setLoading(false);
      handleClose();
      getInvoiceList()
      // convertToWord()
    }
  };

  function truncateTwoDigits(number) {
    return Math.floor(number * 100) / 100;
  }

  function capitalizeFirstLetter(str) {
    if(str !="" && str !=undefined){
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  const getInvoiceList = async () => {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.get("/payment/invoice/list/");
    console.log(data);
    if (data.status == "0x0") {
      setLastInvoiceNo(data.data.invoiceList.results[0].invoice_no+1);
    }
  };
  
  useEffect(() => {
    getInvoiceList()
  }, []);

  useEffect(() => {
    if (invoiceId != "") {
      getStuProfile();
    }
  }, [invoiceId]);

  return (
    <div>
      {/* INVOICE UI  */}
      <div className="d-none">
        <div className="container" id="hello">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <div className="horizontal_bar1 me-4"></div>
                    <img src={logo} alt="logo" />
                    <div className="horizontal_bar2 ms-2"></div>
                    <div className="horizontal_bar3"></div>
                  </div>
                  <div className="ms-5 poweredByText">
                    Powered By Classboat Online Services Private Limited.
                  </div>
                </div>
                <div>
                  <span>Office Address: 1st Floor,</span>
                  <br />
                  <span>Teerth Technospace,</span>
                  <br />
                  <span>Baner, Pune-411014.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 my-4">
              <h2 className="voucher text-center">
                <b>Receipt Voucher</b>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 border">
              <h6>
                Receipt No:<b>{lastInvoiceNo}</b>
              </h6>
            </div>
            <div className="col-3 offset-1 border">
              <b>Course Details</b>
              <p>Name of the course:</p>
              <b>{CourseName}</b>
            </div>
            <div className="col-7 border">
              <b>Candidate Details</b>
              <p>
                Name of the candidate: <b>{FirstName + " " + LastName}</b>
              </p>
              <p>
                Mobile Number: <b>{PhoneNo}</b>
              </p>
              <p>
                Email ID: <b>{Email}</b>
              </p>
              <p>
                 Amount Received: <b>₹ {RegAmmt+(Installment1Status=="P"?Installment1:0)+(Installment2Status=="P"?Installment2:0)+(Installment3Status=="P"?Installment3:0)}</b>
              </p>
              <p>
                Remaining Amount to be paid: <b>₹ {FeeBal}</b>
              </p>
              {/* <p>Remaining Amount Payment Date: <b>{PaymentDate}</b></p> */}
              <p>
                1st Installment: <b>After 2nd session ₹ {Installment1}</b>
              </p>
              <p>
                2nd Installment: <b>After 6th session ₹ {Installment2}</b>
              </p>
              <p>
                3rd Installment: <b>After 10th session ₹ {Installment3}</b>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 my-4">
              <h2 className="voucher text-center">
                <b>Package Details</b>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-5 offset-1 border">
              <p>
                Paid By: <b>{FirstName + " " + LastName}</b>
              </p>
            </div>
            <div className="col-5 border">
              <p>
                Paid to <b>CoachX.Live</b>
              </p>
              <p>
                GST No:<b> 27AAGCC2675N1Z6</b>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-5 offset-1 border">
              <p>Description </p>
            </div>
            <div className="col-5 border">
              <p>Amount</p>
            </div>
          </div>
          <div className="row">
            <div className="col-5 offset-1 border">
              <b>{CourseName}</b>
              <ul>
                <li>
                  <b>100% Job assistance</b>
                </li>
                <li>
                  <b>Live Learning Platform</b>
                </li>
              </ul>
            </div>
            <div className="col-5 border">
              <div className="row">
                <div className="col-5 border">
                  <p>SUB Total </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {PitchedAmount}</b>
                </div>
                <div className="col-5 border">
                  <p>Discount (s) </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {Discount}</b>
                </div>
                <div className="col-5 border">
                  <p>Basic Amt </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {truncateTwoDigits(BasicAmt)}</b>
                </div>
                <div className="col-5 border">
                  <p>GST </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {truncateTwoDigits(GST)} </b>
                </div>
                <div className="col-5 border">
                  <p>CGST </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {truncateTwoDigits(CGST)} </b>
                </div>
                <div className="col-5 border">
                  <p>SGST</p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b>₹ {truncateTwoDigits(SGST)}</b>
                </div>
                <div className="col-5 border">
                  <p>Total Amt. </p>
                </div>
                <div className="col-7 border">
                  {" "}
                  <b> ₹ {TotalAmt} </b>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 border">
              <p>
                Amount in Words: <b>{capitalizeFirstLetter(FeesInWord)}</b>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-5 offset-1 border">
              <p>
                Date of Payment: <b>{PaymentDate} </b>
              </p>
            </div>
            <div className="col-5 border">
              <p>
                Received by: <b>CoachX.Live</b>
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-10 offset-1">
              <div>
                <img src={logo} alt="logo" width={120} />
                <p>Yours Faithfully</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1">
              <p>Terms:</p>
              <ul>
                <li>
                  This amount is non-refundable as per the terms of Coachx.Live
                </li>
                <li>
                  If you fail to pay your installments on the mentioned dates or
                  that particular week maximum your LMS will be disabled
                  automatically.
                </li>
                <li>
                  Placement assistance is eligible to only candidates with more
                  than 80% attendance in live sessions, who submits all
                  assignments and projects on time, and who clears more than 80%
                  in assignments, projects and mock interviews.
                </li>
                <li>This receipt is system generated.</li>
              </ul>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-10 offset-1">
              <h6 className="text-center poweredByText">
                <i class="bi bi-globe"> </i>{" "}
                <span className="ms-1">www.CoachX.Live</span>{" "}
                <i class="bi bi-envelope"></i>{" "}
                <span className="ms-1">wecare@coachx.live</span>{" "}
                <i class="bi bi-whatsapp"></i>{" "}
                <span className="ms-1">7378757330</span>
              </h6>
            </div>
          </div>
          <div className="horizontal_bar4"></div>
        </div>
      </div>

      {/* INVOICE GENERATION FORM  */}
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <h2>Generate Invoice</h2>
              <form onSubmit={takeRecord}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="">Course Name:</label>
                      <select
                        className="form-select"
                        name="UserRole"
                        onChange={(e) => setCourseName(e.target.value)}
                        value={CourseName}
                        aria-label="Default select example"
                        required
                      >
                        <option>Select course</option>
                        <option value="Complete Tableau Training Program">
                          Complete Tableau Training Program
                        </option>
                        <option value="Complete Power BI Training Program">
                          Complete Power BI Training Program
                        </option>
                        <option value="Complete Python Training Program">
                          Complete Python Training Program
                        </option>
                        <option value="Complete SQL Training Program">
                          Complete SQL Training Program
                        </option>
                        <option value="Professional Certification Program In Azure">
                          Professional Certification Program In Azure
                        </option>
                        <option
                          value="Professional Certification Program In Data Science 
                          "
                        >
                          Professional Certification Program In Data Science
                        </option>
                        <option value="Professional Certification Program In Data Analytics">
                          Professional Certification Program In Data Analytics
                        </option>
                        <option
                          value="Professional Certification Program In Business Analytics 
                          "
                        >
                          Professional Certification Program In Business
                          Analytics
                        </option>
                        <option value="Professional Certification Program In Excel SQL POWER BI ">
                          Professional Certification Program In Excel SQL POWER
                          BI{" "}
                        </option>
                        <option value="Professional Certification Program In EXCEL & POWER BI">
                          Professional Certification Program In EXCEL & POWER BI
                        </option>
                        <option value="Professional Certification Program In SQL & POWER BI ">
                          Professional Certification Program In SQL & POWER BI{" "}
                        </option>
                        <option value="Professional Certification Program In AWS Cloud Practitioner with Devops">
                          Professional Certification Program In AWS Cloud
                          Practitioner with Devops
                        </option>
                        <option value=""></option>
                      </select>
                    </div>
                    <div className="col-6">
                      <label htmlFor="">Invoice No.</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Invoice No."
                        // onChange={(e) => setInvoiceNo(e.target.value)}
                        value={lastInvoiceNo}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 my-4 d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    onClick={DownloadInvoicePDF}
                  >
                    {Loading
                      ? "Generating Invoice..."
                      : "Generate Invoice And Download"}
                  </button>
                </div>
              </form>
            </Box>
          </Fade>
        </Modal>
      </div>

      {/* <button className="btn btn-info btn-sm"   
        // onClick={DownloadInvoicePDF}
        // data-bs-target="#invoiceModal"
        data-bs-target="#invoiceModal"
        data-bs-toggle="modal"
        >
          <i class="bi bi-receipt" ></i>
        </button> */}
    </div>
  );
}
