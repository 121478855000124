import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const supportStuList=createAsyncThunk("/supportStuList",async({pageNo,SalesUser,Search,FilterBatch,sort})=>{
try {
    const token = JSON.parse(localStorage.getItem("access"));
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    
    const {data}=await axios.get(
        `/users/student/create_payment/list?page=${pageNo}&counsellor=${JSON.stringify(
          SalesUser
        )}&search=${Search}&course=[]&stud_info__student_status=${FilterBatch}&sort=${sort} `
      );
      console.log(data);
    return data
} catch (error) {
    throw new Error(error.message)
}
})

const supportStuListSlice = createSlice({
    name:"supportStuListSlice",
    initialState:{
        data:null,
        loading:false,
        error:null
    },
    reducer:{},
    extraReducers:(builder)=>{
        builder
        .addCase(supportStuList.pending, (state)=>{
            state.loading=true;
            state.error= null;
        })
        .addCase(supportStuList.fulfilled, (state,action)=>{
            state.loading=false;
            state.data = action.payload;
        })
        .addCase(supportStuList.rejected, (state,action)=>{
            state.loading=false;
            state.error= action.error.message;
        })
    }
    
})
export default supportStuListSlice.reducer